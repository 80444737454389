import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 25px;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${props => (props.color ? props.color : props.theme.colors.BLUE)};
  border: ${props => (props.empty ? '2px solid black' : 'none')};
  font-weight: bold;
  color: ${props => (props.empty || props.color === props.theme.colors.YELLOW ? 'black' : 'white')};
  font-size: 18px;
  width: ${props => (props.block ? '100%' : 'auto')};
  max-width: ${props => props.notWide ? '430px' : 'unset'};
`;

export default ({ children, ...rest }) => <StyledButton {...rest}>{children}</StyledButton>;
