import {
	PRODUCTS_SET_SHOPPING_LIST,
	PRODUCTS_SET_SIZE,
	PRODUCTS_MARK_AVAILABLE,
	PRODUCTS_SELECT_PRODUCT,
	PRODUCTS_TOGGLE_SHOW_DIRECTIONS,
	PRODUCTS_MARK_PRODUCT_DONE,
	PRODUCTS_SET_SELF_SERVICE,
	PRODUCTS_SET_MISTAKE_PHOTO,
	PRODUCTS_SAVE_COMMENT,
	PRODUCTS_RESET_SHOPPING_LIST,
	PRODUCTS_MARK_SUMMARY_REACHED,
	PRODUCTS_GET_SHOPPING_LIST_STARTED,
	PRODUCTS_GET_SHOPPING_LIST_FAILED,
	PRODUCTS_SET_SHOPPING_LIST_FROM_REVIEW,
	PRODUCTS_SET_CHECK_AVAILABILITY_DONE,
	PRODUCTS_SET_REVIEW_ID,
	PRODUCTS_REPLACE_PRODUCT,
	PRODUCTS_REPLACE_PRODUCT_STARTED,
	PRODUCTS_REPLACE_PRODUCT_FAILED,
} from './products.action';
import { REHYDRATE } from 'redux-persist/lib/constants';

const initialState = {
	products: [],
	size: 5,
	product: { id: '' },
	showDirections: true,
	isLoadingProducts: false,
	isReview: false,
	isCheckAvailabilityDone: false,
	reviewId: '',
};

const markProductDone = (products, product) =>
	products.map(p => {
		if (p.identifier === product.identifier) {
			p.isDone = true;
		}
		return p;
	});

const markSummaryReached = (products, product) =>
	products.map(p => {
		if (p.identifier === product.identifier) {
			p.summaryReached = true;
		}
		return p;
	});

const setAvailability = (products, identifier, isAvailable) =>
	products.map(p => {
		if (p.identifier === identifier) {
			p.isAvailable = isAvailable;
		}
		return p;
	});

const setSelfService = (products, { product, location, question }) =>
	products.map(p => {
		if (p.identifier === product.identifier) {
			p.checkSelfService = true;
			p.location = location;
			p.question = question;
		}
		return p;
	});

const setMistakePhoto = (products, { photo, productId, categoryType }) =>
	products.map(p => {
		if (p.identifier === productId) {
			p.mistakeMarked = p.mistakeMarked || [];
			p.mistakeMarked = p.mistakeMarked.concat([{ categoryType, photo }]);
		}
		return p;
	});

const saveComment = (products, { comment, productId, categoryType, question }) =>
	products.map(p => {
		if (p.identifier === productId) {
			p.mistakeMarked = p.mistakeMarked.map(m => {
				if (m.categoryType === categoryType) {
					m.comment = comment;
					m.question = question;
				}
				return m;
			});
		}
		return p;
	});

const productsReducer = (state = initialState, action) => {
	switch (action.type) {
		case PRODUCTS_SET_SHOPPING_LIST:
			return {
				...state,
				products: action.products,
				isLoadingProducts: false,
			};
		case PRODUCTS_GET_SHOPPING_LIST_STARTED:
			return {
				...state,
				isLoadingProducts: true,
			};
		case PRODUCTS_GET_SHOPPING_LIST_FAILED:
			return {
				...state,
				isLoadingProducts: false,
			};
		case PRODUCTS_SET_SIZE:
			return {
				...state,
				size: action.size,
			};
		case PRODUCTS_MARK_AVAILABLE:
			return {
				...state,
				products: setAvailability(state.products, action.identifier, action.isAvailable),
			};
		case PRODUCTS_SELECT_PRODUCT:
			return {
				...state,
				product: action.product,
			};
		case PRODUCTS_TOGGLE_SHOW_DIRECTIONS:
			return Object.assign({}, state, action.showDirections);
		case PRODUCTS_MARK_PRODUCT_DONE:
			return {
				...state,
				products: markProductDone(state.products, action.product),
			};
		case PRODUCTS_MARK_SUMMARY_REACHED:
			return {
				...state,
				products: markSummaryReached(state.products, action.product),
			};
		case PRODUCTS_SET_SELF_SERVICE:
			return {
				...state,
				products: setSelfService(state.products, action),
			};
		case PRODUCTS_SET_MISTAKE_PHOTO:
			return {
				...state,
				products: setMistakePhoto(state.products, action),
			};
		case PRODUCTS_SAVE_COMMENT:
			return {
				...state,
				products: saveComment(state.products, action),
			};
		case PRODUCTS_RESET_SHOPPING_LIST:
			return {
				...state,
				products: initialState.products,
				size: initialState.size,
				product: initialState.product,
				isReview: initialState.isReview,
				isCheckAvailabilityDone: initialState.isCheckAvailabilityDone,
				reviewId: initialState.reviewId,
			};
		case PRODUCTS_SET_SHOPPING_LIST_FROM_REVIEW:
			return {
				...state,
				products: action.products,
				isReview: true,
				isCheckAvailabilityDone: false,
				size: action.products.length,
			};
		case PRODUCTS_SET_CHECK_AVAILABILITY_DONE:
			return {
				...state,
				isCheckAvailabilityDone: true,
			};
		case PRODUCTS_SET_REVIEW_ID:
			return {
				...state,
				reviewId: action.reviewId,
			};
		case PRODUCTS_REPLACE_PRODUCT_STARTED:
			return {
				...state,
				isLoadingProducts: true,
			};
		case PRODUCTS_REPLACE_PRODUCT:
			return {
				...state,
				products: state.products.map(p =>
					p.identifier === action.products.productToReplace ? action.products.response : p
				),
				isLoadingProducts: false,
			};
		case PRODUCTS_REPLACE_PRODUCT_FAILED:
			return {
				...state,
				isLoadingProducts: false,
			};
		case REHYDRATE:
			const persist = JSON.parse(window.localStorage.getItem('persist:root'));
			const persistedEvents = persist && persist.events && JSON.parse(persist.events);
			const sequenceId = persistedEvents && persistedEvents.sequenceId;
			if (action && action.payload && action.payload.products) {
				action.payload.products = !sequenceId ? state : action.payload.products;
				return action.payload.products;
			}
			return state;
		default:
			return state;
	}
};

export default productsReducer;

export function getProducts(state) {
	return state.products && state.products.products;
}

export function getProductsSize(state) {
	return state.products && state.products.size;
}

export function getProduct(state) {
	return state.products && state.products.product;
}

export function getDirections(state) {
	return state.products && state.products.showDirections;
}

export function getIsLoadingProducts(state) {
	return state.products && state.products.isLoadingProducts;
}

export function getIsReview(state) {
	return state.products && state.products.isReview;
}

export function getIsCheckAvailabilityDone(state) {
	return state.products && state.products.isCheckAvailabilityDone;
}

export function getReviewId(state) {
	return state.products && state.products.reviewId;
}
