import React from 'react';
import styled from 'styled-components';
import ProductCard from '../../components/ProductCard';
import Minus from '../../components/icons/Minus';
import PlusExpand from '../../components/icons/PlusExpand';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import actions from '../../store/Events/events.action';
import { getMistakes, getFetchingMistakes } from '../../store/Mistakes/mistakes.reducer';
import { bindActionCreators } from 'redux';
import Button from '../../components/Form/Button/Button';
import theme from '../../styles/1-settings/theme';
import { getFilters } from '../../store/Events/events.reducer';
import Close from '../../components/icons/Close';
import eventsAction from '../../store/Events/events.action';
import { getAreas } from '../../store/App/app.reducer';
import { FilterLoading, ProductLoading } from '../../components/LoadingElements';
import { Trans } from 'react-i18next';

const Wrapper = styled.article`
	flex: 1;
	overflow-y: auto;
	margin: ${props => (props.desktop ? '20px 0px' : '0px')};
	height: ${props => (props.desktop ? 'auto' : 'calc(100% - 60px)')};
`;

const StyledSection = styled.section`
	background-color: ${props => (props.desktop ? 'transparent' : props.theme.colors.GREY)};
	margin: ${props => (props.withMargin ? '2px 0px' : '0px')};

	header {
		margin-bottom: ${props => (props.desktop ? '20px' : '0px')};
		padding: ${props => (props.desktop ? '30px 60px' : '25px')};
		display: flex;
		justify-content: space-between;
		background-color: ${props => (props.desktop ? 'white' : props.theme.colors.GREY)};
		box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
		align-items: center;

		h1 {
			font-weight: bold;
			margin: 0px;
			font-size: ${props => (props.desktop ? '24px' : 'auto')};
		}
	}
`;

const ProductList = styled.section`
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
	margin: ${props => (props.desktop ? '20px 0px' : '0px')};

	div:last-of-type {
		border-bottom: 0px solid black;
	}
`;

const FilterWrapper = styled.section`
	display: flex;
	flex-direction: column;
	padding: 10px 15px;
	background-color: ${props => (props.desktop ? 'white' : props.theme.colors.LIGHT_GREY)};
	margin-bottom: ${props => (props.desktop ? '10px' : '0px')};
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};

	button {
		padding: 12px;
		margin: 5px 20% 10px;
	}

	article {
		display: flex;
		flex-wrap: wrap;
	}
`;

const Filter = styled.section`
	padding: 5px 10px;
	border: 1px solid #c1c1c1;
	margin: 5px;
	font-size: ${props => (props.desktop ? '14px' : '12px')};
	line-height: 24px;
	display: flex;
	align-items: center;
	background-color: white;
	position: relative;

	p {
		margin-right: 10px;
	}
`;

class Intro extends React.PureComponent {
	componentDidMount() {
		this.props.eventsGetMistakes();
	}

	state = {
		expanded: true,
	};

	goTo = id => {
		const url = `/store-stream/${id}`;
		this.props.history.push(url);
	};

	handleToggleFilter = filter => {
		const { toggleFilter, eventsGetMistakes } = this.props;
		toggleFilter({ name: filter });
		eventsGetMistakes();
	};

	render() {
		const { desktop, mistakes, history, filters, fetchingMistakes } = this.props;
		return (
			<Wrapper desktop={desktop}>
				{!fetchingMistakes && (
					<FilterWrapper desktop={desktop}>
						<Button
							empty
							desktop={desktop}
							color={desktop ? 'white' : theme.colors.LIGHT_GREY}
							onClick={() => history.push('/store-stream/filter')}
						>
							<Trans i18nKey="filter">Filter</Trans>
						</Button>

						<article>
							{filters.map((filter, key) => (
								<Filter desktop={desktop} key={key} onClick={() => this.handleToggleFilter(filter)}>
									<p>
										<Trans i18nKey="area">Area</Trans>: {filter}
									</p>
									<Close color={'black'} width={'12'} height={'12'} />
								</Filter>
							))}
						</article>
					</FilterWrapper>
				)}
				{!fetchingMistakes && mistakes.notFixed.results.length > 0 && (
					<>
						<StyledSection desktop={desktop} withMargin={!this.state.expanded}>
							<header onClick={() => this.setState({ expanded: !this.state.expanded })}>
								<h1>
									<Trans i18nKey="toImprove">To improve</Trans>
								</h1>
								{this.state.expanded && <Minus width={desktop ? '22' : '18'} height={desktop ? '22' : '18'} />}
								{!this.state.expanded && <PlusExpand width={desktop ? '22' : '16'} height={desktop ? '22' : '16'} />}
							</header>
							{this.state.expanded && (
								<ProductList desktop={desktop}>
									{mistakes.notFixed.results
										.filter(mistake => mistake && mistake.product && mistake.product.identifier)
										.map((mistake, key) => (
											<ProductCard
												key={key}
												mistake={mistake}
												desktop={desktop}
												onClick={() => this.goTo(mistake.product.identifier)}
											/>
										))}
								</ProductList>
							)}
						</StyledSection>
					</>
				)}
				{!fetchingMistakes && mistakes.fixed.results.length > 0 && (
					<StyledSection desktop={desktop}>
						<header>
							<h1>
								<Trans i18nKey="Improved">Improved</Trans>
							</h1>
						</header>
						<ProductList desktop={desktop}>
							{mistakes.fixed.results.map((mistake, key) => (
								<ProductCard icon key={key} mistake={mistake} desktop={desktop} />
							))}
						</ProductList>
					</StyledSection>
				)}
				{!fetchingMistakes && mistakes.fixed.results.length === 0 && mistakes.notFixed.results.length === 0 && (
					<StyledSection desktop={desktop}>
						<header>
							<h1>
								<Trans i18nKey="nothingToReport">Nothing reported yet...</Trans>
							</h1>
						</header>
					</StyledSection>
				)}

				{fetchingMistakes && (
					<>
						<FilterLoading />
						<ProductLoading />
						<ProductLoading />
					</>
				)}
			</Wrapper>
		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	mistakes: getMistakes(state),
	filters: getFilters(state),
	areas: getAreas(state),
	fetchingMistakes: getFetchingMistakes(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			toggleFilter: filter => eventsAction.eventsToggleFilter(filter),
			eventsGetMistakes: () => actions.eventsGetMistakes(),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro));
