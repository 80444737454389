import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import Button from '../../components/Form/Button/Button';
import DesktopCross from '../../components/icons/DesktopCross';
import CheckMark from '../../components/icons/CheckMarkTestbuyQuestions';
import Modal from '../../components/Modal';
import appActions from '../../store/App/app.action';
import { getArea, getAllAreasName, getTotalItemsInArea } from '../../store/App/app.reducer';
import eventsAction from '../../store/Events/events.action';
import actions from '../../store/Products/products.action';
import {
	getProducts,
	getDirections,
	getIsLoadingProducts,
	getIsReview,
	getIsCheckAvailabilityDone,
} from '../../store/Products/products.reducer';
import { getActiveQuestion, getQuestions } from '../../store/Questions/questions.reducer';
import theme from '../../styles/1-settings/theme';

import Product, { ProductLoading } from './components/Product';

const Wrapper = styled.article`
	background-color: white;
	height: ${props => (props.desktop ? 'auto' : 'calc(100% - 60px);')};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-top: 1px solid #dedede;
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
	margin: ${props => (props.desktop ? 'auto' : '0px')};
	overflow: ${props => (props.scroll ? 'auto' : 'hidden')};

	.product-list {
		max-height: ${props => (props.desktop ? '525px' : 'auto')};
		overflow-y: ${props => (props.desktop ? 'auto' : 'hidden')};
	}

	footer {
		display: flex;
		flex-direction: ${props => (props.desktop ? 'row' : 'column')};
		background-color: ${props => (props.desktop ? 'white' : props.theme.colors.GREY)};
		padding: ${props => (props.desktop ? '20px 60px' : '20px')};
		justify-content: center;

		section {
			display: flex;
			flex-direction: column;
			margin: ${props => (props.desktop ? '10px' : '10px 0px')};
			width: ${props => (props.desktop ? '240px' : 'auto')};
		}
	}
`;

const Wave = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	width: 100px;
	padding: 15px;
	margin-left: auto;
	margin-right: auto;
	.dot {
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin-right: 3px;
		background: #303131;
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}
`;

const Saving = () => (
	<Wave>
		<span className="dot" />
		<span className="dot" />
		<span className="dot" />
	</Wave>
);

class ShoppingList extends React.PureComponent {
	state = {
		showModal: false,
		showSelfServiceModal: false,
		showReplaceModal: false,
		enableCheckAvailability: false,
		testbuyFinished: false,
		reportDownloaded: false,
		productToReplace: '',
	};

	componentDidMount() {
		const { products, isCheckAvailabilityDone } = this.props;

		const areAllFinished = products
			.map(p => {
				if (typeof p.isDone !== 'boolean') {
					p.isDone = false;
				}
				return p;
			})
			.filter(p => !p.isDone)
			.reduce(() => false, true);

		const hasSelfService = products.filter(p => p.checkSelfService).reduce(() => true, false);

		if (hasSelfService) {
			this.setState({ hasSelfService: true });
		}

		if (products && products.length && areAllFinished) {
			this.setState({ testbuyFinished: true });
		}

		if (areAllFinished && hasSelfService && !isCheckAvailabilityDone) {
			this.setState({ showSelfServiceModal: true });
			this.setState({ enableCheckAvailability: true });
		}
	}

	resetTestBuy() {
		this.setState({
			showModal: false,
			testbuyFinished: false,
			enableCheckAvailability: false,
			showSelfServiceModal: false,
			reportDownloaded: false,
		});
		this.props.toggleShowDirections({ showDirections: true });
		this.props.emptyShoppingList(); // Empty the current shopping cart
		this.props.resetShoppingList();
		this.props.prefetchData();
		this.props.history.push('/testbuy-intro');
	}

	selectProduct(product) {
		if (product.isDone) return;
		const { selectProduct, history, fireEvent } = this.props;
		const url = getActiveQuestion(this.props.questions, product);
		selectProduct(product);
		history.push(url);
		fireEvent(
			{
				product_identifier: product.identifier,
				area: product.area,
			},
			'product_chosen'
		);
	}

	closeModal = () => {
		this.props.toggleShowDirections({ showDirections: false });
	};

	download = sequenceId => {
		this.props.downloadReport(sequenceId);
		this.setState({ reportDownloaded: true });
	};

	replaceProduct = () => {
		this.props.replaceProduct(this.state.productToReplace);
		this.setState({ showReplaceModal: false, productToReplace: '' });
	};

	render() {
		const {
			products,
			desktop,
			size,
			showDirections,
			area,
			allAreas,
			isLoadingProducts,
			isReview,
			isCheckAvailabilityDone,
			sequenceId,
			isDownloading,
			totalItemsInArea,
		} = this.props;
		const loadingArray = [...Array(size).keys()];

		return (
			<Wrapper scroll={!this.state.showDirections} desktop={desktop} isReview={isReview}>
				{this.state.showModal && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<header>
								<Trans i18nKey="shoppingList.modalHeader">
									Are you sure you want to reset your test buy? Your unfinished testbuys will be deleted.
								</Trans>
							</header>
							<Button desktop={desktop} onClick={() => this.resetTestBuy()}>
								<Trans i18nKey="continue">Continue</Trans>
							</Button>
							<Button desktop={desktop} onClick={() => this.setState({ showModal: false })}>
								<Trans i18nKey="cancel">Cancel</Trans>
							</Button>
						</Modal.Body>
					</Modal>
				)}
				{this.state.showSelfServiceModal && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<header style={{ fontWeight: 'bold', alignSelf: 'center' }}>
								<Trans i18nKey="shoppingList.check">Check availability</Trans>
							</header>
							<header>
								<Trans i18nKey="shoppingList.selfServiceModalHeader1">
									You’ve finished test buying all the products on your shopping list.
								</Trans>
							</header>
							<header style={{ margin: '20px 0px' }}>
								<Trans i18nKey="shoppingList.selfServiceModalHeader2">
									There are a few products for you to check in the Self-service furniture area or Market hall.
								</Trans>
							</header>
							<Button desktop={desktop} onClick={() => this.setState({ showSelfServiceModal: false })}>
								<Trans i18nKey="Done">Done</Trans>
							</Button>
						</Modal.Body>
					</Modal>
				)}
				{this.state.showReplaceModal && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<header>
								<Trans i18nKey="shoppingList.replaceModalHeader">
									Are you sure you want to remove this product from the shopping list and replace it with another?
								</Trans>
							</header>
							<Button desktop={desktop} onClick={this.replaceProduct}>
								<CheckMark color="white" width={desktop ? '25' : '16'} height={desktop ? '25' : '16'} />
							</Button>
							<Button
								desktop={desktop}
								onClick={() => this.setState({ showReplaceModal: false, productToReplace: '' })}
							>
								<DesktopCross color="white" width={desktop ? '25' : '16'} height={desktop ? '25' : '16'} />
							</Button>
						</Modal.Body>
					</Modal>
				)}
				<article>
					<section className="product-list">
						{!isLoadingProducts && products
							? products.map((product, key) => (
									<Product
										key={key}
										name={product.name}
										number={product.identifier}
										isDone={product.isDone}
										image={product.image}
										price={product.price}
										goTo={() => this.selectProduct(product)}
										desktop={desktop}
										area={product.area}
										allAreas={area === allAreas}
										removeFromList={e => {
											e.stopPropagation();
											this.setState({ showReplaceModal: true, productToReplace: product.identifier });
										}}
										canRemove={products.length < totalItemsInArea}
									/>
							  ))
							: loadingArray.map(key => <ProductLoading key={key} />)}
					</section>
					<footer>
						{((this.state.testbuyFinished && this.state.hasSelfService && !isCheckAvailabilityDone) ||
							!this.state.testbuyFinished) && (
							<section>
								<Button
									style={{ height: '100%' }}
									desktop={desktop}
									color={desktop ? 'white' : theme.colors.GREY}
									empty
									disabled={!this.state.enableCheckAvailability}
									onClick={() => this.props.history.push('/check-availability')}
								>
									<Trans i18nKey="shoppingList.check">Check availability</Trans>
								</Button>
							</section>
						)}
						{isReview && (
							<section>
								{!this.state.reportDownloaded ? (
									<Button
										desktop={desktop}
										color={desktop ? 'white' : theme.colors.GREY}
										empty
										onClick={() => this.download(sequenceId)}
										disabled={
											(!this.state.testbuyFinished && !isCheckAvailabilityDone) ||
											(this.state.testbuyFinished && !isCheckAvailabilityDone && this.state.hasSelfService)
										}
									>
										<Trans i18nKey="shoppingList.finishAndReport">Finish and generate report</Trans>
									</Button>
								) : isDownloading ? (
									<Saving />
								) : (
									<Button
										desktop={desktop}
										color={desktop ? 'white' : theme.colors.GREY}
										empty
										onClick={() => this.resetTestBuy()}
										disabled={
											(!this.state.testbuyFinished && !isCheckAvailabilityDone) ||
											(this.state.testbuyFinished && !isCheckAvailabilityDone && this.state.hasSelfService)
										}
									>
										<Trans i18nKey="shoppingList.startNew">Start new test buy</Trans>
									</Button>
								)}
							</section>
						)}
						{(!isReview && this.state.testbuyFinished && isCheckAvailabilityDone) ||
						(!isReview && this.state.testbuyFinished && !this.state.hasSelfService) ? (
							<section>
								<Button
									style={{ height: '100%' }}
									desktop={desktop}
									color={desktop ? 'white' : theme.colors.GREY}
									empty
									onClick={() => this.resetTestBuy()}
								>
									<Trans i18nKey="shoppingList.finishTestbuy">Finish test buy</Trans>
								</Button>
							</section>
						) : !this.state.testbuyFinished ||
						  (this.state.testbuyFinished && !isCheckAvailabilityDone && this.state.hasSelfService) ? (
							<section>
								<Button
									style={{ height: '100%' }}
									desktop={desktop}
									color={desktop ? 'white' : theme.colors.GREY}
									empty
									onClick={() => this.setState({ showModal: true })}
								>
									<Trans i18nKey="shoppingList.reset">Reset test buy</Trans>
								</Button>
							</section>
						) : (
							<></>
						)}
					</footer>
				</article>
				{showDirections && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<header>
								<Trans i18nKey="shoppingList.directions">
									All right, let's do this!
									<br />
									These are the items on your shopping list for today.
								</Trans>
							</header>
							<header>
								<Trans i18nKey="shoppingList.walkAround">Walk around the store and find the products one by one.</Trans>
							</header>
							<Button desktop={desktop} onClick={this.closeModal}>
								<Trans i18nKey="start">Start</Trans>
							</Button>
						</Modal.Body>
					</Modal>
				)}
			</Wrapper>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	products: getProducts(state),
	size: state.products && state.products.size,
	questions: getQuestions(state),
	showDirections: getDirections(state),
	area: getArea(state),
	allAreas: getAllAreasName(state),
	isLoadingProducts: getIsLoadingProducts(state),
	isReview: getIsReview(state),
	isCheckAvailabilityDone: getIsCheckAvailabilityDone(state),
	sequenceId: state.events && state.events.sequenceId,
	isDownloading: state.events && state.events.isDownloading,
	totalItemsInArea: getTotalItemsInArea(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			selectProduct: product => actions.selectProduct(product),
			emptyShoppingList: () => actions.settShoppingList([]),
			resetShoppingList: () => actions.resetShoppingList(),
			replaceProduct: product => actions.replaceProductStarted(product),
			toggleShowDirections: showDirections => actions.toggleShowDirections(showDirections),
			fireEvent: (data, event) => eventsAction.eventsFire({ data, event }),
			prefetchData: () => appActions.appGetPrefetchData(),
			downloadReport: event => eventsAction.eventsDownloadReport(event),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingList));
