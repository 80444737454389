import React from 'react';
import styled from 'styled-components';
import theme from '../../../styles/1-settings/theme';
import { withRouter } from 'react-router';

const blocks = [
  {
    name: 'Fundamentals',
    color: theme.colors.YELLOW,
  },
  {
    name: 'Plan',
    color: theme.colors.ORANGE,
  },
  {
    name: 'Implements',
    color: theme.colors.PINK,
  },
  {
    name: 'Maintain',
    color: theme.colors.PURPLE,
  },
  {
    name: 'Monitor',
    color: theme.colors.LIGHT_BLUE,
  },
  {
    name: 'Supporting Knowledge',
    color: theme.colors.LIGHT_GREEN,
  },
];

const BlockListWrapper = styled.section`
  display: flex;
  flex-direction: column;
`;

const Block = styled.article`
  display: flex;
  align-items: center;
  background-color: ${props => props.color};
  flex: 1;
  height: 100px;
  padding-left: 40px;
  font-weight: bold;
  font-size: 20px;
  color: ${props => props.theme.colors.WHITE};
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  &:last-child {
    margin-bottom: 0;
  }
`;

class BlockList extends React.PureComponent {
  goTo = name => {
    const url = `/cmblocks/${name.toLowerCase().replace(/\s/g, '_')}`;
    this.props.history.push(url);
  };

  render() {
    return (
      <BlockListWrapper>
        {blocks.map((block, key) => (
          <Block
            color={block.color}
            key={key}
            onClick={() => this.goTo(block.name)}
          >
            {key > 0 && key !== block.length - 1 ? key : ''} {block.name}
          </Block>
        ))}
      </BlockListWrapper>
    );
  }
}

export default withRouter(BlockList);
