import React from 'react';
import styled from 'styled-components';
import Home from '../../modules/Home/Home';
import { withBreakpoints } from 'react-breakpoints';
import DesktopContainer from '../../components/DesktopContainer/DesktopContainer';

const AppContainer = styled.section`
	display: flex;
	height: calc(100vh - 80px);
	.AppContainer__Nav {
		width: 32%;
	}
	.AppContainer__Desktop {
		flex: 1;
		width: 68%;
	}
`;

class _AppContainer extends React.PureComponent {
	componentDidMount() {
		if (this.props.breakpoints[this.props.currentBreakpoint] > this.props.breakpoints.mobileLandscape) {
			document.getElementById('root').style.overflow = 'hidden';
		}
	}

	render() {
		const { breakpoints, currentBreakpoint, render, noMenu } = this.props;

		return breakpoints[currentBreakpoint] > breakpoints.mobileLandscape ? (
			<AppContainer>
				{!noMenu && (
					<div className="AppContainer__Nav">
						<Home desktop={true} />
					</div>
				)}
				{breakpoints[currentBreakpoint] > breakpoints.mobileLandscape && (
					<div className="AppContainer__Desktop">
						<DesktopContainer>
							<DesktopContainer.Slot>{render(true)}</DesktopContainer.Slot>
						</DesktopContainer>
					</div>
				)}
			</AppContainer>
		) : (
			render()
		);
	}
}

export default withBreakpoints(_AppContainer);
