import React from 'react';

class Close extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 16.8 16.8"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <path
          d="M9.8,8.4l6.7-6.7c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L8.4,7L1.7,0.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L7,8.4l-6.7,6.7
	   c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l6.7-6.7l6.7,6.7c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
	   c0.4-0.4,0.4-1,0-1.4L9.8,8.4z"
        />
      </svg>
    );
  }
}

Close.defaultProps = {
  width: 24,
  height: 24,
  color: 'white',
};

export default Close;
