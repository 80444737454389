import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import IntroSlider from '../../components/Slider/IntroTestBuySlider';
import actions from '../../store/App/app.action';

class IntroTestbuy extends React.PureComponent {
	markIntroSeen = () => {
		const { history, appSetTestBuyIntroDone } = this.props;
		appSetTestBuyIntroDone();
		history.push('/select-area');
	};

	render() {
		return <IntroSlider desktop={this.props.desktop} goTo={this.markIntroSeen} video={{ title: 'How to testbuy' }} />;
	}
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			appSetTestBuyIntroDone: () => actions.appSetTestBuyIntroDone(),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IntroTestbuy));

// babel-plugin-i18next-extract: Extraction error in /Users/stenmuchow/Work/ikea-askholmen/src/modules/FAQ/FAQ.js at line 26. Couldn't evaluate i18next key. You should either make the key evaluable or skip the line using a skip comment (/* i18next-extract-disable-line */ or /* i18next-extract-disable-next-line */).
// babel-plugin-i18next-extract: Extraction error in /Users/stenmuchow/Work/ikea-askholmen/src/modules/FAQ/FAQ.js at line 26. Couldn't evaluate i18next key. You should either make the key evaluable or skip the line using a skip comment (/* i18next-extract-disable-line */ or /* i18next-extract-disable-next-line */).
// babel-plugin-i18next-extract: Extraction error in /Users/stenmuchow/Work/ikea-askholmen/src/modules/FAQ/FAQ.js at line 27. Couldn't evaluate i18next key. You should either make the key evaluable or skip the line using a skip comment (/* i18next-extract-disable-line */ or /* i18next-extract-disable-next-line */).
