import React from 'react';

class CloseIcon extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 24 24"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}>
        <path d="M14.8 12l3.6-3.6c.8-.8.8-2 0-2.8-.8-.8-2-.8-2.8 0L12 9.2 8.4 5.6c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8L9.2 12l-3.6 3.6c-.8.8-.8 2 0 2.8.4.4.9.6 1.4.6s1-.2 1.4-.6l3.6-3.6 3.6 3.6c.4.4.9.6 1.4.6s1-.2 1.4-.6c.8-.8.8-2 0-2.8L14.8 12z"/>
      </svg>
    );
  }
}

CloseIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#a7a7a7',
};

export default CloseIcon;