import { SET_MISTAKE_PHOTO } from './camera.actions';

const initialState = {
  mistake: {},
};

export default function camera(state = initialState, action) {
  switch (action.type) {
    case SET_MISTAKE_PHOTO:
      return {
        ...state,
        mistake: action.photo,
      };
    default:
      return state;
  }
}

export const getPhoto = state => state && state.camera && state.camera.mistake;
