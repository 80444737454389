import React from 'react';
import styled from 'styled-components';
import { PageWrapper } from '../../components/PageWrapper';
import Button from '../../components/Form/Button/Button';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import baseLanguages from '../../languages';

const srtLanguages = [
	'ar',
	'bg',
	'cs',
	'da',
	'de',
	'el',
	//en
	'es',
	'et',
	'fi',
	'fr-ca',
	'fr-fr',
	'he',
	'hr',
	'hu',
	'id',
	'is',
	'it',
	'ja',
	'ko',
	'lt',
	'lv',
	//ml
	'nb',
	'nl',
	'ml', //missing
	'pl',
	'pt',
	'ro',
	'ru',
	'sk',
	'sl',
	'uk',
	'sv',
	'th',
	'tr',
	'zh-cn',
	'zh-hk',
	'zh-tw',
	'is',
	'sr',
];

export const languages = baseLanguages
	.filter(l => srtLanguages.indexOf(l.code) !== -1)
	.map(l => ({ ...l, ...{ code: l.code.replace(/-/, '_') } }));

export const Wrapper = styled(PageWrapper)`
	overflow: auto;
	background-color: white;
	width: ${props => (props.desktop ? '70vw' : 'auto')};
	margin: auto;
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
	min-height: calc(100% - 60px);

	${props =>
		props.desktop &&
		css`
			text-align: center;
			padding: '50px 120px';
		`}

	${props =>
		props.small &&
		css`
			height: auto;
		`};

	h2 {
		font-size: 36px;
		font-weight: bold;
	}
	p {
		font-size: 18px;
		margin-top: 20px;
		line-height: 30px;
	}
	.slick-slider,
	.slick-list,
	.slick-track {
		height: 100%;
		div {
			height: 100%;
		}
	}

	.slick-slide > div {
		overflow-y: auto;
	}

	.slick-dots {
		top: ${props => (props.desktop ? '0px' : '-30px')};
		bottom: 0;
		height: 25px;

		li {
			margin: 0 2px;
			button:before {
				font-size: 12px;
			}
		}
	}
`;

export const Slide = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100%;
	padding: 2px;
	overflow: auto;
	video::-webkit-media-text-track-display {
		top: -20% !important;
	}
	header {
		margin-top: ${props => (props.desktop ? '80px' : '50px')};
	}
	footer {
		margin-top: ${props => (props.desktop ? '0px' : '50px')};
		margin-bottom: ${props => (props.first ? '0px' : '36px')};
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 106px;
		padding-bottom: 32px;
		.slide__skip {
			text-decoration: underline;
			text-align: center;
			margin-top: 20px;
			color: black;
			margin-bottom: 4px;
		}
	}
`;

const HowToVideosWrapper = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	overflow: hidden;
`;

const HowToVideo = styled.li`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	width: 100%;

	p {
		background: ${props => props.theme.colors.WHITE};
		padding: 10px;
		font-size: 16px;
		flex: 1;
	}
	video {
		flex: 1;
		width: ${props => (props.desktop ? '70%' : '100%')};
		height: auto;
	}
`;

export const Slide1 = ({ goTo, skip, desktop, rtl }) => {
	const { t } = useTranslation();
	return (
		<Slide desktop={desktop} first dir={rtl ? 'rtl' : 'ltr'}>
			<header>
				<p>
					{t('intro.slide1', {
						defaultValue: 'As IKEA, we want to ensure an easy and convenient shopping experience in all our stores.',
					})}
				</p>
			</header>

			<footer>
				<Button block notWide={desktop} onClick={goTo}>
					{t('next', { defaultValue: 'Next' })}
				</Button>
				<div className="slide__skip" onClick={skip}>
					{t('intro.skip', { defaultValue: 'Skip Intro' })}
				</div>
			</footer>
		</Slide>
	);
};

export const Slide1TB = ({ goTo, skip, desktop, rtl }) => {
	const { t } = useTranslation();
	return (
		<Slide desktop={desktop} first dir={rtl ? 'rtl' : 'ltr'}>
			<header>
				<p>{t('testbuySlide.slide1.header1', { defaultValue: 'It is almost time to do some shopping.' })}</p>
				<p>
					{t('testbuySlide.slide1.header2', {
						defaultValue: 'Want to know where to start? Watch the video on how to do a test buy with this app.',
					})}
				</p>
			</header>

			<footer>
				<Button block notWide={desktop} onClick={goTo}>
					{t('next', { defaultValue: 'Next' })}
				</Button>
				<div className="slide__skip" onClick={skip}>
					{t('intro.skip', { defaultValue: 'Skip Intro' })}
				</div>
			</footer>
		</Slide>
	);
};

export const Slide2 = ({ goTo, desktop, rtl }) => {
	const { t } = useTranslation();

	return (
		<Slide desktop={desktop} dir={rtl ? 'rtl' : 'ltr'}>
			<header>
				<p>
					{t('intro.slide2', {
						defaultValue:
							'Doing a test buy is all about seeing products through the customer’s eyes. Please watch the movie that explains why this is so important.',
					})}
				</p>
			</header>

			<footer>
				<Button block notWide={desktop} onClick={goTo}>
					{t('next', { defaultValue: 'Next' })}
				</Button>
			</footer>
		</Slide>
	);
};

export const Slide2TB = ({ goTo, desktop, language, country }) => {
	const { t } = useTranslation();
	const getSource = code => require(`../../assets/videos/how/how_${code}.vtt`);
	const isDefault = code => {
		if (country.name === 'Canada' && language.code === 'fr-ca' && code === 'fr_ca') {
			return true;
		}

		return language.code.replace(/-/, '_') === code;
	};

	return (
		<Slide>
			<header>
				<HowToVideosWrapper>
					<HowToVideo desktop={desktop}>
						<video
							src="https://qollap.content.qollap.com/media/documents/how-ikea.fe6ea3ee.mp4"
							width={320}
							autoPlay={false}
							preload="metadata"
							type="video/mp4"
							controls
							poster="https://ikea-askholmen.content.qollap.com/media/images/how_testbuy.original.png"
						>
							{languages.map(track => (
								<track
									key={track.code}
									label={track.name}
									kind="subtitles"
									src={getSource(track.code)}
									default={isDefault(track.code)}
								/>
							))}
						</video>
					</HowToVideo>
				</HowToVideosWrapper>
			</header>

			<footer>
				<Button block notWide={desktop} onClick={goTo}>
					{t('letsShop', "Let's shop")}
				</Button>
			</footer>
		</Slide>
	);
};

export const Slide3 = ({ goTo, desktop, country, language }) => {
	const { t } = useTranslation();
	const getSource = code => require(`../../assets/videos/why/why_${code}.vtt`);
	const isDefault = code => {
		if (country.name === 'Canada' && language.code === 'fr-ca' && code === 'fr_ca') {
			return true;
		}

		return language.code.replace(/-/, '_') === code;
	};
	return (
		<Slide>
			<header>
				<HowToVideosWrapper>
					<HowToVideo desktop={desktop}>
						<video
							src="https://qollap.content.qollap.com/media/documents/why-ikea.mp4"
							width={320}
							autoPlay={false}
							preload="metadata"
							type="video/mp4"
							controls
							poster="https://ikea-askholmen.content.qollap.com/media/images/why_ikea_video_poster.original.png"
						>
							{languages.map(track => (
								<track
									key={track.code}
									label={track.name}
									kind="subtitles"
									src={getSource(track.code)}
									default={isDefault(track.code)}
								/>
							))}
						</video>
					</HowToVideo>
				</HowToVideosWrapper>
			</header>

			<footer>
				<Button block notWide={desktop} onClick={goTo}>
					{t('intro.start', { defaultValue: "Let's Get Started" })}
				</Button>
			</footer>
		</Slide>
	);
};

export const Slide4 = ({ goTo, desktop, rtl }) => {
	const { t } = useTranslation();
	return (
		<Slide desktop={desktop} dir={rtl ? 'rtl' : 'ltr'}>
			<header>
				<p>
					{t('intro.slide4', {
						defaultValue:
							'This Test buy app is really easy to use. You can do a test buy, solve mistakes and learn more about the IKEA commercial merchandising techniques.',
					})}
				</p>
			</header>

			<footer>
				<Button block notWide={desktop} onClick={goTo}>
					{t('next', { defaultValue: 'Next' })}
				</Button>
			</footer>
		</Slide>
	);
};
