import React from 'react';
import styled from 'styled-components';
import CheckIcon from '../../../components/icons/CheckIcon';
import CloseIcon from '../../../components/icons/CloseIcon';
import { Trans } from 'react-i18next';

const Wrapper = styled.article`
	padding: ${props => (props.desktop ? '25px 50px 25px 50px' : '20px 20px 0px 20px')};
	background-color: white;
	margin: ${props => (props.desktop ? '0px' : '0px 0px 15px 0px')};
	height: 140px;
	display: flex;
	flex-direction: column;
	box-shadow: ${props => (props.desktop ? 'none' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)')};
	border-bottom: ${props => (props.desktop ? '1px solid #dedede' : '0px solid black')};

	article {
		display: flex;
		justify-content: space-between;
	}

	section {
		display: flex;
		width: calc(100% - 100px);
		flex-direction: column;
	}

	header {
		font-size: 18px;
		font-weight: bold;
		line-height: 22px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	footer {
		margin-top: auto;
		padding-bottom: ${props => (props.desktop ? '0px' : '30px')};
		flex-direction: row !important;
		justify-content: space-between;
	}

	p {
		margin-top: 5px;
		font-size: 14px;
	}
`;

const RoundButton = styled.button`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 25px;
	border: none;
	margin-left: 20px;
	background-color: ${props => (props.checked ? props.theme.colors.ORANGE : 'rgba(245, 131, 55, 0.3)')};
	color: white;
	padding: 0px;
`;

const ButtonWrapper = styled.div`
	display: flex;
`;

class ProductWithLocation extends React.PureComponent {
	state = {
		checked1: false,
		checked2: false,
	};

	render() {
		const {
			product: { name, identifier, location = 'not specified', price },
			markProduct,
			isAvailable,
			desktop,
		} = this.props;

		return (
			<Wrapper desktop={desktop}>
				<article>
					<section>
						<header>{name}</header>
						<p>{identifier}</p>
					</section>
					<ButtonWrapper>
						<RoundButton desktop={desktop} checked={isAvailable === true} onClick={() => markProduct(identifier, true)}>
							<CheckIcon width={'20'} height={'20'} color={'white'} bold />
						</RoundButton>
						<RoundButton
							desktop={desktop}
							checked={isAvailable === false}
							onClick={() => markProduct(identifier, false)}
						>
							<CloseIcon width={'20'} height={'20'} color={'white'} />
						</RoundButton>
					</ButtonWrapper>
				</article>
				<footer>
					<div>
						<p style={{ fontWeight: 'bold' }}>
							<Trans i18nKey="location">Location:</Trans>
						</p>
						<p>{location}</p>
					</div>
					<p style={{ fontWeight: 'bold' }}>{price}</p>
				</footer>
			</Wrapper>
		);
	}
}

export default ProductWithLocation;
