import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router';
import AppContainer from './components/AppContainer/AppContainer';

//Home
import Home from './modules/Home/Home';

//Testbuy
import TestbuyIntro from './modules/TestBuy/Intro';
import SelectArea from './modules/SelectArea/SelectArea';
import ChooseTestbuys from './modules/TestBuy/ChooseTestbuys';
import ShoppingList from './modules/TestBuy/ShoppingList';
import QuestionIntro from './modules/TestBuy/QuestionIntro';
import Question from './modules/TestBuy/Question';
import MoreInfo from './modules/TestBuy/MoreInfo';
import QuestionSummary from './modules/TestBuy/QuestionSummary';
import CheckAvailability from './modules/TestBuy/CheckAvailability';
import CheeringScreen from './modules/TestBuy/CheeringScreen';
import CommentMistake from './modules/TestBuy/CommentMistake';

//Review
import ReviewShoppingList from './modules/Review/ShoppingList';
import ReviewSearch from './modules/Review/Search';

//Stream
import Stream from './modules/Stream/Intro';
import StreamFilter from './modules/Stream/Filter';
import ProductDetail from './modules/Stream/ProductDetail';
import MistakeDetail from './modules/Stream/MistakeDetail';
import MoreInfoStream from './modules/Stream/MoreInfo';

//CmBlocks
import CmBlocks from './modules/CmBlocks/modules/Intro';
import SelectedBlockList from './modules/CmBlocks/modules/SelectedBlockList';
import TaskDetail from './modules/CmBlocks/modules/TaskDetail';

//CmExercises
import CmExercises from './modules/CmExercises/Intro';

//Learn
import Learn from './modules/Learn/Learn';
import WhatAndWhy from './modules/Learn/WhatAndWhy';
import PracticeIntro from './modules/Learn/PracticeIntro';
import PracticeQuestion from './modules/Learn/PracticeQuestion';
import PracticeSummary from './modules/Learn/PracticeSummary';
import PracticeVideo from './modules/Learn/Video';
import PracticeImage from './modules/Learn/Image';

import Intro from './modules/Intro/Intro';
import Open from './modules/Open/Open';
import SelectCountry from './modules/SelectCountry/SelectCountry';
import SelectStore from './modules/SelectStore/SelectStore';
import SelectLanguage from './modules/SelectLanguage/SelectLanguage';
import FAQ from './modules/FAQ/FAQ';

import Icons from './modules/Icons/Icons';
import Camera from './modules/Camera/Camera';

class Routes extends React.PureComponent {
	render() {
		return (
			<React.Fragment>
				<Switch>
					<Route
						exact
						path="/"
						component={() => <AppContainer noMenu render={desktop => <SelectLanguage desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/home"
						component={() => <AppContainer noMenu render={desktop => <Open desktop={desktop} />} />}
					/>
					<Route exact path="/icons" component={Icons} />
					<Route exact path="/camera/:productId/:categoryType/:questionId" component={Camera} />
					<Route
						exact
						path="/select-country"
						component={() => <AppContainer noMenu render={desktop => <SelectCountry desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/select-store"
						component={() => <AppContainer noMenu render={desktop => <SelectStore desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/intro"
						component={() => <AppContainer noMenu render={desktop => <Intro desktop={desktop} />} />}
					/>
					<Route exact path="/home" component={Home} />
					<Route
						exact
						path="/testbuy-intro"
						component={() => <AppContainer noMenu render={desktop => <TestbuyIntro desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/testbuy"
						component={() => <AppContainer render={desktop => <ChooseTestbuys desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/select-area"
						component={() => <AppContainer noMenu render={desktop => <SelectArea desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/shopping-list"
						component={() => <AppContainer render={desktop => <ShoppingList desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/question-intro"
						component={() => <AppContainer render={desktop => <QuestionIntro desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/question/:id"
						component={() => <AppContainer render={desktop => <Question desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/question/:id/more-info"
						component={() => <AppContainer render={desktop => <MoreInfo desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/question-final"
						component={() => <AppContainer render={desktop => <QuestionSummary desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/check-availability"
						component={() => <AppContainer render={desktop => <CheckAvailability desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/comment/:productId/:categoryType/:questionId"
						component={() => <AppContainer render={desktop => <CommentMistake desktop={desktop} />} />}
					/>

					<Route exact path="/cheering-screen" component={CheeringScreen} />
					<Route
						exact
						path="/review"
						component={() => <AppContainer render={desktop => <ReviewShoppingList desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/review/search"
						component={() => <AppContainer render={desktop => <ReviewSearch desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/store-stream"
						component={() => <AppContainer render={desktop => <Stream desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/store-stream/filter"
						component={() => <AppContainer render={desktop => <StreamFilter desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/store-stream/:id"
						component={() => <AppContainer render={desktop => <ProductDetail desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/store-stream/:id/:questionId"
						component={() => <AppContainer render={desktop => <MistakeDetail desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/store-stream/more-info/:id/:questionId/"
						component={() => <AppContainer render={desktop => <MoreInfoStream desktop={desktop} />} />}
					/>
					<Route exact path="/cmblocks" component={CmBlocks} />
					<Route exact path="/cmblocks/:id" component={SelectedBlockList} />
					<Route exact path="/cmblocks/:id/:task" component={TaskDetail} />

					<Route exact path="/cmexercises" component={CmExercises} />

					<Route
						exact
						path="/learn"
						component={() => <AppContainer render={desktop => <Learn desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/learn/:id/:material"
						component={() => <AppContainer render={desktop => <WhatAndWhy desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/learn/:id/video/:material"
						component={() => <AppContainer render={desktop => <PracticeVideo desktop={desktop} />} />}
					/>
					<Route exact path="/practice/:id/:question/image" component={PracticeImage} />
					<Route
						exact
						path="/practice/:id"
						component={() => <AppContainer render={desktop => <PracticeIntro desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/practice/:id/:question"
						component={() => <AppContainer render={desktop => <PracticeQuestion desktop={desktop} />} />}
					/>
					<Route
						exact
						path="/practice-summary/:id"
						component={() => <AppContainer render={desktop => <PracticeSummary desktop={desktop} />} />}
					/>
					<Route exact path="/faq" component={() => <AppContainer render={desktop => <FAQ desktop={desktop} />} />} />
				</Switch>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
