import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/App/app.action';
import products from '../../store/Products/products.action';
import styled from 'styled-components';
import { PageWrapper } from '../../components/PageWrapper';
import { SelectList, SelectListItem } from '../../components/Form/SelectList';
import BackArrowDesktop from '../../components/icons/BackArrowDesktop';
import ArrowRightDesktop from '../../components/icons/ArrowRightDesktop';
import { getAreas } from '../../store/App/app.reducer';
import { Trans } from 'react-i18next';

const Wrapper = styled(PageWrapper)`
  padding: ${props => (props.desktop ? '20px 50px' : '0px')};
  margin: auto;
  height: ${props => (props.desktop ? 'calc(100vh - 170px)' : 'calc(100% - 60px)')};
  width: 100%;
  box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
  position: relative;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    div {
      width: 40px;
    }
  }
`;

const ButtonContainer = styled.section`
  position: absolute;
  top: 50%;
  transform: ${props => (props.left ? 'rotate(180deg)' : 'none')};
  right: ${props => (props.left ? 'auto' : '20px')};
  left: ${props => (props.left ? '20px' : 'auto')};
  opacity: ${props => (props.disabled ? '0.2' : '1')};
`;

class Intro extends React.PureComponent {
  constructor(props) {
    super(props);
    this.scroller = React.createRef();
  }

  state = {
    activeLeft: false,
    lastClickedLeft: true,
    activeRight: true,
  };

  selectArea = area => {
    const { history, resetShoppingList, setArea } = this.props;
    resetShoppingList();
    setArea(area);
    history.push('/testbuy');
  };

  onClickRight = () => {
    this.scroller.current.scrollLeft += 800;
    if (this.scroller.current.scrollLeft % 8 !== 0 && this.state.lastClickedLeft) {
      this.setState({ activeLeft: true, lastClickedLeft: false, activeRight: false });
    } else {
      this.setState({ activeRight: true, activeLeft: true });
    }
  };

  onClickLeft = () => {
    this.scroller.current.scrollLeft -= 800;
    if (this.scroller.current.scrollLeft === 0) {
      this.setState({ activeLeft: false, lastClickedLeft: true, activeRight: true });
    } else {
      this.setState({ activeRight: true, lastClickedLeft: true });
    }
  };

  render() {
    const { areas, history, desktop } = this.props;

    return (
      <Wrapper desktop={desktop} className="PageWrapper">
        {desktop && (
          <header>
            <BackArrowDesktop width={'30'} height={'30'} onClick={() => history.goBack()} />
            <h1>
              <Trans i18nKey="selectArea.selector">Choose area</Trans>
            </h1>
            <div />
          </header>
        )}
        {desktop && (
          <ButtonContainer disabled={!this.state.activeLeft} onClick={this.onClickLeft.bind(this)} left>
            <ArrowRightDesktop width={'22'} height={'22'} />
          </ButtonContainer>
        )}
        <SelectList ref={this.scroller} desktop={desktop}>
          {areas &&
            areas.map((a, key) => (
              <SelectListItem store desktop={desktop} key={key} onClick={() => this.selectArea(a)}>
                <span>{a.name}</span>
              </SelectListItem>
            ))}
        </SelectList>
        {desktop && (
          <ButtonContainer disabled={!this.state.activeRight} onClick={this.onClickRight.bind(this)}>
            <ArrowRightDesktop width={'22'} height={'22'} />
          </ButtonContainer>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  areas: getAreas(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setArea: area => actions.appSetArea(area),
      resetShoppingList: () => products.resetShoppingList(),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Intro)
);
