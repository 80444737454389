const initialState = {
	faqs: [
		{
			question: 'Does the IKEA Test buy app store any personal data?',
			answer:
				'Absolutely not! The app only stores the data from the test buy you do. We do not store any personal data. The test buy is completely anonymous.',
			key: 1,
		},
		{
			question: 'What happens when I report an issue?',
			answer:
				'When you report an issue, it is sent to the To do list. This list is an overview of all registered issues. This means that you or another co-worker can take action to solve issues later. The list also gives you insight on the most common issues in your IKEA store.',
			key: 3,
		},
		{
			question: 'Why am I not able to take a photo of an issue?',
			answer:
				'Some iPhone versions have a bug in the operating system (OS). To prevent any problems during test buys, we disabled the camera, so you can only describe the issue in the comment box.',
			key: 4,
		},
		{
			question: 'How do I change my store?',
			answer: 'Open the app menu and select “Change store”.',
			key: 5,
		},
		{
			question: 'What happens if I cannot finish the test buys for the articles I have selected?',
			answer: 'No worries - you can always stop. Any articles you have already done will have been captured.',
			key: 7,
		},
		{
			question: 'Why do not I see the mistake I reported in the To do list?',
			answer: 'Don’t worry - can take several minutes before the article appears on the To do list.',
			key: 8,
		},
		{
			question:
				'I have solved some of the open issues on the To-do list, but I can still see the products on the list.',
			answer:
				'This is correct. Although you have solved the issue, the products will still appear in the list for 30 days. This also gives you insight on the most common mistakes in your store.',
			key: 9,
		},
		{
			question: 'What should I do when the test-buy application does not work?',
			answer:
				'Oops, that should not happen! Sorry for the inconvenience. For all queries about the test-buy app, please contact us via: <a href="mailto:learning@inter.IKEA.com">learning@inter.IKEA.com</a> and provide us with the following details:<ol><li>1. Which Operating system are you using?</li><li>2. Browser and browser version?</li><li>3. Which steps have you taken in the app?</li><li>4. Expected result (what do you expect to happen?)</li><li>5. Actual result (what happens instead?)</li><li>6. Please provide screenshots or screen captures if possible.</li></ol>',
			key: 12,
		},
	],
};

const faqsReducer = (state = initialState, action) => {
	switch (action.type) {
		default:
			return state;
	}
};

export default faqsReducer;

export function getFAQs(state) {
	return state.faqs && state.faqs.faqs;
}
