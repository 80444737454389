import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HeaderWrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.WHITE};
  padding: 20px;
  margin-bottom: 20px;
`;

const HeaderVideo = styled.div`
  display: flex;
  flex: 1;
  height: 200px;
`;

function Header() {
  const { t } = useTranslation();
  return (
    <HeaderWrapper>
      <HeaderVideo />
      <p>{t('header.title', { defaultValue: 'Learn why Commercial Merchandising is a key process within IKEA.' })}</p>
    </HeaderWrapper>
  );
}

export default Header;
