import {
  QUESTION_DONE,
  CATEGORY_SOLVED,
  SET_CATEGORIES,
  SET_MORE_INFO,
  SET_QUESTIONS,
  RESET_MPC_QUESTION,
} from './questions.action';

const initialState = {
  categories: [],
  questions: [],
  moreInfos: [],
  questionsDone: [],
};

const markQuestionDone = (questions, { questionId, productId, hasMistake }) =>
  questions.map(q => {
    if (q.id === parseInt(questionId, 10)) {
      q.done = q.done ? q.done.concat([productId]) : [productId];
      q.hasMistake = q.hasMistake || [];
      q.hasMistake = hasMistake ? q.hasMistake.concat([productId]) : q.hasMistake;
    }
    return q;
  });

const markCategorySolved = (questions, { category, productId }) =>
  questions.map(q => {
    if (q.category === category) {
      q.hasMistake = q.hasMistake || [];
      q.hasMistake = q.hasMistake.filter(id => id !== productId);
    }
    return q;
  });

const setQuestions = questions =>
  questions.map(q => {
    if (q.type === 'mpc' && q.options.length === 2) {
      q.type = q.options[0].label.toLowerCase() === '✓' || q.options[0].label.toLowerCase() === '✗' ? 'y/n' : q.type;
    }
    return q;
  });

const resetMPCQuestion = (questions, question) =>
  questions.map(q => {
    if (q.id === question.id) {
      q.options = q.options.map(o => {
        o.selected = false;
        return o;
      });
      return q;
    }
    return q;
  });

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUESTION_DONE:
      return {
        ...state,
        questions: markQuestionDone(state.questions, action),
      };
    case CATEGORY_SOLVED:
      return {
        ...state,
        questions: markCategorySolved(state.questions, action),
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    case SET_MORE_INFO:
      return {
        ...state,
        moreInfos: action.more_infos,
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questions: setQuestions(action.questions),
      };
    case RESET_MPC_QUESTION:
      return {
        ...state,
        questions: resetMPCQuestion(state.questions, action.question),
      };
    default:
      return state;
  }
};

export default questionsReducer;

export function getQuestions(state) {
  return state.questions && state.questions.questions;
}

export function getMoreInfos(state) {
  return state.questions && state.questions.moreInfos;
}

export function getCategories(state) {
  return (
    state.questions &&
    state.questions.categories &&
    state.questions.categories.map(category => {
      category.hasMistakes = state.questions.questions
        .filter(question => question.category === category.type)
        .filter(
          question => question.hasMistake && question.hasMistake.indexOf(state.products.product.identifier) !== -1
        )
        .reduce((memo, hasMistake) => true, false);
      category.mistakeMarked =
        state.products.product &&
        state.products.product.mistakeMarked &&
        state.products.product.mistakeMarked.filter(m => m.categoryType === category.type).reduce(() => true, false);

      category.questionId = state.questions.questions
        .filter(question => question.category === category.type)
        .filter(
          question => question.hasMistake && question.hasMistake.indexOf(state.products.product.identifier) !== -1
        )
        .reduce((memo, question) => question.id, null);
      return category;
    })
  );
}

export function getActiveQuestion(questions, product) {
  return product.summaryReached
    ? '/question-final'
    : questions
        .map((question, index) => {
          if (question.done && question.done.indexOf(product.identifier) !== -1) {
            return questions.length === index + 1 ? '/question-final' : `/question/${questions[index + 1].id}`;
          }
          return false;
        })
        .filter(url => url)
        .reduce((memo, url) => url, '/question-intro');
}

export function getPreviousQuestion(questions, currentId) {
  return questions.map(q => q.id).reduce((memo, id, index, questions) => {
    if (id === parseInt(currentId, 10)) {
      if (index === 0) {
        return '/shopping-list';
      }
      return `/question/${questions[index - 1]}`;
    }
    return memo;
  }, false);
}
