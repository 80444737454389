export const QUESTION_DONE = 'QUESTION_DONE';
export const CATEGORY_SOLVED = 'CATEGORY_SOLVED';
export const QUESTIONS_FETCH = 'QUESTIONS_FETCH';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_MORE_INFO = 'SET_MORE_INFO';
export const QUESTIONS_ADD_MISTAKE = 'QUESTIONS_ADD_MISTAKE';
export const RESET_MPC_QUESTION = 'RESET_MPC_QUESTION';

const questionDone = (questionId, productId, hasMistake) => ({
  type: QUESTION_DONE,
  questionId,
  productId,
  hasMistake,
});

const markCategorySolved = (category, productId) => ({
  type: CATEGORY_SOLVED,
  category,
  productId,
});

const fetchQuestions = () => ({
  type: QUESTIONS_FETCH,
});

const setCategories = categories => ({
  type: SET_CATEGORIES,
  categories,
});

const setQuestions = questions => ({
  type: SET_QUESTIONS,
  questions,
});

const setMoreInfos = more_infos => ({
  type: SET_MORE_INFO,
  more_infos,
});

const resetMPCQuestion = question => ({
  type: RESET_MPC_QUESTION,
  question,
});

const actions = {
  questionDone,
  markCategorySolved,
  fetchQuestions,
  setCategories,
  setQuestions,
  setMoreInfos,
  resetMPCQuestion,
};

export default actions;
