export const MISTAKES_ADD = 'MISTAKES_ADD';
export const MISTAKES_FETCH = 'MISTAKES_FETCH';
export const MISTAKES_SET = 'MISTAKES_SET';
export const MISTAKE_FIXED = 'MISTAKE_FIXED';
export const MISTAKES_FETCH_START = 'MISTAKES_FETCH_START';
export const MISTAKES_FETCH_SUCCESS = 'MISTAKES_FETCH_SUCCESS';
export const MISTAKES_FETCH_FAILED = 'MISTAKES_FETCH_FAILED';

const fetchingMistakesStart = () => ({
	type: MISTAKES_FETCH_START,
});

const fetchingMistakesSuccess = () => ({
	type: MISTAKES_FETCH_SUCCESS,
});

const fetchingMistakesFailed = () => ({
	type: MISTAKES_FETCH_FAILED,
});

const mistakesAdd = mistake => ({
	type: MISTAKES_ADD,
	mistake,
});

const mistakesFetch = () => ({
	type: MISTAKES_FETCH,
});

const mistakesSet = (mistakes, products, questions, categories) => ({
	type: MISTAKES_SET,
	mistakes,
	products,
	questions,
	categories,
});

const mistakeFixed = mistake => ({
	type: MISTAKE_FIXED,
	mistake,
});

const actions = {
	mistakesAdd,
	mistakesFetch,
	mistakesSet,
	mistakeFixed,
	fetchingMistakesStart,
	fetchingMistakesSuccess,
	fetchingMistakesFailed,
};

export default actions;
