import React from 'react';
import { withRouter } from 'react-router';
import { getQuestions, getMoreInfos } from '../../store/Questions/questions.reducer';
import { connect } from 'react-redux';
import CategoryIcon from '../../components/CategoryIcon';
import Step from '../../components/Step';
import Button from '../../components/Form/Button/Button';
import { getColor } from '../../utils/util';
import { getMistake } from '../../store/Mistakes/mistakes.reducer';
import { Wrapper, InfoContent } from '../../components/MoreInfo/MoreInfoUIComponents.js';
import { useTranslation } from 'react-i18next';

function MoreInfo({
  desktop,
  history,
  moreInfos,
  mistake = { mistakes: [] },
  match: {
    params: { questionId },
  },
}) {
  const currentMistake = (mistake &&
    mistake.mistakes &&
    mistake.mistakes.filter(m => m.question === questionId).reduce((memo, mistake) => mistake, {})) || {
    q: { more_info: '' },
  };

  const { title, text, steps } = moreInfos
    .filter(i => i.url === currentMistake.q.more_info)
    .reduce((memo, info) => info, {});

  const { t } = useTranslation();

  return (
    <>
      <Wrapper desktop={desktop}>
        <article>
          <CategoryIcon category={currentMistake.q.category} width={'55'} height={'55'} />
        </article>
        <InfoContent desktop={desktop}>
          <h1>{title}</h1>
          <h2 dangerouslySetInnerHTML={{ __html: text }} />
          <ul>
            {steps &&
              steps.map((step, key) => (
                <Step desktop={desktop} key={key} title={step.title} description={step.text} id={key + 1} />
              ))}
          </ul>
        </InfoContent>
        {desktop && (
          <footer>
            <Button desktop={desktop} color={getColor(currentMistake.q.category)} onClick={() => history.goBack()}>
              {t('close', 'Close')}
            </Button>
          </footer>
        )}
      </Wrapper>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  questions: getQuestions(state),
  moreInfos: getMoreInfos(state),
  mistake: getMistake(state, ownProps),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MoreInfo)
);
