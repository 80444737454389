import React from 'react';

class Hamburger extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 25.9 23.5"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <g transform="translate(-0.5 -0.5)">
          <path d="M25.4,2.5H1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h23.9c0.6,0,1,0.4,1,1S26,2.5,25.4,2.5z" />
          <path d="M25.4,13.2H1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h23.9c0.6,0,1,0.4,1,1S26,13.2,25.4,13.2z" />
          <path d="M25.4,24H1.5c-0.6,0-1-0.4-1-1s0.4-1,1-1h23.9c0.6,0,1,0.4,1,1S26,24,25.4,24z" />
        </g>
      </svg>
    );
  }
}

Hamburger.defaultProps = {
  width: 25,
  height: 25,
  color: 'black',
};

export default Hamburger;
