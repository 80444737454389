import React from 'react';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getTopics } from '../../store/Learn/learn.reducer';
import Topic from './components/Topic';
import learnPracticeActions from '../../store/Learn/learn.action';

const Wrapper = styled.article`
  padding: ${props => (props.desktop ? '20px 0px' : '15px')};
  background-color: ${props => props.theme.colors.GREY};
  width: 100%;
  overflow: auto;
`;

class Learn extends React.PureComponent {
  componentDidMount() {
    this.props.getLearnPractice();
  }

  render() {
    const { topics, desktop } = this.props;

    return (
      <Wrapper desktop={desktop}>
        {topics.map((topic, key) => (
          <Topic topic={topic} desktop={desktop} key={key} />
        ))}
      </Wrapper>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  topics: getTopics(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLearnPractice: () => learnPracticeActions.getLearnPractice(),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Learn)
);
