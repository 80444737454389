import { css } from 'styled-components';
import theme from '../1-settings/theme';
import Verdana from '../../assets/fonts/verdana/verdana.woff';
import VerdanaB from '../../assets/fonts/verdana/verdanab.woff';
import VerdanaI from '../../assets/fonts/verdana/verdanai.woff';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const base = css`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Verdana', sans-serif;
    background-color: ${theme.colors.GREY};

    h1 {
      font-size: 18px;
    }

    h2 {
      font-size: 16px;
    }

    h3 {
      font-size: 14px;
    }

    h4 {
      font-size: 12px;
      line-height: 24px;
    }
  }
  body,
  html,
  #root {
    height: 100%;
    overflow: auto;
  }

  *:focus,
  *:active {
    outline: none;
  }

  @font-face {
    font-family: Verdana;
    src: url(${Verdana});
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Verdana;
    src: url(${VerdanaI});
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: Verdana;
    src: url(${VerdanaB});
    font-weight: bold;
    font-style: normal;
  }
`;

export default base;
