import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getQuestions, getPreviousQuestion } from '../../store/Questions/questions.reducer';
import { getProduct } from '../../store/Products/products.reducer';
import { withRouter } from 'react-router';
import { YesNoQuestion, MultipleButton, MultipleCheckbox, OpenQuestion } from './components/QuestionTypes';
import HiddenCard from '../../components/HiddenCard';
import Card from '../../components/Card';
import MoreInfoAbout from './components/MoreInfoAbout';
import CategoryIcon from '../../components/CategoryIcon';
import actions from '../../store/Questions/questions.action';
import productActions from '../../store/Products/products.action';
import { bindActionCreators } from 'redux';
import Header from '../../components/Header/Header';
import BackArrowDesktop from '../../components/icons/BackArrowDesktop';
import { getActiveQuestion } from '../../store/Questions/questions.reducer';

const Wrapper = styled.article`
  padding: ${props => (props.desktop ? '20px 0px' : '15px')};
  background-color: ${props => props.theme.colors.GREY};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${props => (props.desktop ? '100%' : 'calc(100% - 60px)')};
`;

const CardContent = styled.article`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding-left: ${props => (props.desktop ? '40px' : '0px')};
`;

class Question extends React.PureComponent {
  componentDidMount() {
    const { product, history } = this.props;
    if (!product.identifier) {
      history.push('/testbuy');
    }
  }

  state = {
    value: '',
  };

  handleOnClick = (nextQuestion, hasMistake) => {
    const { history, questions, product, match, questionDone } = this.props;
    const nextId = nextQuestion
      ? questions.filter(q => q.url === nextQuestion).reduce((memo, q) => q.id, false)
      : 'final';

    questionDone(match.params.id, product.identifier, hasMistake);

    if (nextId && nextId !== 'final') {
      history.push(`/question/${nextId}`);
    } else if (nextId === 'final') {
      history.push('/question-final');
    } else {
      const activeQuestion = getActiveQuestion(questions, product);
      const url = activeQuestion === '/question-intro' ? '/shopping-list' : activeQuestion;
      history.push(url);
    }
  };

  handleOpenQuestion = (nextQuestion, location, question) => {
    const { product, setSelfService } = this.props;
    setSelfService(product, location, question);
    this.handleOnClick(nextQuestion);
  };

  handleCheckboxQuestion = (nextQuestion, options) => {
    const hasMistake = options.filter(o => o.selected && o.make_issue).reduce(() => true, false);
    this.handleOnClick(nextQuestion, hasMistake);
  };

  render() {
    const { match, questions, desktop, history, resetQuestion } = this.props;
    const question = questions.filter(q => q.id === parseInt(match.params.id, 10)).reduce((memo, q) => q, {});

    return (
      <>
        {!desktop && <Header previousUrl={getPreviousQuestion(questions, match.params.id)} />}
        <Wrapper desktop={desktop}>
          <Card desktop={desktop} stretch wBackArrow={desktop}>
            {desktop && <BackArrowDesktop onClick={() => history.goBack()} />}
            <CardContent desktop={desktop}>
              {question.id !== 300 && (
                <Card.Icon>
                  <CategoryIcon
                    category={question.category}
                    width={desktop ? '80' : '55'}
                    height={desktop ? '80' : '55'}
                  />
                </Card.Icon>
              )}
              <Card.Header large={question.type === 'y/n'} id={question.id}>
                {question.title}
              </Card.Header>
              {question.type === 'y/n' && (
                <YesNoQuestion
                  desktop={desktop}
                  category={question.category}
                  question={question}
                  goTo={(nextQuestion, hasMistake) => this.handleOnClick(nextQuestion, hasMistake)}
                />
              )}
              {question.type === 'mpc' && (
                <MultipleButton
                  desktop={desktop}
                  category={question.category}
                  question={question}
                  goTo={(nextQuestion, hasMistake) => this.handleOnClick(nextQuestion, hasMistake)}
                />
              )}
              {question.type === 'checkbox' && (
                <MultipleCheckbox
                  desktop={desktop}
                  category={question.category}
                  question={question}
                  resetQuestion={question => resetQuestion(question)}
                  goTo={(nextQuestion, options) => this.handleCheckboxQuestion(nextQuestion, options)}
                />
              )}
              {question.type === 'open' && (
                <OpenQuestion
                  desktop={desktop}
                  category={question.category}
                  question={question}
                  goTo={(nextQuestion, location) => this.handleOpenQuestion(nextQuestion, location, question)}
                />
              )}
            </CardContent>
          </Card>
          {!desktop && (
            <>
              <HiddenCard />
              <HiddenCard last />
            </>
          )}
          {question.more_info && (
            <MoreInfoAbout
              desktop={desktop}
              category={question.category}
              goTo={() => this.props.history.push(question.id + '/more-info')}
            />
          )}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  questions: getQuestions(state),
  product: getProduct(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetQuestion: question => actions.resetMPCQuestion(question),
      questionDone: (questionId, productId, hasMistake) => actions.questionDone(questionId, productId, hasMistake),
      setSelfService: (product, location, question) => productActions.setSelfService(product, location, question),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Question)
);
