// @flow
import { call, takeLatest, select, put } from 'redux-saga/effects';
import { MISTAKES_FETCH, MISTAKE_FIXED } from './mistakes.action';
import type { Saga } from 'redux-saga';
import { getMistakes } from '../../utils/api';
import { appGetStore, appGetCountry } from '../App/app.reducer';
import { getProducts } from '../Products/products.reducer';
import actions from './mistakes.action';
import eventActions from '../Events/events.action';
import { getQuestions, getCategories } from '../Questions/questions.reducer';

function* mistakesFetch(): Saga<void> {
	try {
		const { identifier } = yield select(appGetStore);
		const { code } = yield select(appGetCountry);
		const products = yield select(getProducts);
		const questions = yield select(getQuestions);
		const categories = yield select(getCategories);
		const mistakes = yield call(getMistakes, code, identifier);
		yield put(actions.setMistakes(mistakes, products, questions, categories));
	} catch (e) {
		console.error(e);
	}
}

function* mistakeSolved({ mistake }): Saga<void> {
	try {
		const { identifier } = yield select(appGetStore);
		const country = yield select(appGetCountry);
		const data = {
			country: country.code,
			store: identifier,
			product_identifier: mistake.identifier,
			location: mistake.location,
			question: mistake.question,
			area: mistake.area,
			language: country.languages[0].code,
		};
		yield put(eventActions.eventsFire({ event: 'mistake_fixed', data }));
	} catch (e) {
		console.error(e);
	}
}

export function* fetchMistakesListener(): Saga<void> {
	yield takeLatest(MISTAKES_FETCH, mistakesFetch);
}

export function* mistakeSolvedListener(): Saga<void> {
	yield takeLatest(MISTAKE_FIXED, mistakeSolved);
}

export default [fetchMistakesListener, mistakeSolvedListener];
