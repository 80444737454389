import React from 'react';
import styled, { css } from 'styled-components';

const StyledSelectList = styled.ul`
	overflow-x: hidden;
	height: ${props => (props.desktop ? 'calc(100% - 75px)' : '100%')};
	${props =>
		props.desktop &&
		css`
			flex-direction: column;
			display: flex;
			flex-wrap: wrap;
		`};
`;

export const SelectList = React.forwardRef((props, ref) => {
	return <StyledSelectList ref={ref} className="SelectList" {...props} />;
});

export const SelectListItem = styled.li`
	background-color: ${props => props.theme.colors.LIGHT_GREY};
	font-size: ${props => (props.desktop ? '16px' : '18px')};
	padding: ${props => (props.desktop ? '20px' : '30px')};
	list-style: none;
	height: ${props => (props.store ? '57px' : '75px')};
	&:active {
		font-weight: bold;
		color: ${props => props.theme.colors.BLUE};
		border-left: ${props => `5px solid ${props.theme.colors.BLUE}`};
		span {
			margin-left: -5px;
		}
	}

	${props =>
		props.desktop &&
		css`
			width: calc(100% / 3);
			&:hover {
				font-weight: bold;
				color: ${props => props.theme.colors.BLUE};
				border-left: ${props => `5px solid ${props.theme.colors.BLUE}`};
				span {
					margin-left: -5px;
				}
			}
		`}
`;
