import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/App/app.action';
import products from '../../store/Products/products.action';
import review from '../../store/Review/review.action';
import styled from 'styled-components';
import { PageWrapper } from '../../components/PageWrapper';
import { SelectList, SelectListItem } from '../../components/Form/SelectList';
import BackArrowDesktop from '../../components/icons/BackArrowDesktop';
import ArrowRightDesktop from '../../components/icons/ArrowRightDesktop';
import { Trans } from 'react-i18next';

const Wrapper = styled(PageWrapper)`
	padding: ${props => (props.desktop ? '20px 50px' : '0px')};
	margin: auto;
	height: ${props => (props.desktop ? 'calc(100vh - 170px)' : 'calc(100% - 60px)')};
	width: 100%;
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
	position: relative;

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		h1 {
			font-size: 24px;
			font-weight: bold;
		}

		div {
			width: 40px;
		}
	}
`;

const ButtonContainer = styled.section`
	position: absolute;
	top: 50%;
	transform: ${props => (props.left ? 'rotate(180deg)' : 'none')};
	right: ${props => (props.left ? 'auto' : '20px')};
	left: ${props => (props.left ? '20px' : 'auto')};
	opacity: ${props => (props.disabled ? '0.2' : '1')};
`;

class Intro extends React.PureComponent {
	constructor(props) {
		super(props);

		this.scroller = React.createRef();
	}

	state = {
		activeLeft: false,
		lastClickedLeft: true,
		activeRight: true,
	};

	selectStore = store => {
		const {
			history,
			setStore,
			temporaryCountry,
			setCountry,
			resetTemporaryCountry,
			resetShoppingList,
			getAreas,
			prefetchData,
			getWeeklyStats,
			resetReviewShoppingList,
		} = this.props;
		if (temporaryCountry.name) {
			setCountry(temporaryCountry);
			resetTemporaryCountry();
			resetShoppingList();
			resetReviewShoppingList();
		}
		setStore(store);
		getAreas();
		prefetchData();
		getWeeklyStats();
		history.push('/intro');
	};

	onClickRight = () => {
		this.scroller.current.scrollLeft += 800;
		if (this.scroller.current.scrollLeft % 8 !== 0 && this.state.lastClickedLeft) {
			this.setState({ activeLeft: true, lastClickedLeft: false, activeRight: false });
		} else {
			this.setState({ activeRight: true, activeLeft: true });
		}
	};

	onClickLeft = () => {
		this.scroller.current.scrollLeft -= 800;
		if (this.scroller.current.scrollLeft === 0) {
			this.setState({ activeLeft: false, lastClickedLeft: true, activeRight: true });
		} else {
			this.setState({ activeRight: true, lastClickedLeft: true });
		}
	};

	render() {
		const { country, history, desktop, temporaryCountry } = this.props;
		let stores;
		if (temporaryCountry.name) {
			stores = temporaryCountry.languages
				.filter(l => l.code === country.code)
				.reduce((memo, l) => l.stores, temporaryCountry.languages[0].stores);
		} else {
			stores = country.languages
				.filter(l => l.code === country.code)
				.reduce((memo, l) => l.stores, country.languages[0].stores);
		}
		return (
			<Wrapper desktop={desktop}>
				{desktop && (
					<header>
						<BackArrowDesktop width={'30'} height={'30'} onClick={() => history.goBack()} />
						<h1>
							<Trans i18nKey="selectStore.selector">Choose store</Trans>
						</h1>
						<div />
					</header>
				)}
				{desktop && (
					<ButtonContainer disabled={!this.state.activeLeft} onClick={this.onClickLeft.bind(this)} left>
						<ArrowRightDesktop width={'22'} height={'22'} />
					</ButtonContainer>
				)}
				<SelectList ref={this.scroller} desktop={desktop}>
					{stores &&
						stores.map((s, key) => (
							<SelectListItem store desktop={desktop} key={key} onClick={() => this.selectStore(s)}>
								<span>{s.name}</span>
							</SelectListItem>
						))}
				</SelectList>
				{desktop && (
					<ButtonContainer disabled={!this.state.activeRight} onClick={this.onClickRight.bind(this)}>
						<ArrowRightDesktop width={'22'} height={'22'} />
					</ButtonContainer>
				)}
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	country: state.app && state.app.country,
	temporaryCountry: state.app && state.app.temporaryCountry,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setStore: store => actions.appSetStore(store),
			setCountry: country => actions.appSetCountry(country),
			prefetchData: () => actions.appGetPrefetchData(),
			getAreas: () => actions.appGetAreas(),
			resetTemporaryCountry: () => actions.appResetTemporaryCountry(),
			resetShoppingList: () => products.resetShoppingList(),
			resetReviewShoppingList: () => review.resetShoppingList(),
			getWeeklyStats: () => actions.appGetWeeklyStats(),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro));
