import styled from 'styled-components';

export const Wrapper = styled.article`
  padding: 20px 15px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${props => props.theme.colors.GREY};
  min-height: calc(100% - 60px);

  article {
    padding: 45px;
    display: flex;
    justify-content: center;
    background-color: ${props => (props.desktop ? 'white' : props.theme.colors.GREY)};
    box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
    margin-bottom: ${props => (props.desktop ? '40px' : '0px')};
    display: flex;
    flex: 1 0 auto;
  }

  h1 {
    font-weight: bold;
    line-height: 26px;
    margin: 5px 0px;
  }

  h2 {
    line-height: 28px;
    margin: 10px 0px;
  }

  ul {
    li:last-of-type {
      border-bottom: none;
    }
  }

  footer {
    margin-top: 20px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
`;

export const InfoContent = styled.section`
  margin: ${props => (props.desktop ? '20px 50px' : '0px')};
  overflow-y: ${props => (props.desktop ? 'auto' : 'hidden')};
`;
