export const PRODUCTS_GET_SHOPPING_LIST = 'PRODUCTS_GET_SHOPPING_LIST';
export const PRODUCTS_SET_SHOPPING_LIST = 'PRODUCTS_SET_SHOPPING_LIST';
export const PRODUCTS_SET_SIZE = 'PRODUCTS_SET_SIZE';
export const PRODUCTS_MARK_AVAILABLE = 'PRODUCTS_MARK_AVAILABLE';
export const PRODUCTS_SELECT_PRODUCT = 'PRODUCTS_SELECT_PRODUCT';
export const PRODUCTS_TOGGLE_SHOW_DIRECTIONS = 'PRODUCTS_TOGGLE_SHOW_DIRECTIONS';
export const PRODUCTS_MARK_PRODUCT_DONE = 'PRODUCTS_MARK_PRODUCT_DONE';
export const PRODUCTS_SET_SELF_SERVICE = 'PRODUCTS_SET_SELF_SERVICE';
export const PRODUCTS_SET_MISTAKE_PHOTO = 'PRODUCTS_SET_MISTAKE_PHOTO';
export const PRODUCTS_SAVE_COMMENT = 'PRODUCTS_SAVE_COMMENT';
export const PRODUCTS_RESET_SHOPPING_LIST = 'PRODUCTS_RESET_SHOPPING_LIST';
export const PRODUCTS_MARK_SUMMARY_REACHED = 'PRODUCTS_MARK_SUMMARY_REACHED';
export const PRODUCTS_SET_SHOPPING_LIST_FAILED = 'PRODUCTS_SET_SHOPPING_LIST_FAILED';
export const PRODUCTS_GET_SHOPPING_LIST_STARTED = 'PRODUCTS_GET_SHOPPING_LIST_STARTED';
export const PRODUCTS_GET_SHOPPING_LIST_FAILED = 'PRODUCTS_GET_SHOPPING_LIST_FAILED';
export const PRODUCTS_SET_SHOPPING_LIST_FROM_REVIEW = 'PRODUCTS_SET_SHOPPING_LIST_FROM_REVIEW';
export const PRODUCTS_SET_CHECK_AVAILABILITY_DONE = 'PRODUCTS_SET_CHECK_AVAILABILITY_DONE';
export const PRODUCTS_SET_REVIEW_ID = 'PRODUCTS_SET_REVIEW_ID';
export const PRODUCTS_REPLACE_PRODUCT = 'PRODUCTS_REPLACE_PRODUCT';
export const PRODUCTS_REPLACE_PRODUCT_STARTED = 'PRODUCTS_REPLACE_PRODUCT_STARTED';
export const PRODUCTS_REPLACE_PRODUCT_FAILED = 'PRODUCTS_REPLACE_PRODUCT_FAILED';

const actions = {
	getShoppingList() {
		return {
			type: PRODUCTS_GET_SHOPPING_LIST,
		};
	},
	getShoppingListStarted() {
		return {
			type: PRODUCTS_GET_SHOPPING_LIST_STARTED,
		};
	},
	getShoppingListFailed() {
		return {
			type: PRODUCTS_GET_SHOPPING_LIST_FAILED,
		};
	},
	settShoppingList(products) {
		return {
			type: PRODUCTS_SET_SHOPPING_LIST,
			products,
		};
	},
	settShoppingListFailed() {
		return {
			type: PRODUCTS_SET_SHOPPING_LIST_FAILED,
		};
	},
	setProductsSize(size) {
		return {
			type: PRODUCTS_SET_SIZE,
			size,
		};
	},
	markProduct(identifier, isAvailable) {
		return {
			type: PRODUCTS_MARK_AVAILABLE,
			identifier,
			isAvailable,
		};
	},
	selectProduct(product) {
		return {
			type: PRODUCTS_SELECT_PRODUCT,
			product,
		};
	},
	toggleShowDirections(showDirections) {
		return {
			type: PRODUCTS_TOGGLE_SHOW_DIRECTIONS,
			showDirections,
		};
	},
	markProductDone(product) {
		return {
			type: PRODUCTS_MARK_PRODUCT_DONE,
			product,
		};
	},
	markSummaryReached(product) {
		return {
			type: PRODUCTS_MARK_SUMMARY_REACHED,
			product,
		};
	},
	setSelfService(product, location, question) {
		return {
			type: PRODUCTS_SET_SELF_SERVICE,
			product,
			location,
			question,
		};
	},
	setMistakePhoto(photo, productId, categoryType) {
		return {
			type: PRODUCTS_SET_MISTAKE_PHOTO,
			photo,
			productId,
			categoryType,
		};
	},
	saveComment(comment, productId, categoryType, question) {
		return {
			type: PRODUCTS_SAVE_COMMENT,
			comment,
			productId,
			categoryType,
			question,
		};
	},
	resetShoppingList() {
		return {
			type: PRODUCTS_RESET_SHOPPING_LIST,
		};
	},
	setShoppingListFromReview(products) {
		return {
			type: PRODUCTS_SET_SHOPPING_LIST_FROM_REVIEW,
			products,
		};
	},
	setCheckAvailabilityDone() {
		return {
			type: PRODUCTS_SET_CHECK_AVAILABILITY_DONE,
		};
	},
	setReviewId(reviewId) {
		return {
			type: PRODUCTS_SET_REVIEW_ID,
			reviewId,
		};
	},
	replaceProduct(products) {
		return {
			type: PRODUCTS_REPLACE_PRODUCT,
			products,
		};
	},
	replaceProductStarted(product) {
		return {
			type: PRODUCTS_REPLACE_PRODUCT_STARTED,
			product,
		};
	},
	replaceProductFailed() {
		return {
			type: PRODUCTS_REPLACE_PRODUCT_FAILED,
		};
	},
};

export default actions;
