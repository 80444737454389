import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { getTopics, getPracticeResults } from '../../store/Learn/learn.reducer';
import Button from '../../components/Form/Button/Button';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  height: ${props => (props.desktop ? 'calc(100vh - 200px)' : 'calc(100vh - 60px)')};
  width: 100%;
  background-color: white;
  margin: ${props => (props.desktop ? '15px 0px' : '0px')};
  box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};

  footer {
    display: flex;
    align-self: flex-end;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    button {
      flex: 1;
      margin-bottom: 10px;
      &:last-of-type {
        color: ${props => props.theme.colors.BLUE};
        border: 2px solid ${props => props.theme.colors.BLUE};
      }
    }
  }
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-bottom: 40px;
    }
  }

  p {
    font-size: 22px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 60px;
    font-weight: bold;
  }
`;

function PracticeSummary({
  match: {
    params: { id },
  },
  practiceResults: { result, resultsTotal },
  history,
  desktop,
}) {
  const { t } = useTranslation();
  return (
    <Wrapper desktop={desktop}>
      <Results>
        <div>
          <p>{t('score', { defaultValue: 'You scored' })}</p>
          <h2>{result}</h2>
        </div>
        <div>
          <p>{t('outof', { defaultValue: 'out of' })}</p>
          <h2>{resultsTotal}</h2>
        </div>
      </Results>
      <footer>
        <Button onClick={() => history.push(`/practice/${id}`)}>{t('retry', { defaultValue: 'Try again' })}</Button>
        <Button color={'white'} onClick={() => history.push(`/learn`)}>
          {t('return_learn_practice', { defaultValue: 'Return to Learn and practise' })}
        </Button>
      </footer>
    </Wrapper>
  );
}

const mapStateToProps = state => ({
  topics: getTopics(state),
  practiceResults: getPracticeResults(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PracticeSummary));
