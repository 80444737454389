import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './App/app.reducer';
import cmblocks from './CmBlocks/cmblocks.reducer';
import products from './Products/products.reducer';
import learn from './Learn/learn.reducer';
import questions from './Questions/questions.reducer';
import mistakes from './Mistakes/mistakes.reducer';
import camera from './Camera/camera.reducer';
import events from './Events/events.reducer';
import faqs from './FAQ/faq.reducer';
import review from './Review/review.reducer';

const rootReducer = history =>
	combineReducers({
		app,
		cmblocks,
		products,
		learn,
		questions,
		mistakes,
		camera,
		events,
		faqs,
		review,
		router: connectRouter(history),
	});

export default rootReducer;
