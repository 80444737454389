import React from 'react';

class CheckMarkCompletedState extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 14.2 10.523"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <g transform="translate(-324 -703)">
          <path
            d="M13.82.38a1.226,1.226,0,0,0-1.775,0L4.818,7.48,2.155,4.818a1.226,1.226,0,0,0-1.775,0,1.226,1.226,0,0,0,0,1.775l3.55,3.55h0a1.37,1.37,0,0,0,.887.38,1.37,1.37,0,0,0,.887-.38L13.82,2.029A1.081,1.081,0,0,0,13.82.38Z"
            transform="translate(324 703)"
          />
        </g>
      </svg>
    );
  }
}

CheckMarkCompletedState.defaultProps = {
  width: 18,
  height: 18,
  color: 'black',
};

export default CheckMarkCompletedState;
