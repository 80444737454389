import React from "react";
import styled from 'styled-components';
import BackArrow from '../../components/icons/BackArrow';
import { withRouter } from 'react-router';
import {getTopics} from "../../store/Learn/learn.reducer";
import {connect} from "react-redux";

const Wrapper = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    align-items: center;

    header {
      position: absolute;
      top: 15px;
      left: 15px;
    }
`;

const Image = styled.article`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${props => `url(${props.image})`};
  margin: auto;
  padding-bottom: 56.25%;
`;

class Video extends React.PureComponent {
  render() {
    const {topics, match, history} = this.props;
    const questions = topics
      .filter(q => q.id === parseInt(match.params.id, 10))
      .map(t => {
        return [
          t.questions.filter(q => q.uuid === match.params.question).reduce((memo, q) => q, {})
        ]
      });
    const question = questions[0][0];

    return (
        <Wrapper>
          <header onClick={() => history.goBack()}>
            <BackArrow color={'white'}/>
          </header>
          <Image image={question.image}/>
        </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  topics: getTopics(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Video)
);