import React from 'react';

class CheeringPuppet extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 133.5 212.3"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          fill="#1D1E1C"
          d="M65,77.8c-4,0-7.9-1.1-11.4-3.1c-5.1-3-8.8-7.9-10.3-13.7S42.7,49.1,45.8,44s7.9-8.8,13.7-10.3
	   c11.9-3,24.1,4.2,27.2,16.1l0,0c3.1,11.9-4.2,24.2-16.1,27.2C68.7,77.5,66.8,77.8,65,77.8z M64.9,35.1c-1.6,0-3.3,0.2-5,0.6
	   c-5.3,1.3-9.7,4.7-12.5,9.4c-2.8,4.7-3.6,10.2-2.2,15.4s4.7,9.7,9.4,12.5c4.7,2.8,10.2,3.6,15.4,2.2c10.9-2.8,17.5-13.9,14.7-24.8
	   C82.3,41.2,74,35.1,64.9,35.1z"
        />
        <path
          fill="#1D1E1C"
          d="M65.7,71.5c-6,0-11.5-4-13.1-10.1c-0.1-0.3,0-0.5,0.1-0.8c0.1-0.2,0.4-0.4,0.6-0.5L77.6,54
	   c0.3-0.1,0.5,0,0.8,0.1c0.2,0.1,0.4,0.4,0.5,0.6c0.9,3.5,0.3,7.1-1.5,10.2s-4.8,5.3-8.3,6.1C67.9,71.4,66.8,71.5,65.7,71.5z
		M54.9,61.9c2,5.5,7.9,8.7,13.6,7.3c3-0.7,5.5-2.6,7.1-5.2c1.4-2.3,1.9-5,1.5-7.7L54.9,61.9z"
        />
        <path
          fill="#1D1E1C"
          d="M46.5,212.3c-0.1,0-0.3,0-0.4-0.1L29,203.9c-0.2-0.1-0.4-0.3-0.5-0.6s-0.1-0.5,0-0.8L62.3,133l-3.6-32.9
	   c-24-1-45.8-13.7-58.5-34C0,65.9,0,65.6,0,65.4c0.1-0.3,0.2-0.5,0.4-0.6l12.7-7.9c0.5-0.3,1.1-0.1,1.4,0.3
	   c10.3,16.4,28.8,26.4,48.1,26.1l9.8-1.1c0.2,0,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0c20.5-4.4,37-20.3,42-40.7
	   c0.5-2,0.9-4.1,1.2-6.2c0.1-0.5,0.6-0.9,1.1-0.9l14.9,2c0.5,0.1,0.9,0.6,0.9,1.1c-0.3,2.7-0.8,5.5-1.5,8.1
	   c-5.9,23.9-24.1,43.4-47.5,51l3.6,33.2l45.1,65.8c0.2,0.2,0.2,0.5,0.2,0.8c0,0.3-0.2,0.5-0.4,0.6l-15.7,10.7
	   c-0.5,0.3-1.1,0.2-1.4-0.3l-38.8-56.7l-29.5,61c-0.1,0.2-0.3,0.4-0.6,0.5C46.7,212.3,46.6,212.3,46.5,212.3z M30.7,202.5L46,210
	   l29.9-61.6c0.2-0.3,0.5-0.5,0.8-0.6c0.3,0,0.7,0.1,0.9,0.4l39.2,57.3l14-9.6l-44.6-65.2c-0.1-0.1-0.2-0.3-0.2-0.5L82.4,96
	   c-0.1-0.5,0.2-0.9,0.7-1.1c23.1-7.2,41.1-26.4,46.9-49.9c0.6-2.3,1.1-4.6,1.4-6.9l-12.9-1.7c-0.3,1.8-0.6,3.6-1.1,5.4
	   C112.1,62.9,95.1,79.4,73.9,84c-0.2,0.1-0.4,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0l-10,1.1
	   c-19.9,0.3-38.6-9.7-49.4-26.2l-11,6.8C15,85.5,36.3,97.5,59.6,98.2c0.5,0,0.9,0.4,1,0.9l3.7,34c0,0.2,0,0.4-0.1,0.5L30.7,202.5z"
        />
        <g>
          <path fill="none" d="M105.3,55.4" />
        </g>
        <g>
          <path
            fill="#62BB46"
            d="M25.9,44.8l-14.5-3.7c0.1-0.4,0.2-0.7,0.3-1.1l14.5,3.9L25.9,44.8z"
          />
          <path
            fill="#62BB46"
            d="M30.1,34.4l-13-7.6c0.4-0.7,0.8-1.4,1.2-2L31,33C30.7,33.5,30.4,34,30.1,34.4z M37.7,25.1
		   L27.6,14c0.6-0.5,1.2-1,1.8-1.6L39,24C38.5,24.3,38.1,24.7,37.7,25.1z M84.1,20.1c-0.5-0.3-1-0.6-1.5-0.8L89.5,6
		   c0.7,0.4,1.4,0.7,2.1,1.1L84.1,20.1z M47.7,18.5L41.4,4.9c0.7-0.3,1.4-0.6,2.2-1l5.7,13.9C48.7,18,48.2,18.2,47.7,18.5z M72.9,15.7
		   c-0.6-0.1-1.1-0.2-1.7-0.3l2.6-14.8c0.8,0.1,1.5,0.3,2.3,0.5L72.9,15.7z M59.2,15.1L57.3,0.3L59.7,0L61,14.9
		   C60.4,15,59.8,15.1,59.2,15.1z"
          />
          <path
            fill="#62BB46"
            d="M92.9,27l-0.6-0.6l10.6-10.6l0.8,0.8L92.9,27z"
          />
        </g>
        <g>
          <path fill="#1D1E1C" d="M65.3,95.4" />
        </g>
      </svg>
    );
  }
}

CheeringPuppet.defaultProps = {
  width: 200,
  height: 200,
  color: '#a7a7a7',
};

export default CheeringPuppet;
