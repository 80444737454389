import React from 'react';

class ArrowRightDesktop extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 14.6 26.4"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M14.2 12.1L2.6.4C2-.1 1-.1.4.4-.1 1-.1 2 .4 2.6L11 13.2.4 23.8c-.6.6-.6 1.5 0 2.1.3.3.7.4 1.1.4s.8-.1 1.1-.4l11.6-11.6c.3-.3.4-.7.4-1.1 0-.4-.1-.8-.4-1.1z"/>
      </svg>
    )
  };
}

ArrowRightDesktop.defaultProps = {
  width: 12,
  height: 12,
  color: '#000',
};

export default ArrowRightDesktop;
