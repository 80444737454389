import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getColor } from '../../utils/util';
import MoreInfoAbout from '../TestBuy/components/MoreInfoAbout';
import BackArrowDesktop from '../../components/icons/BackArrowDesktop';
import CheckMarkCompletedState from '../../components/icons/CheckMarkCompletedState';
import { getMistake } from '../../store/Mistakes/mistakes.reducer';
import actions from '../../store/Mistakes/mistakes.action';
import { Trans } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  padding: ${props => (props.desktop ? '20px 0px' : '15px')};
  flex-direction: column;
  background-color: #ededee;
  flex: 1;
  height: auto;
  overflow: auto;

  footer {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: ${props => (props.desktop ? '10px 0px' : '0px')};
  }
`;

const IconContainer = styled.section`
  padding: 30px;
  margin: auto;
`;

const Product = styled.section`
  box-shadow: ${props =>
    props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)'};
  background-color: white;
  padding: ${props => (props.desktop ? '30px 70px 25px 30px' : '10px 15px 50px 15px')};

  section {
    padding: ${props => (props.desktop ? '15px 0px' : '10px 0px')};
  }

  article {
    padding-left: ${props => (props.desktop ? '40px' : '0px')};
  }

  .productInfo {
    display: flex;
    justify-content: space-between;

    header {
      display: flex;
      flex-direction: column;
      font-weight: normal;

      h1 {
        margin: 0;
        font-size: ${props => (props.desktop ? '32px' : '20px')};
        line-height: 24px;
        font-weight: bold;
      }
    }
  }
`;

const MistakeDescription = styled.section`
  line-height: ${props => (props.desktop ? '30px' : '21px')};

  h3 {
    font-size: ${props => (props.desktop ? '18px' : '14px')};
  }

  h3:first-of-type {
    font-weight: bold;
  }
`;

class MistakeDetail extends React.PureComponent {
  markSolved = currentMistake => {
    const { mistakeFixed, history } = this.props;
    mistakeFixed(currentMistake);
    history.push('/store-stream');
  };

  render() {
    const {
      mistake,
      desktop,
      history,
      match: {
        params: { questionId, id },
      },
    } = this.props;

    const currentMistake = mistake.mistakes
      .filter(m => m.question === questionId)
      .reduce((memo, mistake) => mistake, {});

    return (
      <Wrapper desktop={desktop} className="MistakeDetail">
        <Product desktop={desktop}>
          {desktop && <BackArrowDesktop className="BackArrowDesktop" onClick={() => history.goBack()} />}
          <article>
            <section className="productInfo">
              <header>
                <h1>{mistake.product.name}</h1>
              </header>
            </section>
            <section>
              {currentMistake.image && currentMistake.image.includes('.png') && (
                <img src={currentMistake.image} alt={''} style={{ width: '100%', maxHeight: '300px' }} />
              )}
            </section>
            <MistakeDescription desktop={desktop}>
              <h3>
                <Trans i18nKey="comment">Comment</Trans>:
              </h3>
              <h3>{currentMistake.comment}</h3>
            </MistakeDescription>
          </article>
        </Product>
        {currentMistake.fixed && (
          <IconContainer>
            <CheckMarkCompletedState width={'30'} height={'30'} color={getColor(mistake.type)} />
          </IconContainer>
        )}
        {!currentMistake.fixed && (
          <footer>
            <MoreInfoAbout
              goTo={() => this.props.history.push(`/store-stream/more-info/${id}/${questionId}`)}
              category={currentMistake.q.category || currentMistake.question}
            />
            <Button
              style={{ marginTop: '15px' }}
              color={getColor(currentMistake.q.category || currentMistake.question)}
              onClick={() =>
                this.markSolved({
                  identifier: mistake.product.identifier,
                  question: currentMistake.question,
                  area: mistake.area,
                  location: currentMistake.location,
                })
              }
            >
              <Trans i18nKey="markSolved">Mark as solved</Trans>
            </Button>
          </footer>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  mistake: getMistake(state, ownProps),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      mistakeFixed: mistake => actions.mistakeFixed(mistake),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MistakeDetail)
);
