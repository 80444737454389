import React from 'react';

class TestbuyConditionYellow extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 55.6 54.377"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M55.6,12.3a.367.367,0,0,0-.1-.3h0a.215.215,0,0,0-.2-.2l-.1-.1c-.1-.1-.2-.1-.3-.2L36.7,2.9a1.388,1.388,0,0,0-.9,0,.752.752,0,0,0-.5.7L34.1,9.5,26.7,7a.967.967,0,0,0-1.2.5l-2.8,5.6a.962.962,0,1,0,1.7.9l2.4-4.9,7.6,2.5a.755.755,0,0,0,.8-.1,1.275,1.275,0,0,0,.5-.7L37,5.3l15.1,7L27.8,22.5,3.6,12.3,28.2,1.9A.992.992,0,0,0,28.7.6.992.992,0,0,0,27.4.1L.6,11.4c-.1,0-.2.1-.3.2l-.1.1-.2.2V12H0s0,.2,0,.3H0V41.9a.945.945,0,0,0,1,1,.945.945,0,0,0,1-1V13.8L26.8,24.2V51.7l-16.7-7L7.9,38.4l8.9-2.5a1.208,1.208,0,0,0,.7-.7,1.015,1.015,0,0,0-.3-1l-3.4-3.4,4.7-3.5a1,1,0,0,0-1.2-1.6l-4.8,3.6L8.9,24.5a1.075,1.075,0,0,0-1.4-.2,1.075,1.075,0,0,0-.2,1.4l4.2,5.6h.1v.1l3,3L6.4,36.8a1.024,1.024,0,0,0-.6.5,1.949,1.949,0,0,0-.1.8l2.7,7.8a.9.9,0,0,0,.6.6l18.5,7.8h0a.85.85,0,0,0,.8,0h0L55,42.8a.961.961,0,0,0,.6-.9V12.3ZM28.8,51.7V24.2L53.6,13.8V41.3Z"
          transform="translate(0 -0.023)"
        />
      </svg>
    );
  }
}

TestbuyConditionYellow.defaultProps = {
  width: 50,
  height: 50,
  color: '#ffc512',
};

export default TestbuyConditionYellow;
