import React from 'react';
import styled from 'styled-components';
import { ShimmerAnimation } from '../../../styles/6-objects/objects';
import CheckIcon from '../../../components/icons/CheckIcon';
import MinusIcon from '../../../components/icons/Minus';
import theme from '../../../styles/1-settings/theme';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.article`
	padding: ${props => (props.desktop ? '30px 70px' : '20px 30px')};
	height: ${props => (props.desktop ? '105px' : '100px')};
	border-bottom: 1px solid #dedede;
	display: flex;
	align-items: center;

	${props =>
		props.allAreas &&
		css`
			height: ${props => (props.desktop ? '145px' : '130px')};
		`};

	svg {
		margin-left: auto;
		flex: 1 0 auto;
		max-width: 24px;
	}

	button {
		margin-left: auto;
		flex: 1 0 auto;
		background: transparent;
		border: none;
		box-shadow: none;
		max-width: 20px;
		padding: 0;

		svg {
			max-width: 20px;
		}
	}

	header {
		font-size: 18px;
		font-weight: bold;
		line-height: 22px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	p {
		font-size: 16px;
		line-height: 24px;
	}

	.price {
		font-weight: bold;
	}

	section {
		max-width: calc(100% - 120px);
	}
`;

const Header = styled(ShimmerAnimation)`
	width: 200px;
	height: 20px;
`;

const Number = styled(ShimmerAnimation)`
	width: 100px;
	height: 14px;
	margin-top: 5px;
`;

const Image = styled(ShimmerAnimation)`
	width: 75px;
	height: 75px;
	margin-right: 20px;
	margin-left: 20px;
`;

export const ProductImage = styled.div`
	background-image: ${props => `url(${props.image})`};
	width: 75px;
	height: 75px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-right: 20px;
	min-width: 75px;
`;

const LocationTag = styled.article`
	background-color: ${props => props.theme.colors.GREY};
	margin-top: 10px;
	padding: 0px 10px;
	width: fit-content;
	line-height: 32px;
	max-width: 100%;

	h3 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

export const ProductLoading = () => (
	<Wrapper>
		<Image />
		<div>
			<Header />
			<Number />
		</div>
	</Wrapper>
);

export const Product = ({
	name,
	number,
	goTo,
	desktop,
	isDone,
	image,
	price,
	allAreas,
	area,
	removeFromList,
	canRemove,
}) => {
	const { t } = useTranslation();
	return (
		<Wrapper desktop={desktop} onClick={goTo} allAreas={allAreas}>
			<ProductImage image={image} />
			<section>
				<header>{name}</header>
				<p>{number}</p>
				<p className="price">{price}</p>
				{allAreas && (
					<LocationTag desktop={desktop}>
						<h3>
							{t('area', { defaultValue: 'Area' })}: {area}
						</h3>
					</LocationTag>
				)}
			</section>
			{isDone ? (
				<CheckIcon thin={true} color={theme.colors.BLACK} />
			) : (
				canRemove && (
					<button onClick={removeFromList}>
						<MinusIcon color={theme.colors.BLACK} width={20} height={20} />
					</button>
				)
			)}
		</Wrapper>
	);
};

export default Product;
