import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import Card from '../../components/Card';
import HiddenCard from '../../components/HiddenCard';
import ShoppingBasket from '../../components/icons/ShoppingBasket';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/Products/products.action';
import eventsAction from '../../store/Events/events.action';
import { appGetCountry, appGetStore } from '../../store/App/app.reducer';
import { Trans } from 'react-i18next';

const Wrapper = styled.article`
	padding: ${props => (props.desktop ? '20px 0px' : '15px')};
	display: flex;
	flex-direction: column;
	height: ${props => (props.desktop ? '100%' : 'calc(100% - 60px)')};
	background-color: ${props => props.theme.colors.GREY};

	.Card {
		padding: 110px 70px 150px;
		.CardIcon {
			padding: 40px;
		}
		h1 {
			font-weight: bold;
			font-size: ${props => (props.desktop ? '28px' : '24px')};
			text-align: center;
			line-height: 38px;
		}
	}
`;

class ChooseTestbuys extends React.PureComponent {
	state = {
		showDirections: false,
		testbuys: 0,
	};

	toggleDirections = testbuys => {
		this.setState({ showDirections: true, testbuys });
	};

	goToShoppingList = size => {
		const {
			history,
			getShoppingList,
			setProductsSize,
			settShoppingList,
			fireEvent,
			country,
			store,
			eventsResetApp,
		} = this.props;

		settShoppingList([]);
		setProductsSize(size);
		getShoppingList();
		eventsResetApp();
		fireEvent(
			{
				country: country.code,
				language: country.languages[0].code,
				store: store.identifier,
			},
			'testbuy_started'
		);
		history.push('/shopping-list');
	};

	render() {
		const { desktop } = this.props;
		return (
			<Wrapper desktop={desktop}>
				<Card className={desktop ? 'Card' : ''} stretch>
					<Card.Icon className="CardIcon">
						<ShoppingBasket width={desktop ? '80' : '60'} height={'60'} />
					</Card.Icon>
					<Card.Header>
						<h1>
							<Trans i18nKey="chooseTestBuys.header">How many testbuys would you like to do?</Trans>
						</h1>
					</Card.Header>
					<Card.Button>
						<Button desktop={desktop} style={{ marginBottom: '20px' }} onClick={() => this.goToShoppingList(5)}>
							5
						</Button>
						<Button desktop={desktop} onClick={() => this.goToShoppingList(10)}>
							10
						</Button>
					</Card.Button>
				</Card>
				{!desktop && (
					<>
						<HiddenCard />
						<HiddenCard last stretch />
					</>
				)}
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	country: appGetCountry(state),
	store: appGetStore(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setProductsSize: size => actions.setProductsSize(size),
			getShoppingList: () => actions.getShoppingList(),
			settShoppingList: products => actions.settShoppingList(products),
			fireEvent: (data, event) => eventsAction.eventsFire({ data, event }),
			eventsResetApp: () => eventsAction.eventsResetApp(),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseTestbuys));
