import React from 'react';

class BackArrowDesktop extends React.Component {
  render() {
    return (
      <svg
        className={this.props.className}
        viewBox="0 0 43 35"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        onClick={this.props.onClick}
        style={this.props.style}
      >
        <path
          d="M41.5 16H5.1L18.5 2.6c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0L.5 16.5c-.6.6-.6 1.5 0 2.1l15.9 16c.3.3.7.4 1.1.4.4 0 .8-.1 1.1-.4.6-.6.6-1.5 0-2.1L5.1 19h36.4c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5z"
          fill="#282828"
        />
      </svg>
    );
  }
}

BackArrowDesktop.defaultProps = {
  width: 40,
  height: 40,
  color: 'black',
};

export default BackArrowDesktop;
