import React from 'react';

class DesktopCross extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 24.9 24.9"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M15.5,12.3l7.6-7.6c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0l-7.6,7.6L4.8,1.6c-0.9-0.9-2.3-0.9-3.2,0
          c-0.9,0.9-0.9,2.3,0,3.2l7.6,7.6l-7.6,7.6c-0.9,0.9-0.9,2.3,0,3.2c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7l7.6-7.6l7.6,7.6
          c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7c0.9-0.9,0.9-2.3,0-3.2L15.5,12.3z"/>
      </svg>
    );
  }
}

DesktopCross.defaultProps = {
  width: 100,
  height: 100,
  color: '#a7a7a7',
};

export default DesktopCross;