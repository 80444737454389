import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { toastr } from 'react-redux-toastr';

let debug = false;
if (process.env.NODE_ENV === 'development') {
	debug = true;
}

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// load: 'languageOnly',
		fallbackLng: 'en',
		debug,
		ns: ['translations'],
		defaultNS: 'translations',
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
			queryStringParams: { v: process.env.VERSION },
		},
	});

if (process.env.NODE_ENV === 'development') {
	// Show error in console if keys are saved with spaces as this messes up locize
	i18n.on('missingKey', (lngs, namespace, key, res) => {
		if (key.includes(' ')) {
			const message = `Trying to save missing key containing a space: '${key}'. Please fix this key to remove the spaces and delete the key from locize.io.`;
			console.error(message);
			toastr.error('Missing Key', message);
		}
	});
	window.i18n = i18n;
	window.changeLanguage = lang => i18n.changeLanguage(lang);
}

export default i18n;
