import {
	EVENTS_RESET_APP,
	EVENTS_SET_TOKEN,
	EVENTS_TOGGLE_FILTER,
	EVENTS_SET_FILTERS,
	EVENTS_DOWNLOAD_STARTED,
	EVENTS_DOWNLOAD_FAILED,
	EVENTS_DOWNLOAD_FINISHED,
} from './events.action';
import uuid4 from 'uuid4';

const initialState = {
	sequenceId: uuid4(),
	token: '',
	filters: [],
	isDownloading: false,
};

export const setFilters = (filters, { name }) => {
	if (filters.indexOf(name) !== -1) {
		return filters.filter(f => f !== name);
	} else {
		return filters.concat([name]);
	}
};

export default function events(state = initialState, action) {
	switch (action.type) {
		case EVENTS_RESET_APP:
			return {
				...initialState,
				sequenceId: uuid4(),
				token: state.token,
				isDownloading: false,
			};
		case EVENTS_SET_TOKEN:
			return {
				...state,
				token: action.token,
			};
		case EVENTS_TOGGLE_FILTER:
			return {
				...state,
				filters: setFilters(state.filters, action.filter),
			};
		case EVENTS_SET_FILTERS:
			return {
				...state,
				filters: action.filters,
			};
		case EVENTS_DOWNLOAD_STARTED:
			return {
				...state,
				isDownloading: true,
			};
		case EVENTS_DOWNLOAD_FAILED:
			return {
				...state,
				isDownloading: false,
			};
		case EVENTS_DOWNLOAD_FINISHED:
			return {
				...state,
				isDownloading: false,
			};
		default:
			return state;
	}
}

export const getSequenceId = state => state && state.events && state.events.sequenceId;
export const getToken = state => state && state.events && state.events.token;
export const getFilters = state => state && state.events && state.events.filters;
export const getIsDownloading = state => state && state.events && state.events.isDownloading;
