import { call, put, takeLatest, select } from 'redux-saga/effects';
import actions, { QUESTIONS_FETCH } from './questions.action';
import { getQuestions } from '../../utils/api';
import { appGetLanguage } from '../App/app.reducer';

export function* fetchQuestions() {
	try {
		const { code } = yield select(appGetLanguage);
		const { categories, more_infos, questions } = yield call(getQuestions, code);
		if (categories) {
			yield put(actions.setCategories(categories));
		}
		if (questions) {
			yield put(actions.setQuestions(questions));
		}
		if (more_infos) {
			yield put(actions.setMoreInfos(more_infos));
		}
	} catch (e) {
		console.error(e);
	}
}
export function* fetchQuestionsListener() {
	yield takeLatest(QUESTIONS_FETCH, fetchQuestions);
}

export default [fetchQuestionsListener];
