import React from 'react';

class CrossCircle extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 24.9 24.9"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M12.5,0c6.9,0,12.5,5.6,12.5,12.5s-5.6,12.5-12.5,12.5S0,19.3,0,12.5c0,0,0,0,0,0C0,5.6,5.6,0,12.5,0z" />
        <path
          fill="#fff"
          d="M14,12.6l2.9-2.9c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-2.9,2.9L9.7,8.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
	   l2.9,2.9l-2.9,2.9c-0.4,0.4-0.4,1,0,1.4C8.5,17,8.7,17.1,9,17.1s0.5-0.1,0.7-0.3l2.9-2.9l2.9,2.9c0.2,0.2,0.5,0.3,0.7,0.3
	   s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L14,12.6z"
        />
      </svg>
    );
  }
}

CrossCircle.defaultProps = {
  width: 100,
  height: 100,
  color: '#a7a7a7',
};

export default CrossCircle;
