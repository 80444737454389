export const EVENTS_FIRE = 'EVENTS_FIRE';
export const EVENTS_LOGIN = 'EVENTS_LOGIN';
export const EVENTS_RESET_APP = 'EVENTS_RESET_APP';
export const EVENTS_GET_MISTAKES = 'EVENTS_GET_MISTAKES';
export const EVENTS_SET_TOKEN = 'EVENTS_SET_TOKEN';
export const EVENTS_TOGGLE_FILTER = 'EVENTS_TOGGLE_FILTER';
export const EVENTS_SET_FILTERS = 'EVENTS_SET_FILTERS';
export const EVENTS_MARK_MISTAKE_COMPLETE = 'EVENTS_MARK_MISTAKE_COMPLETE';
export const EVENTS_DOWNLOAD_REPORT = 'EVENTS_DOWNLOAD_REPORT';
export const EVENTS_FIRE_WITH_REVIEW_ID = 'EVENTS_FIRE_WITH_REVIEW_ID';
export const EVENTS_DOWNLOAD_STARTED = 'EVENTS_DOWNLOAD_STARTED';
export const EVENTS_DOWNLOAD_FAILED = 'EVENTS_DOWNLOAD_FAILED';
export const EVENTS_DOWNLOAD_FINISHED = 'EVENTS_DOWNLOAD_FINISHED';

const eventsFire = ({ event, data }) => ({
	type: EVENTS_FIRE,
	event,
	data,
});

const eventsFireWithReviewId = ({ event, data }) => ({
	type: EVENTS_FIRE_WITH_REVIEW_ID,
	event,
	data,
});

const eventsMarkMistakeAndComplete = product => ({
	type: EVENTS_MARK_MISTAKE_COMPLETE,
	product,
});

const eventsLogin = () => ({
	type: EVENTS_LOGIN,
});

const eventsResetApp = () => ({
	type: EVENTS_RESET_APP,
});

const eventsGetMistakes = () => ({
	type: EVENTS_GET_MISTAKES,
});

const eventsSetToken = token => ({
	type: EVENTS_SET_TOKEN,
	token,
});

const eventsToggleFilter = filter => ({
	type: EVENTS_TOGGLE_FILTER,
	filter,
});

const eventsSetFilter = filters => ({
	type: EVENTS_SET_FILTERS,
	filters,
});

const eventsDownloadReport = event => ({
	type: EVENTS_DOWNLOAD_REPORT,
	event,
});

const eventsDownloadReportStarted = () => ({
	type: EVENTS_DOWNLOAD_STARTED,
});

const eventsDownloadReportFailed = () => ({
	type: EVENTS_DOWNLOAD_FAILED,
});

const eventsDownloadReportFinished = () => ({
	type: EVENTS_DOWNLOAD_FINISHED,
});

const actions = {
	eventsFire,
	eventsLogin,
	eventsResetApp,
	eventsGetMistakes,
	eventsSetToken,
	eventsToggleFilter,
	eventsSetFilter,
	eventsMarkMistakeAndComplete,
	eventsDownloadReport,
	eventsFireWithReviewId,
	eventsDownloadReportStarted,
	eventsDownloadReportFailed,
	eventsDownloadReportFinished,
};

export default actions;
