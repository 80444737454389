import styled from 'styled-components';

export const ProgressBarWrapper = styled.article`
  display: flex;
  flex: 1;
  min-width: 120px;
  background-color: rgba(255, 255, 255, 0.3);
  height: 11px;
  border-radius: 5px;

  $colors: (
    orange: ${props => props.theme.colors.ORANGE},
    pink: ${props => props.theme.colors.PINK},
    purple: ${props => props.theme.colors.PURPLE},
    lightblue: ${props => props.theme.colors.LIGHT_BLUE},
  );

  @each $color, $var in $colors {
    &--#{$color} {
      color: #{$var};

      .progress-bar--dashed {
        background-image: linear-gradient(
          135deg,
          #{$var} 25%,
          transparent 25%,
          transparent 50%,
          #{$var} 50%,
          #{$var} 75%,
          transparent 75%,
          transparent
        );
      }
    }
  }

  span:first-child {
    margin-left: 5px;
    margin-right: 5px;
  }

  span:nth-child(2):not(:last-child) {
    margin-right: 5px;
  }

  span:last-child {
    margin-right: 5px;
  }
`;

export const ProgressBarDotted = styled.div`
  position: relative;
  top: 3px;
  width: 100%;
  border-top: 5px dotted white;
  margin-right: 3px;
`;

export const ProgressBarDashed = styled.div`
  position: relative;
  top: 3px;
  border-radius: 2px;
  height: 5px;
  width: 100%;
  background-size: 5px 5px;
  background-image: linear-gradient(
    135deg,
    white 25%,
    transparent 25%,
    transparent 50%,
    white 50%,
    white 75%,
    transparent 75%,
    transparent
  );
`;

export const ProgressBarSolid = styled.div`
  position: relative;
  top: 3px;
  width: 100%;
  height: 0;
  border: 2.5px solid white;
  border-radius: 2px;
  margin-right: 3px;
`;
