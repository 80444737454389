import React from 'react';

class LearnMaintainSolution extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 50.8 45.419"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M50.728 12.7c0-.072-.072-.072 0 0a.552.552 0 0 0-.144-.215l-.072-.072c-.072-.072-.144-.072-.215-.144L33.58 5.31a.786.786 0 0 0-.574 0l-16.718 6.96c-.072 0-.144.072-.215.144a.07.07 0 0 1-.072.072c-.072.072-.072.072-.072.144v.072c0 .072-.072.144-.072.215v19.514a.628.628 0 0 0 .431.646l16.718 7.032h.287a.431.431 0 0 0 .287-.072l16.79-6.96a.69.69 0 0 0 .431-.646V12.915a.264.264 0 0 1-.073-.215zM33.293 6.745l14.853 6.171-14.925 6.17-14.853-6.171zM17.22 13.992l15.355 6.386v18.01L17.22 32.001zm16.79 24.4V20.378l15.355-6.386v18.009z"/>
        <path d="M11.624 0H.718A.678.678 0 0 0 0 .718V44.7a.678.678 0 0 0 .718.718h10.906a.678.678 0 0 0 .718-.718V.718A.678.678 0 0 0 11.624 0zM1.435 43.984V1.435h9.471v3.588H6.6a.678.678 0 0 0-.718.718.678.678 0 0 0 .718.718h4.305v2.725H8.323a.718.718 0 1 0 0 1.435h2.583v2.87H6.6a.718.718 0 1 0 0 1.435h4.305v2.8H8.323a.718.718 0 1 0 0 1.435h2.583v2.8H6.6a.718.718 0 1 0 0 1.435h4.305V26.4H8.323a.718.718 0 1 0 0 1.435h2.583v2.583H6.6a.718.718 0 0 0 0 1.435h4.305v2.8H8.323a.718.718 0 1 0 0 1.435h2.583v2.8H6.6a.718.718 0 1 0 0 1.435h4.305v3.731h-9.47z"/>
      </svg>
    )
  }
}

LearnMaintainSolution.defaultProps = {
  width: 50,
  height: 50,
  color: 'black',
};

export default LearnMaintainSolution;