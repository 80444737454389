import React from 'react';
import Header from '../components/Header';
import BlocksList from '../components/BlocksList';
import styled from 'styled-components';

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

class Intro extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <Header />
        <BlocksList />
      </Wrapper>
    );
  }
}

export default Intro;
