import React from 'react';

class Minus extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 21.9 3.5"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M20.135 3.432H1.75A1.75 1.75 0 0 1-.018 1.664c0-.99.778-1.768 1.768-1.768h18.385c.99 0 1.768.778 1.768 1.768-.071 1.06-.849 1.839-1.768 1.768z"/>
      </svg>
    )
  }
}

Minus.defaultProps = {
  width: 28,
  height: 28,
  color: 'black',
};

export default Minus;