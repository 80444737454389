import React from 'react';
import styled from 'styled-components';
import ArrowRight from '../../../components/icons/ArrowRight';
import { getColor } from '../../../utils/util';
import { useTranslation } from 'react-i18next';

const StyledButton = styled.button`
  padding: 20px;
  margin: 15px 0px 0px 0px;
  background-color: ${props => (props.desktop ? props.color : props.theme.colors.GREY)};
  border: 2px solid ${props => props.color};
  font-weight: bold;
  color: ${props => (props.desktop && props.color !== props.theme.colors.YELLOW ? 'white' : 'black')};
  width: ${props => (props.block ? '100%' : 'auto')};
  cursor: pointer;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  min-height: 68px;

  section {
    visibility: ${props => (props.desktop ? 'hidden' : 'visible')};
  }
`;

export default ({ category, desktop, goTo, children }) => {
  const { t } = useTranslation();
  return (
    <StyledButton className="MoreInfoAbout" desktop={desktop} color={getColor(category)} onClick={goTo}>
      <div style={{ width: '12px', visibility: 'hidden' }} />
      {t('moreInfoAbout', 'More info about...')}
      <section>
        <ArrowRight color={'black'} />
      </section>
    </StyledButton>
  );
};
