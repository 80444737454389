import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import PlusExpand from './icons/PlusExpand';
import MinusCollapse from './icons/MinusCollapse';
import { languages } from './Slider/SliderComponents';
import { Trans } from 'react-i18next';

const StepItem = styled.li`
	border-bottom: 1px solid ${props => props.theme.colors.DARK_GREY};
	padding: 15px 0px;

	section {
		padding-right: 20px;
	}
`;

const StepHeader = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;

	.question {
		max-width: 80%;
	}
`;

class Step extends React.PureComponent {
	state = {
		expanded: false,
	};

	handleOnClick = () => {
		this.setState({ expanded: !this.state.expanded });
	};

	render() {
		const { title, video, description, id, noStepHeader, country, language } = this.props;
		const getSource = code => require(`../assets/videos/how/how_${code}.vtt`);
		const isDefault = code => {
			if (country.name === 'Canada' && language.code === 'fr-ca' && code === 'fr_ca') {
				return true;
			}

			return language.code.replace(/-/, '_') === code;
		};
		return (
			<StepItem className="StepItem">
				<StepHeader className="StepItem__StepHeader" onClick={() => this.handleOnClick()}>
					{!noStepHeader && (
						<h1 style={{ fontWeight: '300' }}>
							<Trans i18nKey="Step">Step</Trans> {id}
						</h1>
					)}
					{noStepHeader && <h1 className={'question'}>{title}</h1>}
					{!this.state.expanded && <PlusExpand width={14} height={14} />}
					{this.state.expanded && <MinusCollapse width={14} height={14} />}
				</StepHeader>
				<section>
					{!noStepHeader && <h1>{title}</h1>}
					{this.state.expanded && video && (
						<video src={video} width={320} controls autoPlay={false} preload="metadata" type="video/mp4">
							{languages.map(track => (
								<track
									key={track.code}
									label={track.name}
									kind="subtitles"
									src={getSource(track.code)}
									default={isDefault(track.code)}
								/>
							))}
						</video>
					)}
					{this.state.expanded && <h2 dangerouslySetInnerHTML={{ __html: description }} />}
				</section>
			</StepItem>
		);
	}
}

export default withRouter(Step);
