import React from 'react';
import styled from 'styled-components';
import ProgressBar from '../ProgressBar/ProgressBar';
import { withRouter } from 'react-router';

const Blocks = styled.ul`
  display: flex;
  flex-direction: column;
`;

const Block = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.color};
  color: ${props => props.theme.colors.WHITE};
  padding: 20px;
  height: 150px;
  margin-bottom: 10px;
  .ProgressBar {
    position: absolute;
    top: 15px;
    left: 20px;
  }
`;

const BlockHeader = styled.header`
  height: 100%;
`;

class _Blocks extends React.PureComponent {
  render() {
    const { tasks, color, id } = this.props;

    return (
      <Blocks>
        {tasks.map((t, key) => (
          <Block color={color} key={key} onClick={() => this.props.history.push(`/cmblocks/${id}/${t.id}`)}>
            <BlockHeader>
              <ProgressBar roles={t.roles} color={color} />
            </BlockHeader>
            {t.name}
          </Block>
        ))}
      </Blocks>
    );
  }
}

export default withRouter(_Blocks);
