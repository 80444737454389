import React from 'react';
import {
  ProgressBarWrapper,
  ProgressBarDotted,
  ProgressBarDashed,
  ProgressBarSolid,
} from './ProgressBarComponents';

const ProgressBar = ({ type, color, chosen, roles = [] }) => {
  const isSales = roles
    .filter(role => role.name === 'sales')
    .reduce((memo, role) => role, false);

  const isLogistics = roles
    .filter(role => role.name === 'logistics')
    .reduce((memo, role) => role, false);

  const isCom = roles
    .filter(role => role.name === 'com&in')
    .reduce((memo, role) => role, false);

  return (
    <ProgressBarWrapper color={color} className="ProgressBar">
      {isSales && <ProgressBarDotted style={{ flex: isSales.percentage }} />}
      {isLogistics && (
        <ProgressBarDashed
          style={{ flex: isLogistics.percentage }}
          color={color}
        />
      )}
      {isCom && <ProgressBarSolid style={{ flex: isCom.percentage }} />}
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
