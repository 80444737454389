import React from 'react';

class TestbuyAvailabilityOrange extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 64.472 61.821"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          fill="#f58337"
          d="M56.914,20.531,64.229,12a1.058,1.058,0,0,0,.183-.853.879.879,0,0,0-.549-.67L41.067.051a1.688,1.688,0,0,0-.549-.061.99.99,0,0,0-.549.3l-7.741,8.35-7.8-8.35a.947.947,0,0,0-.853-.3c-.061,0-.122.061-.244.061L.534,10.474a1.226,1.226,0,0,0-.549.67A1.058,1.058,0,0,0,.168,12l7.314,8.533L.229,29a.905.905,0,0,0-.244.853.879.879,0,0,0,.549.67l7.253,3.291v16.64a.956.956,0,0,0,.549.853L31.8,61.734a1.128,1.128,0,0,0,.731,0L56.061,51.372a.863.863,0,0,0,.549-.853v-16.7l7.253-3.291a1.226,1.226,0,0,0,.549-.67A1.058,1.058,0,0,0,64.229,29Zm-23.771,12.8,6.827,7.375a1.033,1.033,0,0,0,1.1.244L54.72,34.733V49.849L33.143,59.418Zm22.247-11.7,6.522,7.619-21.028,9.63-7.07-7.619ZM33.814,9.742l7.07-7.619,21.028,9.63-6.522,7.558Zm19.5,10.788L32.229,29.856l-21.15-9.326,21.089-9.387ZM30.583,31.258l-7.07,7.619L2.484,29.247l6.522-7.619ZM9.677,34.672,23.33,40.889a.952.952,0,0,0,1.1-.244l6.827-7.375V59.357L9.677,49.849ZM2.484,11.693,23.513,2.123l7.07,7.619L9.006,19.312Z"
          transform="translate(0.037 0.025)"
        />
      </svg>
    );
  }
}

TestbuyAvailabilityOrange.defaultProps = {
  width: 60,
  height: 60,
  color: '#a7a7a7',
};

export default TestbuyAvailabilityOrange;
