import React from 'react';

class LearnMaintainSolution extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 55.141 57.532"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M41.722,28.951h0a.583.583,0,0,0-.077-.308h0v-.077a.166.166,0,0,0-.154-.154l-.077-.077c-.077-.077-.154-.077-.231-.154l-12.956-5.4v-4.01a.771.771,0,0,0-1.542,0v3.47l-5.63-2.314a.845.845,0,0,0-.617,0L.463,28.257c-.077,0-.154.077-.231.154l-.077.077L0,28.643v.077H0q0,.116,0,.231H0V52.4a.674.674,0,0,0,.463.694l19.666,8.175a.786.786,0,0,0,.617.386.7.7,0,0,0,.54-.231L41.26,53.09a.741.741,0,0,0,.463-.694V28.951ZM20.9,21.47l5.938,2.468v4.087a.771.771,0,0,0,1.542,0V24.632l10.643,4.4L20.9,36.509,2.776,28.951ZM1.542,30.108,19.974,37.82V59.645L1.542,51.933ZM21.517,59.722V37.9L40.18,30.108V51.933Z" transform="translate(0 -4.118)"/>
        <path d="M35.471,10.72a.729.729,0,0,0,.771-.771V.771a.771.771,0,1,0-1.542,0V9.949A.773.773,0,0,0,35.471,10.72Z" transform="translate(-7.939)"/>
        <path d="M20.571,16.542h9.177a.771.771,0,0,0,0-1.542H20.571a.771.771,0,0,0,0,1.542Z" transform="translate(-4.53 -3.432)"/>
        <path d="M38.471,16.542h9.177a.771.771,0,1,0,0-1.542H38.471a.729.729,0,0,0-.771.771A.773.773,0,0,0,38.471,16.542Z" transform="translate(-8.626 -3.432)"/>
        <path d="M61.271,22.878a.729.729,0,0,0,.771-.771V17.171a.771.771,0,1,0-1.542,0v4.936A.729.729,0,0,0,61.271,22.878Z" transform="translate(-13.842 -3.752)"/>
        <path d="M57.207,25.4H52.271a.771.771,0,1,0,0,1.542h4.936a.729.729,0,0,0,.771-.771A.773.773,0,0,0,57.207,25.4Z" transform="translate(-11.783 -5.811)"/>
        <path d="M62.042,33.807V28.871a.771.771,0,1,0-1.542,0v4.936a.729.729,0,0,0,.771.771A.773.773,0,0,0,62.042,33.807Z" transform="translate(-13.842 -6.429)"/>
        <path d="M68.807,25.4H63.871a.771.771,0,1,0,0,1.542h4.936a.771.771,0,1,0,0-1.542Z" transform="translate(-14.437 -5.811)"/>
        <path d="M54.645,6.99A2.568,2.568,0,0,0,57.19,4.445a2.545,2.545,0,1,0-5.09,0A2.568,2.568,0,0,0,54.645,6.99Zm0-3.548a1,1,0,1,1-1,1A1.021,1.021,0,0,1,54.645,3.442Z" transform="translate(-11.92 -0.435)"/>
        <path d="M64.268,42.9A2.485,2.485,0,0,0,61.8,45.368a2.436,2.436,0,0,0,2.468,2.468,2.468,2.468,0,0,0,0-4.936Zm0,3.316a.938.938,0,0,1-.925-.925.891.891,0,0,1,.925-.925.925.925,0,0,1,0,1.851Z" transform="translate(-14.139 -9.815)"/>
        <path d="M26.068,31.736A2.468,2.468,0,1,0,23.6,29.268,2.436,2.436,0,0,0,26.068,31.736Zm0-3.316a.938.938,0,0,1,.925.925.891.891,0,0,1-.925.925.938.938,0,0,1-.925-.925A.891.891,0,0,1,26.068,28.42Z" transform="translate(-5.4 -6.132)"/>
      </svg>
    )
  }
}

LearnMaintainSolution.defaultProps = {
  width: 50,
  height: 50,
  color: 'black',
};

export default LearnMaintainSolution;
