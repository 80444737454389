export const START_PRACTICE = 'START_PRACTICE';
export const SET_PRACTICE_TOTAL = 'SET_PRACTICE_TOTAL';
export const UPDATE_PRACTICE_RESULT = 'UPDATE_PRACTICE_RESULT';
export const LEARN_GET_LEARN_PRACTICE = 'LEARN_GET_LEARN_PRACTICE';
export const LEARN_SET_LEARN_PRACTICE = 'LEARN_SET_LEARN_PRACTICE';

const setPracticeTotal = total => ({
  type: SET_PRACTICE_TOTAL,
  total,
});

const startPractice = () => ({
  type: START_PRACTICE,
});

const updatePracticeResults = result => ({
  type: UPDATE_PRACTICE_RESULT,
  result,
});

const setLearnPractice = learnPractice => ({
  type: LEARN_SET_LEARN_PRACTICE,
  learnPractice,
});

const getLearnPractice = () => ({
  type: LEARN_GET_LEARN_PRACTICE,
});

const actions = {
  startPractice,
  setPracticeTotal,
  updatePracticeResults,
  setLearnPractice,
  getLearnPractice,
};

export default actions;
