// @flow
import { takeLatest, takeEvery, call, select, put, all } from 'redux-saga/effects';
import {
	EVENTS_FIRE,
	EVENTS_LOGIN,
	EVENTS_GET_MISTAKES,
	EVENTS_MARK_MISTAKE_COMPLETE,
	EVENTS_DOWNLOAD_REPORT,
	EVENTS_FIRE_WITH_REVIEW_ID,
} from './events.action';
import uuid4 from 'uuid4';
import { getSequenceId, getToken, getFilters } from './events.reducer';
import jwtDecode from 'jwt-decode';
import differenceInHours from 'date-fns/difference_in_hours';
import { sendEvent, loginEvents, getMistakes, API_HOST } from '../../utils/api';
import { appGetStore, appGetCountry } from '../App/app.reducer';
import { getReviewId } from '../Products/products.reducer';
import actions from '../Mistakes/mistakes.action';
import eventActions from './events.action';
import { getProducts } from '../Products/products.reducer';
import { getQuestions, getCategories } from '../Questions/questions.reducer';
import { fetchQuestions } from '../Questions/questions.saga';
import { getTestbuyReport } from '../../utils/api';
import productActions from '../Products/products.action';

export const getApiUrl = event => `${API_HOST}/api/events/${event}/`;
const TOKEN_URL = `${API_HOST}/auth/request-username-only/`;
const REFRESH_TOKEN_URL = `${API_HOST}/auth/refresh/`;

export const shouldRefreshToken = token => {
	const decoded = jwtDecode(token);
	const timeToExp = differenceInHours(new Date(decoded.exp * 1000), Date.now());
	return timeToExp <= 2;
};

function* fireEvent({ event, data = null }) {
	const recordedAtTimestamp = new Date();
	try {
		let TOKEN = yield select(getToken);
		const sequenceId = yield select(getSequenceId);
		const url = getApiUrl(event);

		if (shouldRefreshToken(TOKEN)) {
			yield call(eventsRefreshToken);
			TOKEN = yield select(getToken);
		}

		const body = Object.assign(
			{},
			{ data },
			{ sequence_id: sequenceId, recorded_at: recordedAtTimestamp.toISOString() }
		);
		yield call(
			sendEvent,
			url,
			{
				'Content-Type': 'application/json',
				Authorization: `JWT ${TOKEN}`,
			},
			JSON.stringify(body)
		);
	} catch (e) {
		console.log(e);
	}
}

function* fireEventWithReviewId({ event, data = null }) {
	const recordedAtTimestamp = new Date();
	try {
		let TOKEN = yield select(getToken);
		const sequenceId = yield select(getSequenceId);
		const url = getApiUrl(event);
		yield put(productActions.setReviewId(sequenceId));

		if (shouldRefreshToken(TOKEN)) {
			yield call(eventsRefreshToken);
			TOKEN = yield select(getToken);
		}

		const body = Object.assign(
			{},
			{ data },
			{ sequence_id: sequenceId, recorded_at: recordedAtTimestamp.toISOString() }
		);
		yield call(
			sendEvent,
			url,
			{
				'Content-Type': 'application/json',
				Authorization: `JWT ${TOKEN}`,
			},
			JSON.stringify(body)
		);
	} catch (e) {
		console.log(e);
	}
}

export function* eventsLogin() {
	try {
		const TOKEN = yield select(getToken);
		if (!TOKEN) {
			const username = uuid4();
			const token = yield call(
				loginEvents,
				TOKEN_URL,
				{
					'Content-Type': 'application/json',
				},
				JSON.stringify({ username })
			);
			yield put(eventActions.eventsSetToken(token));
		}
	} catch (e) {
		console.log(e);
	}
}

export function* eventsRefreshToken() {
	try {
		const TOKEN = yield select(getToken);
		const token = yield call(
			loginEvents,
			REFRESH_TOKEN_URL,
			{
				'Content-Type': 'application/json',
			},
			JSON.stringify({ token: TOKEN })
		);
		yield put(eventActions.eventsSetToken(token));
	} catch (e) {
		console.log('Refresh token failed - removing token and relogging in');
		yield put(eventActions.eventsSetToken(undefined));
		yield call(eventsLogin);
	}
}

function* eventsGetMistakes() {
	try {
		yield put(actions.fetchingMistakesStart());
		const filters = yield select(getFilters);
		const areas = filters.reduce((memo, f) => `${memo}&area=${f}`, '');
		let TOKEN = yield select(getToken);
		if (shouldRefreshToken(TOKEN)) {
			yield call(eventsRefreshToken);
			TOKEN = yield select(getToken);
		}
		const products = yield select(getProducts);
		const categories = yield select(getCategories);

		let questions = yield select(getQuestions);
		if (questions.length === 0) {
			yield call(fetchQuestions);
			questions = yield select(getQuestions);
		}
		const { identifier } = yield select(appGetStore);
		const { code } = yield select(appGetCountry);
		yield put(
			actions.mistakesSet({ fixed: { results: [] }, notFixed: { results: [] } }, products, questions, categories)
		);
		const urlNotFixed = `${API_HOST}/api/ikea_testbuy/mistakes/?country=${code}&store=${identifier}&fixed=0${areas}`;
		const urlFixed = `${API_HOST}/api/ikea_testbuy/mistakes/?country=${code}&store=${identifier}&fixed=1${areas}`;
		const fixed = yield call(getMistakes, urlFixed, {
			Authorization: `JWT ${TOKEN}`,
		});
		const notFixed = yield call(getMistakes, urlNotFixed, {
			Authorization: `JWT ${TOKEN}`,
		});
		const mistakes = { fixed, notFixed };
		yield put(actions.mistakesSet(mistakes, products, questions, categories));
		yield put(actions.fetchingMistakesSuccess());
	} catch (e) {
		yield put(actions.fetchingMistakesFailed());
		console.error(e);
	}
}

function* eventsMarkMistakesComplete({ product }) {
	try {
		const { identifier } = yield select(appGetStore);
		const country = yield select(appGetCountry);
		yield all(
			product.mistakes.map(mistake =>
				call(fireEvent, {
					data: {
						...mistake,
						area: product.area,
						country: country.code,
						store: identifier,
						language: country.languages[0].code,
					},
					event: 'mistake_found',
				})
			)
		);
		yield call(fireEvent, {
			data: {
				product_identifier: product.product_identifier,
				area: product.area,
				price: parseFloat(product.price && product.price.split(' ')[1]),
				currency: product.price && product.price.split(' ')[0],
				country: country.code,
				store: identifier,
				language: country.languages[0].code,
			},
			event: 'product_completed',
		});
	} catch (e) {
		console.log(e);
	}
}

function* eventsDownloadReport() {
	try {
		yield put(eventActions.eventsDownloadReportStarted());
		let TOKEN = yield select(getToken);
		let id = yield select(getReviewId);
		if (shouldRefreshToken(TOKEN)) {
			yield call(eventsRefreshToken);
			TOKEN = yield select(getToken);
		}
		const url = `${API_HOST}/api/ikea_testbuy/reviewreport/${id}/`;
		yield call(getTestbuyReport, url, {
			Authorization: `JWT ${TOKEN}`,
		});
	} catch (e) {
		console.log(e);
		yield put(eventActions.eventsDownloadReportFailed());
	}
}

export function* watchEventsLogin() {
	yield takeLatest(EVENTS_LOGIN, eventsLogin);
}

export function* watchFireEvent() {
	yield takeLatest(EVENTS_FIRE, fireEvent);
}

export function* watchEventsGetMistakest() {
	yield takeLatest(EVENTS_GET_MISTAKES, eventsGetMistakes);
}

export function* watchEventsMarkMistakeComplete() {
	yield takeEvery(EVENTS_MARK_MISTAKE_COMPLETE, eventsMarkMistakesComplete);
}

export function* eventsDownloadReportListener() {
	yield takeLatest(EVENTS_DOWNLOAD_REPORT, eventsDownloadReport);
}

export function* watchFireEventWithReviewId() {
	yield takeLatest(EVENTS_FIRE_WITH_REVIEW_ID, fireEventWithReviewId);
}

export default [
	watchFireEvent,
	watchEventsLogin,
	watchEventsGetMistakest,
	watchEventsMarkMistakeComplete,
	eventsDownloadReportListener,
	watchFireEventWithReviewId,
];
