import React from 'react';

class BackArrow extends React.Component {
  render() {
    return (
      <svg
        className="BackArrow"
        viewBox="0 0 27 22"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <path
          d="M26,10H3.4l8.3-8.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-10,10c-0.4,0.4-0.4,1,0,1.4l10,10
	c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L3.4,12H26c0.6,0,1-0.4,1-1S26.6,10,26,10z"
        />
      </svg>
    );
  }
}

BackArrow.defaultProps = {
  width: 28,
  height: 28,
  color: '#282828',
};

export default BackArrow;
