import React, { useState } from 'react';
import styled from 'styled-components';
import Plus from '../../components/icons/PlusExpand';
import SearchIcon from '../../components/icons/SearchMagnifyingGlass';
import Button from '../../components/Form/Button/Button';
import Modal from '../../components/Modal';
import theme from '../../styles/1-settings/theme';
import actions from '../../store/Review/review.action';
import { Trans } from 'react-i18next';
import CheckIcon from '../../components/icons/CheckIcon';
import { ProductLoading } from '../TestBuy/components/Product';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.article`
	background-color: white;
	height: ${(props) => (props.desktop ? 'auto' : 'calc(100% - 60px);')};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border-top: 1px solid #dedede;
	box-shadow: ${(props) => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
	margin: ${(props) => (props.desktop ? 'auto' : '0px')};
	overflow: ${(props) => (props.scroll ? 'auto' : 'hidden')};
	width: 100%;

	@media screen and (min-width: 1200px) {
		min-height: 525px;
	}

	footer {
		display: flex;
		flex-direction: ${(props) => (props.desktop ? 'row' : 'column')};
		background-color: ${(props) => (props.desktop ? 'white' : props.theme.colors.GREY)};
		padding: ${(props) => (props.desktop ? '20px 60px' : '20px')};
		justify-content: center;
		margin-top: auto;

		section {
			display: flex;
			flex-direction: column;
			margin: ${(props) => (props.desktop ? '10px' : '10px 0px')};
			width: ${(props) => (props.desktop ? '240px' : 'auto')};
		}
	}
`;

const Search = styled.div`
	display: flex;
	justify-content: space-between;
	border: 1px solid #d9d9d9;
	padding: 10px;
	border-radius: 2px;
	margin: 10px 10px 0;

	input {
		border: none;
		width: 100%;
	}
`;

const SearchResults = styled.div`
	min-height: 200px;
	margin: 0px;
	overflow-y: auto;

	@media screen and (min-width: 1200px) {
		max-height: 465px;
	}
`;

const EmptyResults = styled.div`
	padding: 40px;
`;

const SearchResult = styled.div`
	display: flex;
	padding: ${(props) => (props.desktop ? '30px 70px' : '20px 12px 20px 30px')};
	height: ${(props) => (props.desktop ? '105px' : '100px')};
	align-items: center;
	border-bottom: 1px solid #dedede;
	background-color: white;
	opacity: ${(props) => (props.alreadySelected ? '0.2' : '1')};
	transition: all 300ms ease-in-out;

	.image {
		background-image: ${(props) => `url(${props.image})`};
		width: 75px;
		height: 75px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 20px;
	}

	.content {
		width: calc(100% - 95px);
		position: relative;
		header {
			font-size: 18px;
			line-height: 22px;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		p {
			font-size: 16px;
			line-height: 24px;
			&.price {
				font-weight: bold;
			}
		}

		div {
			position: absolute;
			right: 12px;
			bottom: 24px;
		}
	}
`;

export default function ReviewSearch(props) {
	const shoppingList = useSelector((state) => state.review && state.review.shoppingList);
	const searchResults = useSelector((state) => state.review && state.review.searchResults);
	const isSearching = useSelector((state) => state.review && state.review.isSearching);
	const dispatch = useDispatch();
	const history = useHistory();
	const { desktop } = props;
	const { t } = useTranslation();
	const [length, setLength] = useState(shoppingList.length);
	const [inputValue, setInputValue] = useState('');
	const [showModal, setShowModal] = useState(length === 10);

	const addToReviewList = (product) => {
		dispatch(actions.addProduct(product));
		shoppingList.length && setLength(shoppingList.length);
		shoppingList.length === 10 && setShowModal(true);
	};

	const keyUpHandler = (e) => {
		if (e.keyCode === 13) {
			setInputValue(e.target.value);
			inputValue !== '' && dispatch(actions.reviewGetSearchResults(inputValue));
		}
	};

	const handleChange = (event) => {
		setInputValue(event.target.value);
	};

	const handleOnClick = () => {
		inputValue !== '' && dispatch(actions.reviewGetSearchResults(inputValue));
	};

	return (
		<Wrapper desktop={desktop}>
			{length === 20 && (
				<Modal>
					<Modal.Body desktop={desktop}>
						<header>
							{t(
								'reviewSearch.listFull',
								"You've got 20 items on your shopping list. You need to delete an item before you add a new one."
							)}
						</header>
						<Button desktop={desktop} onClick={() => history.push('/review')}>
							<Trans i18nKey="reviewSearch.goToShoppingList">Go to shopping list</Trans>
						</Button>
					</Modal.Body>
				</Modal>
			)}
			{showModal && (
				<Modal>
					<Modal.Body desktop={desktop}>
						<header>
							{t(
								'reviewSearch.enoughProductsOnList',
								'You now have 10 products on your shopping list. You can go to your shopping list and start your review, or add 10 more products.'
							)}
						</header>
						<Button desktop={desktop} onClick={() => history.push('/review')}>
							<Trans i18nKey="reviewSearch.goToShoppingList">Go to shopping list</Trans>
						</Button>
						<Button desktop={desktop} onClick={() => setShowModal(false)}>
							<Trans i18nKey="reviewSearch.addMoreProducts">Add more products</Trans>
						</Button>
					</Modal.Body>
				</Modal>
			)}
			<Search>
				<input
					placeholder={t('reviewSearch.placeholder', 'Article no.')}
					onKeyUp={keyUpHandler}
					value={inputValue}
					onChange={handleChange}
				></input>
				<div onClick={handleOnClick}>
					<SearchIcon color={'#000'} width={26} height={26} />
				</div>
			</Search>
			{isSearching ? (
				<SearchResults>
					<ProductLoading />
					<ProductLoading />
					<ProductLoading />
					<ProductLoading />
					<ProductLoading />
				</SearchResults>
			) : searchResults && searchResults.length ? (
				<SearchResults>
					{searchResults.map((prod, i) => (
						<SearchResult
							key={i}
							alreadySelected={shoppingList.some((p) => p.identifier === prod.identifier)}
							image={prod.image}
						>
							<section className="image" />
							<section className="content">
								<header>{prod.name}</header>
								<p className="number">{prod.identifier}</p>
								<p className="price">{prod.price}</p>
								{shoppingList.some((p) => p.identifier === prod.identifier) ? (
									<div>
										<CheckIcon thin={true} color={theme.colors.BLACK} />
									</div>
								) : (
									<div onClick={() => addToReviewList(prod)}>
										<Plus width={20} height={20} />
									</div>
								)}
							</section>
						</SearchResult>
					))}
				</SearchResults>
			) : (
				<EmptyResults>{t('reviewSearch.noResults', 'No results')}</EmptyResults>
			)}
			<footer>
				<section>
					<Button
						desktop={desktop}
						color={desktop ? 'white' : theme.colors.GREY}
						empty
						onClick={() => history.push('/review')}
					>
						<Trans i18nKey="reviewSearch.returnToShoppingList">Return to shopping list</Trans>
					</Button>
				</section>
			</footer>
		</Wrapper>
	);
}
