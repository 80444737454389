import React from 'react';

class TestbuyDisplaySampleBlue extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 55.1 55.075"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M55.1,11.7h0a.769.769,0,0,0-.2-.3l-.1-.1c-.1-.1-.2-.1-.3-.2L27.9.1a1.1,1.1,0,0,0-.8,0L.6,11.1h0c-.1,0-.2.1-.3.2l-.1.1-.2.2v.1H0c0,.1,0,.2,0,.4H0v31a.875.875,0,0,0,.6.9L27.2,55h.1c.1,0,.2.1.3.1h0A.6.6,0,0,0,28,55L54.5,44a.961.961,0,0,0,.6-.9V11.7ZM27.6,2.1l24,10-24,10-24-10ZM2,13.6,26.6,23.8V52.6L2,42.4Zm26.6,39V23.8L53.2,13.6V42.4Z"
          transform="translate(0 -0.025)"
        />
      </svg>
    );
  }
}

TestbuyDisplaySampleBlue.defaultProps = {
  width: 50,
  height: 50,
  color: '#43bdf3',
};

export default TestbuyDisplaySampleBlue;
