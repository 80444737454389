import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import Background from '../../assets/images/Fixed.png';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.article`
  padding: 20px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  footer {
    display: flex;
    flex-direction: column;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
  }

  header {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`;

const Image = styled.div`
  background-image: url(${Background});
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  margin-top: 40px;
`;

function CheeringScreen({ history }) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <section>
        <Image />
      </section>
      <header>
        {t('cheeringScreen.done', { defaultValue: "You've finished all test buys for your shopping list!" })}
      </header>
      <footer>
        <Button onClick={() => history.push('/home')}>
          {t('cheeringScreen.return', { defaultValue: 'Return to home' })}
        </Button>
      </footer>
    </Wrapper>
  );
}

export default CheeringScreen;
