import React, { useEffect } from 'react';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import { PageWrapper } from '../../components/PageWrapper';
import LocationIntake from '../../components/icons/LocationIntake';
import { css } from 'styled-components';
import { getProducts } from '../../store/Products/products.reducer';
import learnPracticeActions from '../../store/Learn/learn.action';
// import { FormattedMessage } from '../../locize';
import { useTranslation } from 'react-i18next';
import questionActions from '../../store/Questions/questions.action';

const Wrapper = styled(PageWrapper)`
	padding: ${props => (props.desktop ? '60px 100px 120px 100px' : '60px 35px 125px 35px')};
	height: ${props => (props.desktop ? 'calc(100% - 100px)' : 'auto')};
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: auto;
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
	width: ${props => (props.desktop ? '100%' : 'auto')};

	${props =>
		props.desktop &&
		css`
			text-align: center;
		`}

	${props =>
		!props.desktop &&
		css`
			min-height: calc(100% - 60px);
		`};

	${props =>
		props.small &&
		css`
			padding-bottom: 80px;
		`};

	section {
		justify-content: center;
		display: flex;
		position: relative;

		${props =>
			!props.desktop &&
			css`
				min-height: 100px;
			`};
	}

	h2 {
		font-size: 36px;
		font-weight: bold;
	}

	p {
		font-size: 18px;
		line-height: 30px;
		font-weight: 300;
		margin-top: 30px;
		margin-bottom: 30px;
	}
`;

function Open({ desktop, products, getCountries, getLearnPractice, history, getQuestions }) {
	const useMountEffect = fct => useEffect(fct, []);

	useMountEffect(() => {
		getLearnPractice();
		getQuestions();
	});

	const { t } = useTranslation();

	return (
		<Wrapper small={window.innerWidth < 370} desktop={desktop}>
			<section>
				<LocationIntake />
			</section>
			<article>
				<h2>{t('open.welcome', { defaultValue: 'Hej' })}</h2>
				<p>{t('open.header1', { defaultValue: 'Welcome to the IKEA Test buy app.' })}</p>
				<p>{t('open.header2', { defaultValue: 'Before you start, please choose your country and store.' })}</p>
			</article>
			<footer>
				<Button block notWide={desktop} onClick={() => history.push('/select-country')}>
					{t('open.chooseCountry', { defaultValue: 'Choose country' })}
				</Button>
			</footer>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	products: getProducts(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getLearnPractice: () => learnPracticeActions.getLearnPractice(),
			getQuestions: () => questionActions.fetchQuestions(),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Open));
