import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import ShoppingBasket from '../../components/icons/ShoppingBasket';
import Card from '../../components/Card';
import HiddenCard from '../../components/HiddenCard';
import { withRouter } from 'react-router';
import Header from '../../components/Header/Header';
import { getQuestions } from '../../store/Questions/questions.reducer';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.article`
  padding: ${props => (props.desktop ? '20px 0px' : '15px')};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: ${props => (props.desktop ? '100%' : 'calc(100% - 60px)')};
  background-color: ${props => props.theme.colors.GREY};

  footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
`;

function QuestionIntro({ desktop, questions, history }) {
  const id = questions[0].id;
  const { t } = useTranslation();

  return (
    <>
      {!desktop && <Header previousUrl={'/shopping-list'} />}
      <Wrapper desktop={desktop}>
        <Card stretch desktop={desktop}>
          <Card.Icon>
            <ShoppingBasket width={'60'} height={'60'} />
          </Card.Icon>
          <Card.Header>
            {t('questionIntro.header1', {
              defaultValue: 'Please look for the display sample, then answer the test buy questions.',
            })}
          </Card.Header>
          <Card.Header>
            {t('questionIntro.header2', {
              defaultValue: "Always think from a customer's point of view when doing the test buy.",
            })}
          </Card.Header>
          <footer>
            <Button desktop={desktop} onClick={() => history.push(`/question/${id}`)}>
              {t('next', { defaultValue: 'Next' })}
            </Button>
          </footer>
        </Card>
        {!desktop && (
          <>
            <HiddenCard />
            <HiddenCard last stretch />
          </>
        )}
      </Wrapper>
    </>
  );
}
const mapStateToProps = state => ({
  questions: getQuestions(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(QuestionIntro)
);
