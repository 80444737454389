import React from 'react';

class TestbuyBuyingInstructionsGreen extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 69.3 53.2"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M44.1,53.2H27.7a.875.875,0,0,1-.9-.6.913.913,0,0,1,.2-1.1L43.7,33.8H1a.945.945,0,0,1-1-1V20.1a.945.945,0,0,1,1-1H43.7L27.9,1.7A1.09,1.09,0,0,1,27.7.6a.875.875,0,0,1,.9-.6H44.1a.908.908,0,0,1,.7.3L69,25.9a.967.967,0,0,1,0,1.4L44.8,52.8A1.012,1.012,0,0,1,44.1,53.2ZM30,51.2H43.7L66.9,26.6,43.7,2H30.9L46.8,19.4a1.09,1.09,0,0,1,.2,1.1.961.961,0,0,1-.9.6H2V31.7H46a.875.875,0,0,1,.9.6.913.913,0,0,1-.2,1.1Z"
        />
      </svg>
    );
  }
}

TestbuyBuyingInstructionsGreen.defaultProps = {
  width: 50,
  height: 50,
  color: '#6eb31f',
};

export default TestbuyBuyingInstructionsGreen;
