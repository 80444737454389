import React from 'react';
import styled from 'styled-components';
import CheckMarkCompletedState from './icons/CheckMarkCompletedState';
import { useTranslation } from 'react-i18next';

const Container = styled.li`
	padding: ${props => (props.desktop ? '40px 50px' : '20px')};
	background-color: white;
	box-shadow: ${props =>
		props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)'};
	border-left: 5px solid ${props => props.color};
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0;

	section {
		display: flex;
		flex-direction: column;
		width: 80%;

		h2 {
			font-weight: bold;
			line-height: ${props => (props.desktop ? '24px' : '19px')};
			font-size: ${props => (props.desktop ? '20px' : '16px')};
		}

		h3 {
			height: 45px;
			line-height: ${props => (props.desktop ? '30px' : '21px')};
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: ${props => (props.desktop ? '18px' : '14px')};
		}
	}
`;

function Mistake({ type, description, color, onClickMistake, checked, desktop, id }) {
	const { t } = useTranslation();
	const mistakeType =
		type !== 'availability'
			? type
			: id === 'availability' || type === 'avalability'
			? t('shoppingList.check', 'Check availability')
			: t('notSpecified', 'Not specified');
	return (
		<Container desktop={desktop} color={color} onClick={onClickMistake}>
			<section>
				<h2>{mistakeType}</h2>
				<h3>{description}</h3>
			</section>
			{checked && (
				<CheckMarkCompletedState color={color} width={desktop ? '25' : '18'} height={desktop ? '25' : '18'} />
			)}
		</Container>
	);
}

export default Mistake;
