import React from 'react';

class LocationIntake extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 66.2 93.2"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M65.6 42.3l-22-9.3-.8 1.8 19.8 8.4-29.5 12.4L3.6 43.2l19.8-8.4-.8-1.8-22 9.3c-.4.1-.6.5-.6.9v35.5c0 .4.2.8.6.9l32.1 13.5c.1.1.3.1.4.1s.3 0 .4-.1l32.1-13.5c.4-.2.6-.5.6-.9V43.2c0-.4-.2-.8-.6-.9zM2 44.7l30.1 12.7v33.4L2 78.1V44.7zm32.1 46V57.4l30.1-12.7v33.4L34.1 90.7z"/>
        <path d="M32.2 43.5c.1.4.5.7 1 .7.4 0 .8-.3 1-.7 1.7-5.6 4.9-9.2 8-12.6 3.7-4.1 7.2-8 7.2-14.7C49 7.4 41.9.3 33.1 0c-8.8.3-15.9 7.4-16.2 16.2 0 6.7 3.5 10.7 7.3 14.8 3.1 3.4 6.2 7 8 12.5zM33.1 2c7.7.3 14 6.5 14.2 14.2 0 5.9-3.1 9.4-6.7 13.4-2.6 2.9-5.5 6.1-7.5 10.7-2-4.5-4.8-7.8-7.5-10.6-3.6-4-6.8-7.5-6.8-13.4.4-7.8 6.6-14 14.3-14.3z"/>
        <path d="M33.1 22.2c3.4 0 6.2-2.7 6.2-6.1v-.2c-.1-3.3-2.8-5.8-6.1-5.8h-.4c-3.3.1-5.8 2.8-5.8 6.1v.2c.1 3.3 2.8 5.8 6.1 5.8zm-4.1-6c0-2.2 1.7-4 3.9-4.1h.4c2.2 0 4 1.7 4.1 3.9v.1c0 2.3-1.8 4.1-4.1 4.1-2.2 0-4-1.7-4.1-3.9v-.1z"/>
      </svg>
    );
  }
}

LocationIntake.defaultProps = {
  width: 100,
  height: 100,
  color: 'black',
};

export default LocationIntake;
