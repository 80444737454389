export const APP_INIT = 'APP_INIT';
export const APP_SET_INITIALIZED = 'APP_SET_INITIALIZED';
export const APP_ADD_LOADER = 'APP_ADD_LOADER';
export const APP_REMOVE_LOADER = 'APP_REMOVE_LOADER';
export const APP_RESET = 'APP_RESET';
export const APP_SET_COUNTRY = 'APP_SET_COUNTRY';
export const APP_SET_TEMPORARY_COUNTRY = 'APP_SET_TEMPORARY_COUNTRY';
export const APP_SET_STORE = 'APP_SET_STORE';
export const APP_SET_INTRO_DONE = 'APP_SET_INTRO_DONE';
export const APP_GET_COUNTRIES = 'APP_GET_COUNTRIES';
export const APP_SET_COUNTRIES = 'APP_SET_COUNTRIES';
export const APP_RESET_STORE_COUNTRY = 'APP_RESET_STORE_COUNTRY';
export const APP_RESET_TEMPORARY_COUNTRY = 'APP_RESET_TEMPORARY_COUNTRY';
export const APP_SET_TESTBUY_INTRO_DONE = 'APP_SET_TESTBUY_INTRO_DONE';
export const APP_SET_AREA = 'APP_SET_AREA';
export const APP_GET_AREAS = 'APP_GET_AREAS';
export const APP_SET_PREFETCH_DATA = 'APP_SET_PREFETCH_DATA';
export const APP_GET_PREFETCH_DATA = 'APP_GET_PREFETCH_DATA';
export const APP_REPLACE_PREFETCH_DATA = 'APP_REPLACE_PREFETCH_DATA';
export const APP_GET_WEEKLY_STATS_DATA = 'APP_GET_WEEKLY_STATS_DATA';
export const APP_SET_WEEKLY_STATS = 'APP_SET_WEEKLY_STATS';
export const APP_SET_LANGUAGE = 'APP_SET_LANGUAGE';

const actions = {
	appSetLanguage(language) {
		return {
			type: APP_SET_LANGUAGE,
			language,
		};
	},
	addLoader(key) {
		return {
			type: APP_ADD_LOADER,
			key,
		};
	},
	removeLoader(key) {
		return {
			type: APP_REMOVE_LOADER,
			key,
		};
	},
	appInit() {
		return {
			type: APP_INIT,
		};
	},
	appReset() {
		return {
			type: APP_RESET,
		};
	},
	setAppStatusInitialized(languages) {
		return {
			type: APP_SET_INITIALIZED,
			languages,
		};
	},
	appSetCountry(country) {
		return {
			type: APP_SET_COUNTRY,
			country,
		};
	},
	appSetTemporaryCountry(temporaryCountry) {
		return {
			type: APP_SET_TEMPORARY_COUNTRY,
			temporaryCountry,
		};
	},
	appSetStore(store) {
		return {
			type: APP_SET_STORE,
			store,
		};
	},
	appResetStoreCountry() {
		return {
			type: APP_RESET_STORE_COUNTRY,
		};
	},
	appResetTemporaryCountry() {
		return {
			type: APP_RESET_TEMPORARY_COUNTRY,
		};
	},
	appSetIntroDone() {
		return {
			type: APP_SET_INTRO_DONE,
		};
	},
	appSetTestBuyIntroDone() {
		return {
			type: APP_SET_TESTBUY_INTRO_DONE,
		};
	},
	appGetCountries() {
		return {
			type: APP_GET_COUNTRIES,
		};
	},
	appSetCountries(countries) {
		return {
			type: APP_SET_COUNTRIES,
			countries,
		};
	},
	appGetAreas() {
		return {
			type: APP_GET_AREAS,
		};
	},
	appSetArea(area) {
		return {
			type: APP_SET_AREA,
			area,
		};
	},
	appGetPrefetchData() {
		return {
			type: APP_GET_PREFETCH_DATA,
		};
	},
	appSetPrefetchData(data) {
		return {
			type: APP_SET_PREFETCH_DATA,
			data,
		};
	},
	replaceItem(products) {
		return {
			type: APP_REPLACE_PREFETCH_DATA,
			products,
		};
	},
	appGetWeeklyStats(week, year) {
		return {
			type: APP_GET_WEEKLY_STATS_DATA,
			week,
			year,
		};
	},
	appSetWeeklyStats(stats) {
		return {
			type: APP_SET_WEEKLY_STATS,
			stats,
		};
	},
};

export default actions;
