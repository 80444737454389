import React from 'react';
import styled from 'styled-components';

const Card = styled.article`
  background-color: white;
  padding: 2px;
  margin: 1px 5px;
  z-index: 1;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.16);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  margin: ${props => (props.last ? '1px 10px' : '1px 5px')};
  margin-bottom: ${props => (props.stretch ? '90px' : '0px')};
`;

export default class HiddenCard extends React.PureComponent {

  render() {
    const { last, stretch } = this.props;

    return (
      <Card last={last} stretch={stretch} />
    );
  }
}