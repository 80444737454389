import React from 'react';
import styled from 'styled-components';
import {css} from "styled-components";

const ModalBody = styled.article`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: ${props => props.desktop ? '80px' : '45px 25px'};
  margin: 20px;
  position: relative;
  
  ${props =>
  props.camera &&
  css`
    width: ${props => props.desktop ? '350px' : '300px'};
    height: ${props => props.desktop ? '350px' : '300px'};
    background-color: white;
    display: flex;
    padding: ${props => props.desktop ? '70px 40px 50px' : '70px 25px 30px'};
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  `};

  header {
    align-self: flex-start;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }

  button {
    margin-top: 20px;
    width: 300px;
    max-width: 300px;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

class Modal extends React.PureComponent {
  static Body = ModalBody;
  body = undefined;

  componentDidMount() {
    this.body = document.querySelector('body');
    this.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    this.body.style.overflow = 'auto';
  }

  render() {
    const { children } = this.props;

    return <ModalWrapper>{children}</ModalWrapper>;
  }
}

export default Modal;
