import React from 'react';
import styled from 'styled-components';
import CameraIcon from '../../components/icons/Camera';
import BackArrow from '../../components/icons/BackArrow';
import Close from '../../components/icons/Close';
import RotateScreen from '../../components/icons/RotateScreen';
import Modal from '../../components/Modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/Products/products.action';
import { getPhoto } from '../../store/Camera/camera.reducer';
import Button from '../../components/Form/Button/Button';
import { Trans } from 'react-i18next';

const Wrapper = styled.div`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	height: 100%;
	background-color: #c7c7c7;

	canvas {
		display: none;
	}
`;

const CameraButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: white;
	width: ${window.innerWidth > 800 ? '120px' : '80px'};
	height: ${window.innerWidth > 800 ? '120px' : '80px'};
	border: none;
	position: absolute;
	top: 50%;
	margin-top: -37px;
	right: 50px;
	@media screen and (orientation: portrait) {
		transform: rotate(90deg);
		top: calc(100% - 75px);
		left: 50%;
		margin-left: -37px;
	}
`;

const BackButton = styled.button`
	display: flex;
	background: transparent;
	border: none;
	position: absolute;
	top: 15px;
	left: 10px;
	height: 50px;
	width: 50px;
	z-index: 1;
	@media screen and (orientation: portrait) {
		transform: rotate(90deg);
		left: calc(100% - 50px);
	}
`;

const IconContainer = styled.section`
	position: absolute;
	right: 20px;
	top: 20px;
`;

class Camera extends React.PureComponent {
	state = {
		showModal: true,
		noCamera: false,
	};

	prevent(e) {
		e.preventDefault();
	}

	componentDidMount() {
		document.addEventListener('touchmove', this.prevent, { passive: false });
		this.player = document.getElementById('player');
		this.canvas = document.getElementById('canvas');
		this.context = this.canvas.getContext('2d');

		const root = document.getElementById('root');
		const landscapeConstraints = {
			video: {
				facingMode: 'environment',
				width: { min: 720, ideal: 1280, max: 1920 },
				height: { min: 405, ideal: 720, max: 1080 },
				zoom: false,
			},
		};

		root.style.overflow = 'hidden';

		if ('mediaDevices' in navigator) {
			navigator.mediaDevices
				.getUserMedia(landscapeConstraints)
				.then(stream => {
					this.player.srcObject = stream;
					this.player.play();
				})
				.catch(e => console.log(e));
		} else {
			this.setState({ noCamera: true });
			document.removeEventListener('touchmove', this.prevent, { passive: false });
		}
	}

	captureImage = category => {
		const { productId, categoryType, questionId } = this.props.match.params;
		if (!this.state.noCamera) {
			this.context = this.canvas.getContext('2d');
			this.context.drawImage(this.player, 0, 0, this.canvas.width, this.canvas.height);
			const dataUrl = this.canvas.toDataURL('image/png');
			this.props.setMistakePhoto(dataUrl, productId, categoryType);
			const tracks = this.player && this.player.srcObject && this.player.srcObject.getTracks();
			tracks && tracks[0].stop();
			document.removeEventListener('touchmove', this.prevent, { passive: false });
		} else {
			this.props.setMistakePhoto('', productId, categoryType);
		}
		const root = document.getElementById('root');
		root.style.overflow = 'auto';
		this.props.history.push(`/comment/${productId}/${categoryType}/${questionId}`);
	};

	render() {
		const { history, match } = this.props;
		const { productId, categoryType, questionId } = this.props.match.params;
		return (
			<Wrapper>
				{!this.state.noCamera && (
					<BackButton onClick={() => history.push(`/comment/${productId}/${categoryType}/${questionId}`)}>
						<BackArrow color={'white'} />
					</BackButton>
				)}
				{this.state.showModal && !this.state.noCamera && (
					<Modal>
						<Modal.Body desktop={window.innerWidth > 800} camera>
							<IconContainer onClick={() => this.setState({ showModal: false })}>
								<Close width={'20'} height={'20'} color={'black'} />
							</IconContainer>
							<header>
								<Trans i18nKey="camera.modalHeader1">Please make sure the device is in landscape mode.</Trans>
							</header>
							<RotateScreen
								width={window.innerWidth > 800 ? '150' : '120'}
								height={window.innerWidth > 800 ? '90' : '60'}
								color={'black'}
							/>
						</Modal.Body>
					</Modal>
				)}
				{this.state.showModal && this.state.noCamera && (
					<Modal>
						<Modal.Body desktop={window.innerWidth > 800}>
							<header>
								<Trans i18nKey="camera.modalHeader2">
									Your device does not allow access to camera, please write down a description of the mistake.
								</Trans>
							</header>
							<Button style={{ width: '90%' }} onClick={() => this.captureImage(match.params.category)}>
								<Trans i18nKey="go">Go</Trans>
							</Button>
						</Modal.Body>
					</Modal>
				)}
				{!this.state.noCamera && (
					<>
						<video style={{ width: '100vw', height: '100%' }} id="player" playsInline muted autoPlay />
						<CameraButton id="capture" onClick={() => this.captureImage(match.params.category)}>
							<CameraIcon
								width={window.innerWidth > 800 ? '60' : '40'}
								height={window.innerWidth > 800 ? '60' : '40'}
								color={'black'}
							/>
						</CameraButton>
						<canvas id="canvas" width="1280" height="720" />
					</>
				)}
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	photo: getPhoto(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setMistakePhoto: (photo, productId, categoryType) => actions.setMistakePhoto(photo, productId, categoryType),
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Camera);
