import {
	REVIEW_GET_SHOPPING_LIST_FAILED,
	REVIEW_GET_SHOPPING_LIST_STARTED,
	REVIEW_ADD_PRODUCT,
	REVIEW_REMOVE_PRODUCT,
	REVIEW_RESET_SHOPPING_LIST,
	SET_SEARCH_RESULTS,
	SET_SEARCHING,
} from './review.action';

const initialState = {
	shoppingList: [],
	isLoadingProducts: false,
	searchResults: [],
	isSearching: false,
};

const addProduct = (shoppingList, { product }) => {
	const updatedList = shoppingList;
	updatedList.push(product);

	return updatedList;
};

const removeProduct = (shoppingList, { index }) => {
	const updatedList = shoppingList;
	updatedList.splice(index, 1);

	return updatedList;
};

const reviewReducer = (state = initialState, action) => {
	switch (action.type) {
		case REVIEW_GET_SHOPPING_LIST_STARTED:
			return {
				...state,
				isLoadingProducts: true,
			};
		case REVIEW_GET_SHOPPING_LIST_FAILED:
			return {
				...state,
				isLoadingProducts: false,
			};
		case REVIEW_ADD_PRODUCT:
			return {
				...state,
				shoppingList: addProduct(state.shoppingList, action),
			};
		case REVIEW_REMOVE_PRODUCT:
			return {
				...state,
				shoppingList: removeProduct(state.shoppingList, action),
			};
		case REVIEW_RESET_SHOPPING_LIST:
			return {
				...state,
				shoppingList: [],
				searchResults: [],
			};
		case SET_SEARCH_RESULTS:
			return {
				...state,
				searchResults: action.results,
			};
		case SET_SEARCHING:
			return {
				...state,
				isSearching: action.isSearching,
			};
		default:
			return state;
	}
};

export default reviewReducer;

export function getShoppingList(state) {
	return state.shoppingList;
}
