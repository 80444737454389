import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getQuestions, getCategories } from '../../store/Questions/questions.reducer';
import CategoryIcon from '../../components/CategoryIcon';
import CheckMark from '../../components/icons/CheckMarkTestbuyQuestions';
import { getColor } from '../../utils/util';
import Button from '../../components/Form/Button/Button';
import Modal from '../../components/Modal';
import CheeringPuppet from '../../components/icons/CheeringPuppet';
import Card from '../../components/Card';
import Header from '../../components/Header/Header';
import { bindActionCreators } from 'redux';
import actions from '../../store/Questions/questions.action';
import productActions from '../../store/Products/products.action';
import { getProduct } from '../../store/Products/products.reducer';
import eventsAction from '../../store/Events/events.action';
import CrossTestbuyQuestions from '../../components/icons/CrossTestbuyQuestions';
import appActions from '../../store/App/app.action';
import getYear from 'date-fns/get_year';
import getISOWeek from 'date-fns/get_iso_week';
import { useTranslation, Trans } from 'react-i18next';

const Wrapper = styled.article`
	padding: 15px;
	flex: 1;
	flex-direction: column;
	background-color: ${props => props.theme.colors.GREY};
	overflow: auto;
	height: ${props => (props.desktop ? '100%' : 'calc(100% - 60px);')};
	display: flex;
`;

const CategoryWrapper = styled.article`
	display: block;
	margin-bottom: 15px;

	.CategoryIcon {
		margin-right: 30px;
	}
	.CategoryCheckMark {
		margin-left: auto;
	}
	.CategoryButtons {
		display: flex;
		width: 100%;
		margin-top: 8px;
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 1;
			height: 45px;
			padding: 0;
			font-size: 14px;
			&:first-child {
				margin-right: 5px;
			}
		}
	}
`;

const CategoryContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	flex: 1;
	background: white;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
	padding: 10px 30px;
	font-weight: bold;
	color: black;
	min-height: ${props => (props.desktop ? '132px' : '73px')};
`;

const CardHeader = styled.header`
	font-size: 24px;
	line-height: 30px;
	font-weight: bold;
	text-align: center;
	padding: 30px 15px;
`;

function Category({ category, desktop, markSolved, report }) {
	const { t } = useTranslation();

	return (
		<CategoryWrapper>
			<CategoryContainer desktop={desktop}>
				<div className="CategoryIcon">
					<CategoryIcon category={category.type} height="35" width="35" />
				</div>
				{category.display_name}
				{(!category.hasMistakes || category.mistakeMarked) && (
					<div className="CategoryCheckMark">
						<CheckMark width={'18'} height={'18'} color={getColor(category.type)} />
					</div>
				)}
				{category.hasMistakes && !category.mistakeMarked && (
					<div className="CategoryCheckMark">
						<CrossTestbuyQuestions width={'15'} height={'15'} color={getColor(category.type)} />
					</div>
				)}
			</CategoryContainer>
			{category.hasMistakes && !category.mistakeMarked && (
				<div className="CategoryButtons">
					<Button style={{ height: desktop ? '65px' : '45px' }} color={getColor(category.type)} onClick={markSolved}>
						{t('solved', { defaultValue: 'Solved' })}
					</Button>
					<Button style={{ height: desktop ? '65px' : '45px' }} color={getColor(category.type)} onClick={report}>
						{t('report', { defaultValue: 'Report' })}
					</Button>
				</div>
			)}
		</CategoryWrapper>
	);
}

class QuestionSummary extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			showModal: props.categories
				.filter(c => c.hasMistakes)
				.filter(cat => cat.mistakeMarked)
				.reduce(() => false, true),
		};
	}

	state = {};

	componentDidMount() {
		this.props.markSummaryReached(this.props.product);
	}

	markProductDone = () => {
		const { markProductDone, product, eventsMarkMistakeAndComplete, getWeeklyStats } = this.props;
		const year = getYear(new Date());
		const week = getISOWeek(new Date());

		const mistakes = this.props.categories && this.props.categories.some(cat => cat.hasMistakes && !cat.mistakeMarked);

		if (!product.checkSelfService && !mistakes) {
			eventsMarkMistakeAndComplete({
				product_identifier: product.identifier,
				area: product.area,
				price: product.price,
				mistakes: product.mistakeMarked
					? product.mistakeMarked.map(mistake => {
							const data = {};
							if (mistake.photo) data.image = mistake.photo;
							if (mistake.comment) data.comment = mistake.comment;
							if (mistake.question) data.question = mistake.question;
							return {
								product_identifier: product.identifier,
								location: 'unknown',
								...data,
							};
					  })
					: [],
			});
			markProductDone(product);
			getWeeklyStats(week, year);
		} else if (product.checkSelfService && !mistakes) {
			markProductDone(product);
			getWeeklyStats(week, year);
		}
	};

	nextProduct = () => {
		this.markProductDone();
		this.props.history.push('/shopping-list');
	};

	render() {
		const { desktop, questions, categories, markCategorySolved, history, product, fireEvent } = this.props;
		const lastQuestionUrl = questions.map(q => q.id)[questions.length - 1];
		const mistakes = categories && categories.some(cat => cat.hasMistakes && !cat.mistakeMarked);

		return (
			<>
				{!desktop && <Header previousUrl={`/question/${lastQuestionUrl}`} />}
				<Wrapper desktop={desktop} className="QuestionSummary">
					{mistakes &&
						categories.map((category, key) => (
							<Category
								key={key}
								category={category}
								desktop={desktop}
								fireEvent={fireEvent}
								product={product}
								markSolved={() => markCategorySolved(category.type, product.identifier)}
								report={() => history.push(`/comment/${product.identifier}/${category.type}/${category.questionId}`)}
							/>
						))}
					{!mistakes && (
						<Card desktop={desktop} spaced stretch>
							<section>
								<Card.Icon>
									<CheeringPuppet />
								</Card.Icon>
								<CardHeader>
									<Trans i18nKey="questionSummary.header">You are great!</Trans>
								</CardHeader>
							</section>
							<Button desktop={desktop} onClick={() => this.nextProduct()}>
								<Trans i18nKey="questionSummary.nextProduct">Next product</Trans>
							</Button>
						</Card>
					)}
					{this.state.showModal && mistakes && (
						<Modal>
							<Modal.Body desktop={desktop}>
								<header>
									<Trans i18nKey="questionSummary.modalHeader1">
										You've answered all the test buy questions for this product. Please look at the overview.
									</Trans>
								</header>
								<br />
								<header>
									<Trans i18nKey="questionSummary.modalHeader2">
										If you can solve a mistake within 1 minute, please do so. If not, you can report the mistake.
									</Trans>
								</header>
								<Button desktop={desktop} onClick={() => this.setState({ showModal: false })}>
									<Trans i18nKey="letsGo">Let's go!</Trans>
								</Button>
							</Modal.Body>
						</Modal>
					)}
				</Wrapper>
			</>
		);
	}
}

const mapStateToProps = state => ({
	questions: getQuestions(state),
	categories: getCategories(state),
	product: getProduct(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			markCategorySolved: (category, productId) => actions.markCategorySolved(category, productId),
			markProductDone: product => productActions.markProductDone(product),
			eventsMarkMistakeAndComplete: product => eventsAction.eventsMarkMistakeAndComplete(product),
			getWeeklyStats: (week, year) => appActions.appGetWeeklyStats(week, year),
			markSummaryReached: product => productActions.markSummaryReached(product),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionSummary));
