import { MISTAKES_SET, MISTAKES_FETCH_START, MISTAKES_FETCH_SUCCESS, MISTAKES_FETCH_FAILED } from './mistakes.action';

const initialState = {
	mistakes: { fixed: { results: [] }, notFixed: { results: [] } },
	fetchingMistakes: false,
};

const setMistakes = ({ mistakes, products, questions, categories }) => {
	return {
		fixed: {
			results: mistakes.fixed.results.map(m => {
				m.mistakes = m.mistakes.map(mistake => {
					mistake.q = questions
						.filter(q => q.id === parseInt(mistake.question, 10))
						.map(q => {
							q.display_category = categories
								.filter(c => c.type === q.category)
								.reduce((memo, cat) => cat.display_name, '');
							return q;
						})
						.reduce((memo, question) => question, {});
					return mistake;
				});
				return m;
			}),
		},
		notFixed: {
			results: mistakes.notFixed.results.map(m => {
				m.mistakes = m.mistakes.map(mistake => {
					mistake.q = questions
						.filter(q => q.id === parseInt(mistake.question, 10))
						.map(q => {
							q.display_category = categories
								.filter(c => c.type === q.category)
								.reduce((memo, cat) => cat.display_name, '');
							return q;
						})
						.reduce((memo, question) => question, {});
					return mistake;
				});
				return m;
			}),
		},
	};
};

const mistakesReducer = (state = initialState, action) => {
	switch (action.type) {
		case MISTAKES_SET:
			return {
				...state,
				mistakes: setMistakes(action),
			};
		case MISTAKES_FETCH_START:
			return {
				...state,
				fetchingMistakes: true,
			};
		case MISTAKES_FETCH_SUCCESS:
			return {
				...state,
				fetchingMistakes: false,
			};
		case MISTAKES_FETCH_FAILED:
			return {
				...state,
				fetchingMistakes: false,
			};
		default:
			return state;
	}
};

export default mistakesReducer;

export function getMistakes(state) {
	return state.mistakes.mistakes;
}

export function getMistake(state, props) {
	return (
		state.mistakes.mistakes &&
		state.mistakes.mistakes.notFixed.results
			.filter(m => m.product.identifier === props.match.params.id)
			.reduce((memo, mistake) => mistake, {})
	);
}

export const getFetchingMistakes = state => state && state.mistakes && state.mistakes.fetchingMistakes;
