import React from 'react';
import { withRouter } from 'react-router';
import { getQuestions, getMoreInfos } from '../../store/Questions/questions.reducer';
import { connect } from 'react-redux';
import CategoryIcon from '../../components/CategoryIcon';
import Step from '../../components/Step';
import Button from '../../components/Form/Button/Button';
import { getColor } from '../../utils/util';
import Header from '../../components/Header/Header';
import { getMistake } from '../../store/Mistakes/mistakes.reducer';
import { Wrapper, InfoContent } from '../../components/MoreInfo/MoreInfoUIComponents.js';
import { useTranslation } from 'react-i18next';

function MoreInfo({ desktop, questions, match, history, moreInfos }) {
  const question = questions.filter(q => q.id === parseInt(match.params.id, 10)).reduce((memo, q) => q, {});
  const { title, text, steps } = moreInfos.filter(i => i.url === question.more_info).reduce((memo, info) => info, {});
  const { t } = useTranslation();
  return (
    <>
      {!desktop && <Header onClick={() => history.goBack()} />}
      <Wrapper desktop={desktop}>
        <article>
          <CategoryIcon category={question.category} width={'55'} height={'55'} />
        </article>
        <InfoContent desktop={desktop}>
          <h1>{title}</h1>
          <h2 dangerouslySetInnerHTML={{ __html: text }} />
          <ul>
            {steps &&
              steps.map((step, key) => (
                <Step desktop={desktop} key={key} title={step.title} description={step.text} id={key + 1} />
              ))}
          </ul>
        </InfoContent>
        {desktop && (
          <footer>
            <Button desktop={desktop} color={getColor(question.category)} onClick={() => history.goBack()}>
              {t('close', { defaultValue: 'Close' })}
            </Button>
          </footer>
        )}
      </Wrapper>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  questions: getQuestions(state),
  moreInfos: getMoreInfos(state),
  mistake: getMistake(state, ownProps),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MoreInfo)
);
