import React from 'react';
import styled from 'styled-components';
import CheckMarkCompletedState from '../../components/icons/CheckMarkCompletedState';
import Button from '../../components/Form/Button/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { getAreas } from '../../store/App/app.reducer';
import BackArrowDesktop from '../../components/icons/BackArrowDesktop';
import { getFilters, setFilters } from '../../store/Events/events.reducer';
import eventsAction from '../../store/Events/events.action';
import { Trans } from 'react-i18next';

const Wrapper = styled.article`
  width: 100%;
  height: ${props => (props.desktop ? 'auto' : 'calc(100% - 60px)')};
  margin: ${props => (props.desktop ? '20px 0px' : '0px')};
  background-color: ${props => (props.desktop ? 'white' : props.theme.colors.LIGHT_GREY)};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 30px;
  box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};

  header {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    margin-top: ${props => (props.desktop ? '20px' : '0px')};
  }

  form {
    display: flex;
    margin: 20px 0px;
    overflow: auto;

    li {
      font-size: 16px;
      line-height: 36px;
      display: flex;
      padding-bottom: 5px;
    }
  }

  footer {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    button:first-child {
      margin-bottom: 10px;
    }
  }
`;

const Checkbox = styled.section`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  margin-right: 20px;
  background-color: ${props => (props.checkedButton ? 'black' : 'transparent')};
  color: white;
  font-weight: bold;
  align-self: center;
`;

class Filter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filters: props.toggledFilters,
    };
  }

  onChange = option => {
    this.setState({ filters: setFilters(this.state.filters, option) });
  };

  getResults = () => {
    this.props.setFilters(this.state.filters);
    this.props.history.push('/store-stream');
  };

  resetFilters = () => {
    this.setState({ filters: [] });
    this.props.setFilters([]);
  };

  isChecked = name => this.state.filters.indexOf(name) !== -1;

  render() {
    const { areas, desktop, history } = this.props;
    return (
      <Wrapper desktop={desktop} className="Filter">
        {desktop && (
          <div>
            <BackArrowDesktop className="BackArrowDesktop" onClick={() => history.goBack()} />
          </div>
        )}
        <header>
          <Trans i18nKey="area">Area</Trans>
        </header>
        <form>
          <ul>
            {areas &&
              areas.map((option, key) => (
                <li key={key} onClick={() => this.onChange(option)}>
                  <Checkbox checkedButton={this.isChecked(option.name)}>
                    {this.isChecked(option.name) && (
                      <CheckMarkCompletedState width={'14'} height={'14'} color={'white'} />
                    )}
                  </Checkbox>
                  {option.name}
                </li>
              ))}
          </ul>
        </form>
        <footer>
          <Button onClick={() => this.getResults()}>
            <Trans i18nKey="go">Go!</Trans>
          </Button>
          <Button onClick={() => this.resetFilters()}>
            <Trans i18nKey="reset">Reset filters</Trans>
          </Button>
        </footer>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  areas: getAreas(state),
  toggledFilters: getFilters(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setFilters: filters => eventsAction.eventsSetFilter(filters),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Filter)
);
