import React from 'react';

class ArrowRight extends React.Component {
  render() {
    return (
      <svg
        className={this.props.className}
        viewBox="0 0 6.9 12"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        <path
          d="M6.7,5.2l-5-4.9c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L4.6,6l-4.3,4.3c-0.4,0.4-0.4,1,0,1.4C0.5,11.9,0.7,12,1,12
	s0.5-0.1,0.7-0.3l4.9-5C6.9,6.5,7,6.2,6.9,6C7,5.7,6.9,5.4,6.7,5.2z"
        />
      </svg>
    );
  }
}

ArrowRight.defaultProps = {
  width: 12,
  height: 12,
  color: '#000',
};

export default ArrowRight;
