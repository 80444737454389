import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Step from '../../components/Step';
import Button from '../../components/Form/Button/Button';
import { Wrapper, InfoContent } from '../../components/MoreInfo/MoreInfoUIComponents.js';
import { getFAQs } from '../../store/FAQ/faq.reducer';
import FAQIcon from '../../components/icons/FAQs';
import { useTranslation } from 'react-i18next';
import { appGetCountry, appGetLanguage } from '../../store/App/app.reducer';

function FAQ({ desktop, history, FAQs, country, language }) {
	const { t } = useTranslation();
	return (
		<>
			<Wrapper desktop={desktop}>
				<article>
					<FAQIcon width={'55'} height={'55'} />
				</article>
				<InfoContent desktop={desktop}>
					<ul style={{ paddingRight: desktop ? 30 : 0 }}>
						{FAQs.map((step, key) => (
							<Step
								noStepHeader
								desktop={desktop}
								key={key}
								title={t(`faqList.q${step.key}`, { defaultValue: step.question })}
								description={t(`faqList.a${step.key}`, { defaultValue: step.answer })}
								video={step.video}
								language={language}
								country={country}
								id={key + 1}
							/>
						))}
					</ul>
				</InfoContent>
				{desktop && (
					<footer>
						<Button desktop={desktop} onClick={() => history.goBack()}>
							{t('close', { defaultValue: 'Close' })}
						</Button>
					</footer>
				)}
			</Wrapper>
		</>
	);
}

const mapStateToProps = (state, ownProps) => ({
	FAQs: getFAQs(state),
	country: appGetCountry(state),
	language: appGetLanguage(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FAQ));
