import React from 'react';

class SearchMagnifyingGlass extends React.Component {
  render() {
    return (
      <svg
        {...this.props}
        viewBox="0 0 25.9 26.1"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M25.9,23.9l-4.5-4.5A11.883,11.883,0,0,0,24,12a12,12,0,1,0-4.8,9.6l4.5,4.5ZM2,12A10,10,0,1,1,12,22,10.029,10.029,0,0,1,2,12Z" />
      </svg>
    );
  }
}

SearchMagnifyingGlass.defaultProps = {
  width: 100,
  height: 100,
  color: '#a7a7a7',
};

export default SearchMagnifyingGlass;
