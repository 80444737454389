import React from 'react';
import { withRouter } from 'react-router-dom';
import IntroSlider from '../../components/Slider/IntroSlider';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Intro extends React.PureComponent {
	render() {
		const { desktop } = this.props;

		return (
			<IntroSlider
				desktop={desktop}
				goTo={() => this.props.history.push('/testbuy-intro')}
				video={{ title: 'Why Testbuy' }}
			/>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Intro));
