import React from 'react';

class CheckIcon extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 24 24"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}>
        {this.props.thin &&
          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
        }
        {this.props.bold &&
          <path
            d="M10 18c-.5 0-1-.2-1.4-.6l-4-4c-.8-.8-.8-2 0-2.8.8-.8 2.1-.8 2.8 0l2.6 2.6 6.6-6.6c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8l-8 8c-.4.4-.9.6-1.4.6z"/>
        }
      </svg>
    );
  }
}

CheckIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#a7a7a7',
  bold: false,
  thin: false
};

export default CheckIcon;
