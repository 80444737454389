import React from 'react';

class TestbuyGroupingPurple extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 66.066 64.575"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <g transform="translate(0 0.075)">
          <path
            d="M66,18.8h0a.769.769,0,0,0-.2-.3l-.1-.1c-.1-.1-.2-.1-.3-.2l-16.7-7a1.1,1.1,0,0,0-.8,0l-16.7,7h0c-.1,0-.2.1-.3.2l-.1.1-.2.2v.1h0c0,.1-.1.2-.1.3h0V30.2a1,1,0,1,0,2,0V20.6l14.7,6.1V44L43,42.2a.985.985,0,1,0-.8,1.8l5.6,2.4a.85.85,0,0,0,.8,0h0l16.7-7a.961.961,0,0,0,.6-.9V19.1C66.1,19,66.1,18.9,66,18.8ZM48.4,13.2l14.1,5.9L48.4,25,34.3,19.1Zm1,30.9V26.7l14.7-6.1V37.9Z"
          />
          <path
            d="M9.4,32a.875.875,0,0,0,.9-.6.992.992,0,0,0-.5-1.3L2,26.8V9.5l14.7,6.1V27.5a1,1,0,0,0,2,0V15.6L33.4,9.5v4.1a1,1,0,1,0,2,0V8h0a.367.367,0,0,0-.1-.3h0V7.6a.215.215,0,0,0-.2-.2L35,7.3c0-.1-.1-.2-.2-.3L18.1,0a1.1,1.1,0,0,0-.8,0L.6,7h0c-.1,0-.2.1-.3.2l-.1.1c0,.1,0,.1-.1.2v.1h0C0,7.7,0,7.8,0,8H0V27.5a.875.875,0,0,0,.6.9L9,31.9C9.1,31.9,9.2,32,9.4,32ZM17.7,2.1,31.8,8,17.7,13.9,3.6,8Z"
          />
          <path
            d="M39.5,36.8h0c-.1-.1-.1-.2-.2-.3l-.1-.1c-.1-.1-.2-.1-.3-.2l-16.7-7a1.1,1.1,0,0,0-.8,0l-16.7,7h0c-.1,0-.2.1-.3.2l-.1.1c-.1.1-.1.1-.1.2v.1h0c0,.1-.1.2-.1.3h0V56.6a.875.875,0,0,0,.6.9l16.7,7h.3a.6.6,0,0,0,.4-.1h0l16.7-7a.961.961,0,0,0,.6-.9V37.2c.2-.1.2-.2.1-.4ZM21.9,31.3,36,37.2,21.9,43.1,7.8,37.2ZM6.2,38.7l14.7,6.1V62.1l-14.7-6ZM22.9,62.2V44.9l14.7-6.1V56.1Z"
          />
        </g>
      </svg>
    );
  }
}

TestbuyGroupingPurple.defaultProps = {
  width: 50,
  height: 50,
  color: '#5618a4',
};

export default TestbuyGroupingPurple;
