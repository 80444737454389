import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import SagaManager from './sagas';

// MIDDLEWARES
export const history = createBrowserHistory();
const router = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware({
	onError: error => {
		console.error(error);
		if (process.env.NODE_ENV === 'development') {
			console.error(error);
		}
	},
});

const createReducer = reducer => {
	const persistConfig = {
		key: 'root',
		storage,
		whitelist: ['app', 'products', 'questions', 'review', 'events'], //'mistakes'
	};

	return persistReducer(persistConfig, reducer(history));
};

let middlewares = [sagaMiddleware, router];

if (process.env.NODE_ENV === `development`) {
	const { logger } = require(`redux-logger`);
	middlewares.push(logger);
}
// STORE
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
	createReducer(rootReducer), 
	composeEnhancer(
		applyMiddleware(...middlewares)
	)
)

// SAGAS
SagaManager.startSagas(sagaMiddleware);

export default () => {
	if (process.env.NODE_ENV === 'development') {
		if (module.hot) {
			module.hot.accept('./reducers', () => store.replaceReducer(createReducer(require('./reducers').default)));

			module.hot.accept('./sagas', () => {
				SagaManager.cancelSagas(store);
				require('./sagas').default.startSagas(sagaMiddleware);
			});
		}
	}

	const persistor = persistStore(store);
	return { store, persistor };
};
