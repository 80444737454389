import React from 'react';

class TestbuyPricetagPink extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 65.6 65.7"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M24.4,65.7a2.878,2.878,0,0,1-2.1-.9L.9,43.4a2.9,2.9,0,0,1,0-4.2L39.1.9a2.9,2.9,0,0,1,4.2,0L64.7,22.3a2.9,2.9,0,0,1,0,4.2L26.5,64.8A2.878,2.878,0,0,1,24.4,65.7ZM41.3,2a.908.908,0,0,0-.7.3L2.3,40.6a.967.967,0,0,0,0,1.4L23.7,63.4a.967.967,0,0,0,1.4,0L63.4,25.1a.967.967,0,0,0,0-1.4L42,2.3A1.08,1.08,0,0,0,41.3,2Z" />
        <g>
          <path d="M44.6,26.9a5.6,5.6,0,1,1,5.6-5.6A5.591,5.591,0,0,1,44.6,26.9Zm0-9.3a3.6,3.6,0,1,0,3.6,3.6A3.585,3.585,0,0,0,44.6,17.6Z" />
        </g>
      </svg>
    );
  }
}

TestbuyPricetagPink.defaultProps = {
  width: 50,
  height: 50,
  color: '#ee7a9e',
};

export default TestbuyPricetagPink;
