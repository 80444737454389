import { put, takeLatest, select, call } from 'redux-saga/effects';
import actions, { QUERY_SEARCH_ENDPOINT } from './review.action';
import { appGetStore, appGetCountry } from '../App/app.reducer';
import { getSearchResults } from '../../utils/api';

function* reviewGetSearchResults({ query }): Saga<void> {
	yield put(actions.setSearching(true));

	const country = yield select(appGetCountry);
	const store = yield select(appGetStore);

	const data = yield call(getSearchResults, {
		country: country.code,
		language: country.languages[0].code,
		store: store.identifier,
		identifier: query,
	});

	if (data) {
		yield put(actions.setSearchResults(data));
	}
	yield put(actions.setSearching(false));
}

export function* reviewGetSearchResultsListener(): Saga<void> {
	yield takeLatest(QUERY_SEARCH_ENDPOINT, reviewGetSearchResults);
}

export default [reviewGetSearchResultsListener];
