import React from 'react';
import styled from 'styled-components';
import ArrowRight from '../../components/icons/ArrowRight';
import BackArrow from '../../components/icons/BackArrow';
import Camera from '../../components/icons/Camera';
import CheckMarkCircle from '../../components/icons/CheckMarkCircle';
import CheckMarkCompletedState from '../../components/icons/CheckMarkCompletedState';
import CheckMarkTestbuyQuestions from '../../components/icons/CheckMarkTestbuyQuestions';
import CheeringPuppet from '../../components/icons/CheeringPuppet';
import Close from '../../components/icons/Close';
import CrossCircle from '../../components/icons/CrossCircle';
import DesktopCross from '../../components/icons/DesktopCross';
import Hamburger from '../../components/icons/Hamburger';
import MinusCollapse from '../../components/icons/MinusCollapse';
import PlusExpand from '../../components/icons/PlusExpand';
import RotateScreen from '../../components/icons/RotateScreen';
import SearchMagnifyingGlass from '../../components/icons/SearchMagnifyingGlass';
import ShoppingBasketCheckMark from '../../components/icons/ShoppingBasketCheckMark';
import ShoppingBasket from '../../components/icons/ShoppingBasket';
import TestbuyAvailabilityOrange from '../../components/icons/TestbuyAvailabilityOrange';
import TestbuyBuyingInstructionsGreen from '../../components/icons/TestbuyBuyingInstructionsGreen';
import TestbuyConditionYellow from '../../components/icons/TestbuyConditionYellow';
import TestbuyDisplaySampleBlue from '../../components/icons/TestbuyDisplaySampleBlue';
import TestbuyGroupingPurple from '../../components/icons/TestbuyGroupingPurple';
import TestbuyPricetagPink from '../../components/icons/TestbuyPricetagPink';
import Minus from '../../components/icons/Minus';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

class icons extends React.PureComponent {
  render() {
    return (
      <Wrapper>
        <ArrowRight />
        <BackArrow />
        <Camera />
        <CheckMarkCircle />
        <CheckMarkCompletedState />
        <CheckMarkTestbuyQuestions />
        <CheeringPuppet />
        <Close />
        <CrossCircle />
        <DesktopCross/>
        <Hamburger />
        <Minus />
        <MinusCollapse />
        <PlusExpand />
        <RotateScreen />
        <SearchMagnifyingGlass />
        <ShoppingBasketCheckMark />
        <ShoppingBasket />
        <TestbuyAvailabilityOrange />
        <TestbuyBuyingInstructionsGreen />
        <TestbuyConditionYellow />
        <TestbuyDisplaySampleBlue />
        <TestbuyGroupingPurple />
        <TestbuyPricetagPink />
      </Wrapper>
    );
  }
}

export default icons;
