// @flow
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { APP_LOADERS } from '../../constants';
import actions, {
	APP_INIT,
	APP_GET_COUNTRIES,
	APP_GET_PREFETCH_DATA,
	APP_GET_WEEKLY_STATS_DATA,
	APP_SET_LANGUAGE,
} from './app.action';
import type { Saga } from 'redux-saga';
import { getCountries, getPrefetchData, getWeeklyStats } from '../../utils/api';
import eventActions from '../Events/events.action';
import { appGetShoppingParams } from './app.reducer';
import { getToken } from '../Events/events.reducer';
import { shouldRefreshToken, eventsRefreshToken } from '../Events/events.saga';
import i18n from '../../i18n';
import languages from '../../languages';

i18n.on('loaded', () => {
	var body = document.getElementsByTagName('body')[0];
	body.setAttribute('dir', 'ltr');
	if (i18n.language === 'ar' || i18n.language === 'he') {
		body.setAttribute('dir', 'rtl');
	}
});

function* appInitWorker(): Saga<void> {
	yield put(actions.addLoader(APP_LOADERS.appInit));
	try {
		yield put(eventActions.eventsLogin());
		yield put(actions.setAppStatusInitialized(languages));

		yield put(actions.removeLoader(APP_LOADERS.appInit));
	} catch (e) {
		console.error(e);
	}
}

function* appGetCountries(): Saga<void> {
	const countries = yield call(getCountries);
	if (countries) {
		yield put(actions.appSetCountries(countries));
	}
}

function* appGetPrefetchData() {
	const params = yield select(appGetShoppingParams);
	const data = yield call(getPrefetchData, params);
	if (data) {
		yield put(actions.appSetPrefetchData(data));
	}
}

function* appGetWeeklyStats({ week, year }) {
	try {
		const shoppingParams = yield select(appGetShoppingParams);
		const params = week ? Object.assign({ week, year }, shoppingParams) : shoppingParams;
		let TOKEN = yield select(getToken);
		if (shouldRefreshToken(TOKEN)) {
			yield call(eventsRefreshToken);
			TOKEN = yield select(getToken);
		}
		const data = yield call(getWeeklyStats, params, {
			'Content-Type': 'application/json',
			Authorization: `JWT ${TOKEN}`,
		});
		if (data) {
			yield put(actions.appSetWeeklyStats(data));
		}
	} catch (e) {
		console.log('Fetch Stats Failed', e);
	}
}

function* appSetLanguage({ language }) {
	try {
		var body = document.getElementsByTagName('body')[0];
		body.setAttribute('dir', 'ltr');
		if (language.code === 'ar' || language.code === 'he') {
			body.setAttribute('dir', 'rtl');
		}
		yield call([i18n, i18n.changeLanguage], language.code);
	} catch (e) {
		console.log(e);
	}
}

export function* appInitListener(): Saga<void> {
	yield takeLatest(APP_INIT, appInitWorker);
}
export function* appGetCountriesListener(): Saga<void> {
	yield takeLatest(APP_GET_COUNTRIES, appGetCountries);
}
export function* appGetPrefetchDataListener(): Saga<void> {
	yield takeLatest(APP_GET_PREFETCH_DATA, appGetPrefetchData);
}
export function* appGetWeeklyStatsListener(): Saga<void> {
	yield takeLatest(APP_GET_WEEKLY_STATS_DATA, appGetWeeklyStats);
}
export function* appSetLanguageListener() {
	yield takeLatest(APP_SET_LANGUAGE, appSetLanguage);
}

export default [
	appInitListener,
	appGetCountriesListener,
	appGetPrefetchDataListener,
	appGetWeeklyStatsListener,
	appSetLanguageListener,
];
