export default [
	{ code: 'ar', name: 'العربية' },
	{ code: 'bg', name: 'българскиезик' },
	{ code: 'hr', name: 'Hrvatski' },
	{ code: 'cs', name: 'ČeskýJazyk, Čeština' },
	{ code: 'da', name: 'Dansk' },
	{ code: 'nl', name: 'Nederlands' },
	{ code: 'en', name: 'English' },
	{ code: 'et', name: 'Eesti' },
	{ code: 'fi', name: 'Suomi' },
	{ code: 'fr-ca', name: 'Français (CA)' },
	{ code: 'fr-fr', name: 'Français (Fr)' },
	{ code: 'de', name: 'Deutsch' },
	{ code: 'el', name: 'Ελληνικά' },
	{ code: 'he', name: 'עברית' },
	{ code: 'hu', name: 'Magyar' },
	{ code: 'is', name: 'Íslenska' },
	{ code: 'id', name: 'Bahasa Indonesia' },
	{ code: 'it', name: 'Italiano' },
	{ code: 'ja', name: '日本語' },
	{ code: 'ko', name: '한국어' },
	{ code: 'lv', name: 'Latviešu' },
	{ code: 'lt', name: 'Lietuvių' },
	{ code: 'ml', name: 'Bahasa Melayu' },
	{ code: 'nb', name: 'Norsk Bokmål' },
	{ code: 'pl', name: 'Polski' },
	{ code: 'pt', name: 'Português' },
	{ code: 'ro', name: 'Română' },
	{ code: 'ru', name: 'Русский' },
	{ code: 'sr', name: 'Српски, Srpski' },
	{ code: 'sk', name: 'Slovenčina' },
	{ code: 'sl', name: 'Slovenščina' },
	{ code: 'es', name: 'Español' },
	{ code: 'sv', name: 'Svenska' },
	{ code: 'th', name: 'ภาษาไทย' },
	{ code: 'zh-cn', name: '简体中文' },
	{ code: 'zh-hk', name: 'Chinese (HongKong)' },
	{ code: 'zh-tw', name: 'Chinese (Taiwan)' },
	{ code: 'tr', name: 'Türkçe' },
	{ code: 'uk', name: 'Українська' },
]
	.sort((a, b) => {
		if (a.code < b.code) return -1;
		if (a.code > b.code) return 1;
		return 0;
	})
	.concat(process.env.NODE_ENV === `development` ? [{ code: 'en-AU', name: '1s Language' }] : []);
