import React from 'react';
import RangeGrouping from './icons/LearnRangeGrouping';
import Merchandise from './icons/LearnMerchandise';
import AssessSolution from './icons/LearnAssessSolution';
import MaintainSolution from './icons/LearnMaintainSolution';

const TopicIcon = ({ topic, ...rest }) => {
  switch (topic) {
    case 950:
      return <RangeGrouping {...rest} />;
    case 1078:
      return <Merchandise {...rest} />;
    case 1079:
      return <AssessSolution {...rest} />;
    case 1084:
      return <MaintainSolution {...rest} />;
    default:
      return <RangeGrouping {...rest} />;
  }
};

export default TopicIcon;
