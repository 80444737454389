import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '../../components/Form/Button/Button';
import Modal from '../../components/Modal';
import { bindActionCreators } from 'redux';
import theme from '../../styles/1-settings/theme';
import { Trans } from 'react-i18next';
import reviewActions from '../../store/Review/review.action';
import productsActions from '../../store/Products/products.action';
import Minus from '../../components/icons/Minus';
import Plus from '../../components/icons/PlusExpand';
import { appGetCountry, appGetStore } from '../../store/App/app.reducer';
import eventsAction from '../../store/Events/events.action';
import DesktopCross from '../../components/icons/DesktopCross';
import CheckMark from '../../components/icons/CheckMarkTestbuyQuestions';

const Wrapper = styled.article`
	background-color: white;
	height: ${props => (props.desktop ? 'auto' : 'calc(100% - 60px);')};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-top: 1px solid #dedede;
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
	margin: ${props => (props.desktop ? 'auto' : '0px')};
	overflow: ${props => (props.scroll ? 'auto' : 'hidden')};
	width: 100%;

	.product-list {
		max-height: ${props => (props.desktop ? '525px' : 'auto')};
		overflow-y: ${props => (props.desktop ? 'auto' : 'hidden')};
	}

	footer {
		display: flex;
		flex-direction: ${props => (props.desktop ? 'row' : 'column')};
		background-color: ${props => (props.desktop ? 'white' : props.theme.colors.GREY)};
		padding: ${props => (props.desktop ? '20px 60px' : '20px')};
		justify-content: center;

		section {
			display: flex;
			flex-direction: column;
			margin: ${props => (props.desktop ? '10px' : '10px 0px')};
			width: ${props => (props.desktop ? '240px' : 'auto')};
		}
	}
`;

const ProductSlot = styled.div`
	padding: ${props => (props.desktop ? '30px 70px' : '20px')};
	height: ${props => (props.desktop ? '105px' : '100px')};
	border-bottom: 1px solid #dedede;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${props => (props.disabled ? '#eee' : 'white')};

	header {
		font-size: 18px;
		line-height: 30px;
		display: flex;
		align-items: center;
		overflow: hidden;

		span {
			&:first-of-type {
				margin-right: ${props => (props.index >= 10 ? '5px' : '15px')};
			}
		}
	}

	button {
		background: transparent;
		border: none;
		box-shadow: none;
	}
`;

const ProductInfo = styled.div`
	display: flex;
	width: calc(100% - 30px);
	.image {
		background-image: ${props => `url(${props.image})`};
		width: 75px;
		height: 75px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
		margin-right: 20px;
	}

	.content {
		width: calc(100% - 95px);
		position: relative;
		h1 {
			font-size: 18px;
			margin: 0;
			line-height: 22px;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		p {
			font-size: 16px;
			line-height: 24px;
			&.price {
				font-weight: bold;
			}
		}
	}
`;

class ShoppingList extends React.PureComponent {
	state = {
		showReviewDirections: !(this.props.shoppingList && this.props.shoppingList.length),
		showStartTestbuyModal: false,
	};

	closeReviewModal = () => {
		this.setState({ showReviewDirections: false });
	};

	removeFromReviewList = () => {
		this.props.removeProduct(this.state.productToRemove);
		this.setState({ showRemoveModal: false, productToRemove: '' });
	};

	startTestbuy = () => {
		const {
			history,
			setShoppingList,
			resetShoppingList,
			fireEventWithReviewId,
			country,
			store,
			eventsResetApp,
			shoppingList,
		} = this.props;
		setShoppingList(shoppingList);
		resetShoppingList();
		eventsResetApp();
		fireEventWithReviewId(
			{
				country: country.code,
				language: country.languages[0].code,
				store: store.identifier,
			},
			'testbuy_started'
		);
		history.push('/shopping-list');
	};

	render() {
		const { desktop, history, shoppingList } = this.props;

		const numberAlreadyChosen = shoppingList && shoppingList.length > 0 && shoppingList.length;

		return (
			<Wrapper scroll={!this.state.showDirections} desktop={desktop}>
				{this.state.showRemoveModal && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<header>
								<Trans i18nKey="reviewShoppingList.removeMessage">
									Are you sure you want to remove this product from the shopping list?
								</Trans>
							</header>
							<Button desktop={desktop} onClick={this.removeFromReviewList}>
								<CheckMark color="white" width={desktop ? '25' : '16'} height={desktop ? '25' : '16'} />
							</Button>
							<Button desktop={desktop} onClick={() => this.setState({ showRemoveModal: false, productToRemove: '' })}>
								<DesktopCross color="white" width={desktop ? '25' : '16'} height={desktop ? '25' : '16'} />
							</Button>
						</Modal.Body>
					</Modal>
				)}
				{this.state.showStartTestbuyModal && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<header>
								<Trans i18nKey="reviewShoppingList.startMessage">
									Do you want to start the review with these products?
									<br />
									You won't be able to edit the list if you proceed.
								</Trans>
							</header>
							<Button desktop={desktop} onClick={() => this.startTestbuy()}>
								<Trans i18nKey="reviewShoppingList.startReview">Start review</Trans>
							</Button>
							<Button desktop={desktop} onClick={() => this.setState({ showStartTestbuyModal: false })}>
								<Trans i18nKey="cancel">Cancel</Trans>
							</Button>
						</Modal.Body>
					</Modal>
				)}
				<article>
					<section className="product-list">
						{shoppingList &&
							shoppingList.map((prod, i) => (
								<ProductSlot desktop={desktop} index={i + 1} key={i}>
									<header>
										<span>{i + 1}.</span>
										<ProductInfo image={prod.image}>
											<section className="image" />
											<section className="content">
												<h1>{prod.name}</h1>
												<p className="number">{prod.identifier}</p>
												<p className="price">{prod.price}</p>
											</section>
										</ProductInfo>
									</header>
									<button onClick={() => this.setState({ showRemoveModal: true, productToRemove: i })}>
										<Minus width={20} height={20} />
									</button>
								</ProductSlot>
							))}
						{[...Array(20 - numberAlreadyChosen)].map((u, i) => (
							<ProductSlot
								desktop={desktop}
								disabled={i !== 0}
								key={i}
								onClick={() => i === 0 && history.push('/review/search')}
							>
								<header>
									<span>{numberAlreadyChosen + i + 1}.</span>
									{i === 0 && (
										<span>
											<Trans i18nKey="reviewShoppingList.addProduct">Add a product...</Trans>
										</span>
									)}
								</header>
								{i === 0 && (
									<button>
										<Plus width={20} height={20} />
									</button>
								)}
							</ProductSlot>
						))}
					</section>
					<footer>
						<section>
							<Button
								style={{ height: '100%' }}
								desktop={desktop}
								color={desktop ? 'white' : theme.colors.GREY}
								empty
								disabled={!shoppingList || shoppingList.length < 10}
								onClick={() => this.setState({ showStartTestbuyModal: true })}
							>
								<Trans i18nKey="reviewShoppingList.startTestbuy">Start test buy</Trans>
							</Button>
						</section>
					</footer>
				</article>
				{this.state.showReviewDirections && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<header>
								<Trans i18nKey="reviewShoppingList.directions">
									You're going to do an official review. Add a minimum of 10 and a maximum of 20 products to your
									shopping list.
								</Trans>
								<br />
								<br />
							</header>
							<Button desktop={desktop} onClick={this.closeReviewModal}>
								<Trans i18nKey="start">Start</Trans>
							</Button>
						</Modal.Body>
					</Modal>
				)}
			</Wrapper>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	shoppingList: state.review && state.review.shoppingList,
	country: appGetCountry(state),
	store: appGetStore(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			removeProduct: index => reviewActions.removeProduct(index),
			setShoppingList: shoppingList => productsActions.setShoppingListFromReview(shoppingList),
			resetShoppingList: () => reviewActions.resetShoppingList(),
			fireEventWithReviewId: (data, event) => eventsAction.eventsFireWithReviewId({ data, event }),
			eventsResetApp: () => eventsAction.eventsResetApp(),
		},
		dispatch
	);

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ShoppingList)
);
