import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import getStoreAndPersistor, { history } from './store/store';
import theme from './styles/1-settings/theme';
import AppStartup from './modules/AppStartup/AppStartup';
import AppReadyWaiter from './modules/AppReadyWaiter/AppReadyWaiter';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ThemeProvider } from 'styled-components';
import BaseStyles from './base-styles';
import ReactBreakpoints from 'react-breakpoints';
import ReactGA from 'react-ga';
import './i18n';
import { LoadingImageWrapper, LoadingImage } from './modules/AppReadyWaiter/AppReadyWaiter';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from 'react-error-boundary';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

const errorHandler = (error, componentStack) => {
	Sentry.withScope(scope => {
		scope.setExtras(componentStack);
		Sentry.captureException(error);
	});
	persistor.purge();
};

const breakpoints = {
	mobile: 400,
	mobileLandscape: 800,
	desktop: 1200,
};

const { store, persistor } = getStoreAndPersistor();

ReactGA.initialize('UA-20991538-14');
history.listen(location => ReactGA.pageview(location.hash.slice(1)));

const HandleErrorWrapper = styled.div`
	display: flex;
	justify-content: center;
	font-size: 24px;
	height: 100vh;

	.ErrorMessage {
		display: flex;
		flex-direction: column;
		padding: 20px;
		margin-top: 80px;

		button {
			margin-top: 20px;
			padding: 20px;
			background: #222222;
			color: white;
			border: none;
		}
	}
`;

const HandleError = () => {
	const { t } = useTranslation();

	return (
		<HandleErrorWrapper>
			<div className="ErrorMessage">
				{t('application_error', 'Whoops... Seems there is a problem in the application.')}
				<button onClick={() => window.location.replace(window.location.origin)}>{t('refresh', 'Refresh')}</button>
			</div>
		</HandleErrorWrapper>
	);
};

const render = Component => {
	return ReactDOM.render(
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<PersistGate loading="loading..." persistor={persistor}>
					<BaseStyles />
					<ReactBreakpoints breakpoints={breakpoints}>
						<AppStartup />
						<ConnectedRouter history={history}>
							<HashRouter>
								<AppReadyWaiter>
									<Suspense
										fallback={
											<LoadingImageWrapper>
												<LoadingImage />
											</LoadingImageWrapper>
										}
									>
										<ErrorBoundary onError={errorHandler} FallbackComponent={HandleError}>
											<Component />
										</ErrorBoundary>
									</Suspense>
								</AppReadyWaiter>
							</HashRouter>
						</ConnectedRouter>
					</ReactBreakpoints>
				</PersistGate>
			</ThemeProvider>
		</Provider>,
		document.getElementById('root')
	);
};

render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({});
