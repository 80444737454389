import React from 'react';

class CheckMarkTestbuyQuestions extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 19.559 14.495"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <g transform="translate(-43 -329)">
          <path
            d="M19.035.524a1.688,1.688,0,0,0-2.445,0L6.636,10.3,2.969,6.636a1.688,1.688,0,0,0-2.445,0,1.688,1.688,0,0,0,0,2.445l4.89,4.89h0a1.886,1.886,0,0,0,1.222.524,1.886,1.886,0,0,0,1.222-.524L19.035,2.794A1.489,1.489,0,0,0,19.035.524Z"
            transform="translate(43 329)"
          />
        </g>
      </svg>
    );
  }
}

CheckMarkTestbuyQuestions.defaultProps = {
  width: 24,
  height: 24,
  color: 'white',
};

export default CheckMarkTestbuyQuestions;
