import React from 'react';
import TestbuyDisplaySampleBlue from './icons/TestbuyDisplaySampleBlue';
import TestbuyGroupingPurple from './icons/TestbuyGroupingPurple';
import TestbuyConditionYellow from './icons/TestbuyConditionYellow';
import TestbuyPricetagPink from './icons/TestbuyPricetagPink';
import TestbuyAvailabilityOrange from './icons/TestbuyAvailabilityOrange';
import TestbuyBuyingInstructionsGreen from './icons/TestbuyBuyingInstructionsGreen';

const CategoryIcon = ({ category, ...rest }) => {
  switch (category) {
    case 'display':
      return <TestbuyDisplaySampleBlue {...rest} />;
    case 'grouping':
      return <TestbuyGroupingPurple {...rest} />;
    case 'condition':
      return <TestbuyConditionYellow {...rest} />;
    case 'price_tag':
      return <TestbuyPricetagPink {...rest} />;
    case 'availability':
      return <TestbuyAvailabilityOrange {...rest} />;
    case 'buying_instructions':
      return <TestbuyBuyingInstructionsGreen {...rest} />;
    default:
      return <TestbuyDisplaySampleBlue {...rest} />;
  }
};

export default CategoryIcon;
