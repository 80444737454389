import React from 'react';
import ProductWithLocation from './components/ProductWithLocation';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import { connect } from 'react-redux';
import { getProducts } from '../../store/Products/products.reducer';
import { withRouter } from 'react-router';
import Modal from '../../components/Modal';
import TestbuyAvailabilityOrange from '../../components/icons/TestbuyAvailabilityOrange';
import { bindActionCreators } from 'redux';
import actions from '../../store/Products/products.action';
import eventsAction from '../../store/Events/events.action';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';

const Wrapper = styled.article`
	height: ${props => (props.desktop ? 'auto' : 'calc(100% - 60px)')};
	display: flex;
	margin: ${props => (props.desktop ? '20px' : 'auto')};
	flex-direction: column;
	justify-content: space-between;
	background-color: ${props => (props.desktop ? 'white' : props.theme.colors.GREY)};
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};

	h2 {
		padding: ${props => (props.desktop ? '40px 50px' : '0px 15px')};
		line-height: 30px;
		margin: ${props => (props.desktop ? '0px' : '10px 0px')};
		border-bottom: ${props => (props.desktop ? '1px solid #dedede' : '0px')};
	}

	footer {
		display: flex;
		flex-direction: column;

		section {
			display: flex;
			flex-direction: column;
			margin: 10px 0px;
		}
	}
`;

const Content = styled.article`
	overflow-y: auto;
	padding: ${props => (props.desktop ? '0px' : '15px')};
`;

class CheckAvailability extends React.PureComponent {
	state = {
		showModal: false,
	};

	markProduct = (identifier, isAvailable) => {
		this.props.markProduct(identifier, isAvailable);
	};

	areProductsMarked = products => products.filter(p => typeof p.isAvailable !== 'boolean').reduce(() => true, false);

	handleOnClick = () => {
		const { products, eventsMarkMistakeAndComplete, setCheckAvailabilityDone, history, t } = this.props;
		const comment = t('product_not_available', {
			defaultValue: 'The product was not available at the indicated pick up point.',
		});
		[...products]
			.filter(p => p.checkSelfService)
			.map(product => {
				const mistakes = product.mistakeMarked
					? product.mistakeMarked.map(mistake => ({
							product_identifier: product.identifier,
							image: mistake.photo,
							location: 'unknown',
							comment: mistake.comment,
							question: mistake.question,
					  }))
					: [];

				const notAvailable = product.isAvailable
					? []
					: [
							{
								product_identifier: product.identifier,
								location: product.location,
								comment,
								question: 'availability',
							},
					  ];

				return eventsMarkMistakeAndComplete({
					hasMistake: !product.isAvailable,
					product_identifier: product.identifier,
					area: product.area,
					price: product.price,
					mistakes: mistakes.concat(notAvailable),
				});
			});

		setCheckAvailabilityDone();
		// resetShoppingList();
		history.push('/shopping-list');
	};

	render() {
		const { products, desktop } = this.props;
		const productsToCheck = products.filter(p => p.checkSelfService);

		const numMistakes = productsToCheck.filter(p => p.isAvailable === false).length;

		return (
			<Wrapper desktop={desktop}>
				<Content desktop={desktop}>
					<h2>
						<Trans i18nKey="checkAvailability.header">
							Please mark per product if it is available at the indicated pick up point.
						</Trans>
					</h2>
					<section style={{ maxHeight: desktop ? '412px' : 'auto', overflowY: 'auto' }}>
						{productsToCheck &&
							productsToCheck.map((product, key) => (
								<ProductWithLocation
									key={key}
									product={product}
									isAvailable={product.isAvailable}
									markProduct={this.markProduct}
									desktop={desktop}
								/>
							))}
					</section>
					<footer style={{ padding: desktop ? '30px 60px' : 'auto' }}>
						<Button
							disabled={this.areProductsMarked(productsToCheck)}
							color={'#F58337'}
							onClick={() => this.setState({ showModal: true })}
						>
							<Trans i18nKey="next">Next</Trans>
						</Button>
					</footer>
				</Content>
				{this.state.showModal && (
					<Modal>
						<Modal.Body desktop={desktop}>
							<section style={{ marginBottom: '20px' }}>
								<TestbuyAvailabilityOrange />
							</section>
							<header
								style={{
									fontSize: '24px',
									fontWeight: 'bold',
									textAlign: 'center',
								}}
							>
								{numMistakes === 1 ? (
									<Trans i18nKey="checkAvailability.mistake" numMistakes={numMistakes}>
										{{ numMistakes }} additional mistake reported to the to-do list.
									</Trans>
								) : (
									<Trans i18nKey="checkAvailability.mistakes" numMistakes={numMistakes}>
										{{ numMistakes }} additional mistakes reported to the to-do list.
									</Trans>
								)}
							</header>
							<Button color={'#F58337'} onClick={this.handleOnClick}>
								<Trans i18nKey="done">Done</Trans>
							</Button>
						</Modal.Body>
					</Modal>
				)}
			</Wrapper>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	products: getProducts(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			markProduct: (identifier, isAvailable) => actions.markProduct(identifier, isAvailable),
			resetShoppingList: () => actions.resetShoppingList(),
			eventsMarkMistakeAndComplete: product => eventsAction.eventsMarkMistakeAndComplete(product),
			setCheckAvailabilityDone: () => actions.setCheckAvailabilityDone(),
		},
		dispatch
	);

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckAvailability)));
