import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions, { LEARN_GET_LEARN_PRACTICE } from './learn.action';
import { appGetLanguage } from '../App/app.reducer';
import { fetchLearnPractice } from '../../utils/api';

function* learnGetLearnPractice() {
	try {
		const { code } = yield select(appGetLanguage);
		const learnPractice = yield call(fetchLearnPractice, code);
		if (learnPractice) {
			yield put(actions.setLearnPractice(learnPractice));
		}
	} catch (e) {
		console.error(e);
	}
}

export function* learnGetLearnPracticeListener() {
	yield takeLatest(LEARN_GET_LEARN_PRACTICE, learnGetLearnPractice);
}

export default [learnGetLearnPracticeListener];
