import React from 'react';

class ShoppingBasket extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 48.7 40"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M39.2,13c-0.9-2.9-4.7-13-14.8-13c-10,0-13.7,10.1-14.6,13H0l7.6,27h33.5l7.6-27H39.2z M24.4,2c8.3,0,11.7,7.9,12.7,11H11.9
	   C12.9,9.9,16.3,2,24.4,2z M39.6,38H9.1L2.6,15H46L39.6,38z"
        />
      </svg>
    );
  }
}

ShoppingBasket.defaultProps = {
  width: 50,
  height: 50,
  color: '#000',
};

export default ShoppingBasket;
