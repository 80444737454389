import axios from 'axios';
import { API_URL } from '../constants';
import getYear from 'date-fns/get_year';
import getISOWeek from 'date-fns/get_iso_week';
import { saveAs } from 'file-saver';
import { store } from '../store/store';
import eventsActions from '../store/Events/events.action';

export const API_HOST = process.env.REACT_APP_API_HOST || 'https://askholmen.test.qollap.com';

export const getCountries = () =>
	axios.get(`${API_URL}/countries/`).then(res => {
		if (res.status === 200) {
			return res.data;
		}
		return Promise.reject(res);
	});

export const getWeeklyStats = (
	{ week = getISOWeek(new Date()), year = getYear(new Date()), country, store },
	headers
) =>
	axios
		.get(
			`${API_HOST}/api/ikea_testbuy/frontend-stats/week_summary/?week=${week}&year=${year}&country=${country}&store=${store}`,
			{ headers }
		)
		.then(res => {
			if (res.status === 200) {
				return res.data;
			}
			return Promise.reject(res);
		});

export const getSearchResults = ({ country = 'nl', language = 'nl', store = '415', identifier = '23' }) =>
	axios
		.get(`${API_URL}/products/search/?country=${country}&language=${language}&store=${store}&identifier=${identifier}`)
		.then(res => {
			if (res.status === 200) {
				return res.data;
			}
			return Promise.reject(res);
		});

export const getShoppingList = ({ country = 'nl', language = 'nl', store = '415', size = 5, area }) =>
	axios
		.get(`${API_URL}/products/?country=${country}&language=${language}&store=${store}&size=${size}&area=${area}`)
		.then(res => {
			if (res.status === 200) {
				return res.data;
			}
			return Promise.reject(res);
		});

export const getPrefetchData = ({ country = 'nl', language = 'nl', store = '415', size = 5 }) =>
	axios
		.get(`${API_URL}/products/prefetch/?country=${country}&language=${language}&store=${store}&size=5&size=10`)
		.then(res => {
			if (res.status === 200) {
				return res.data;
			}
			return Promise.reject(res);
		});

export const getQuestions = language => {
	let url =
		API_HOST === 'https://askholmen.test.qollap.com'
			? `https://ikea-askholmen.content.qollap.com/custom/ikea_askholmen/testbuyflow/4391/?format=json&language=${language}`
			: `https://ikea-askholmen.content.qollap.com/custom/ikea_askholmen/testbuyflow/283/?format=json&language=${language}`;
	return axios.get(url).then(res => {
		if (res.status === 200) {
			return res.data;
		}
		return Promise.reject(res);
	});
};

export const fetchLearnPractice = language => {
	let url =
		API_HOST === 'https://askholmen.test.qollap.com'
			? `https://ikea-askholmen.content.qollap.com/custom/ikea_askholmen/learnpractice/4406/?language=${language}`
			: `https://ikea-askholmen.content.qollap.com/custom/ikea_askholmen/learnpractice/919/?language=${language}`;
	return axios.get(url).then(res => {
		if (res.status === 200) {
			return res.data.topics;
		}
		return Promise.reject(res);
	});
};

export const getTestbuyReport = (url, headers) => {
	const { dispatch } = store;
	axios({
		url: url,
		headers,
		method: 'GET',
		responseType: 'blob',
	}).then(response => {
		if (response.status === 200) {
			const blob = new Blob([response.data], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			saveAs(blob, 'testbuyReport.xlsx');
			dispatch(eventsActions.eventsDownloadReportFinished());
		} else {
			dispatch(eventsActions.eventsDownloadReportFailed());
			return Promise.reject(response);
		}
	});
};

export const sendEvent = (url, headers, body) => axios.post(url, body, { headers });

export const loginEvents = (url, headers, data) =>
	axios.post(url, data, { headers }).then(res => {
		if (res.status === 200) {
			return res.data && res.data.token;
		}

		return Promise.reject(res);
	});

export const getMistakes = (url, headers) =>
	axios.get(url, { headers }).then(res => {
		if (res.status === 200) {
			return res.data;
		}
		return Promise.reject(res);
	});

export const startTestBuy = () => {};

export const getReplacedProduct = ({
	country = 'nl',
	language = 'nl',
	store = '415',
	area = 'blub',
	products_ids = [],
}) => {
	const productsString = products_ids.map(elem => (elem = `&product_ids=${elem}`)).join('');
	return axios
		.get(
			`${API_URL}/products/replace/?country=${country}&language=${language}&store=${store}&area=${area}${productsString}`
		)
		.then(res => {
			if (res.status === 200) {
				return res.data;
			}
			return Promise.reject(res);
		});
};
