import React from 'react';
import styled from 'styled-components';
import Ikea from '../../assets/images/IKEA-logo.svg';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withBreakpoints } from 'react-breakpoints';
import BackArrow from '../icons/BackArrow';
import Hamburger from '../icons/Hamburger';
// import SearchMagnifyingGlass from '../icons/SearchMagnifyingGlass';
import { getProducts } from '../../store/Products/products.reducer';
import { Trans } from 'react-i18next';

const StyledHeader = styled.header`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 20px;
	background-color: white;
	height: 60px;
	border-bottom: 1px solid #dedede;

	div {
		flex: 1;
	}

	h1 {
		text-align: center;
	}
`;

const IconWrapper = styled.div`
	text-align: right;
`;

const StyledHeaderDesktop = styled(StyledHeader)`
	height: 80px;
	width: 100%;
	p {
		margin-top: 3px;
		margin-left: 20px;
		text-align: left;
		font-weight: bold;
		flex: 1;
		cursor: pointer;
	}

	.StyledHeaderDesktop__Language {
		margin-left: auto;
		text-align: right;
	}
`;

const Logo = styled.i`
	display: flex;
	margin-left: -5px;
	background: ${props => `url(${Ikea})`};
	width: 100px;
	height: 30px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;

const Menu = styled.article`
	height: 100%;
	background-color: white;
	width: calc(100% - 65px);
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
`;

const MenuItem = styled.li`
	border-bottom: 1px solid #c8c8c8;
	height: ${window.innerWidth < 340 ? '64px' : '70px'};
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 30px;
	font-size: 20px;
	line-height: 24px;
	color: ${props => (props.disabled ? '#C8C8C8' : 'black')};

	input {
		transition: all 0.2s ease-in-out;
		opacity: ${props => (props.collapsed ? 0 : 1)};
		max-width: ${props => (props.collapsed ? '0' : '210px')};
		border: none;
	}
`;

const Overlay = styled.article`
	min-height: 100%;
	width: 100vw;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;

	div {
		width: 65px;
		position: absolute;
		right: 0px;
		height: 100vh;
	}
`;

class Header extends React.PureComponent {
	state = {
		openMenu: false,
		collapsed: true,
	};

	goToBase = isDesktop => {
		const { products } = this.props;
		const url = this.props.isIntroDone
			? isDesktop
				? products.length
					? '/shopping-list'
					: '/testbuy'
				: '/home'
			: '/';
		this.props.history.push(url);
	};

	resetCountry = () => {
		const { history } = this.props;
		history.push('/select-country');
	};

	handleOnClick = url => {
		this.props.history.push(url);
		this.setState({ openMenu: false, collapsed: true });
	};

	getText = () => {
		let url = this.props.location.pathname;
		const baseUrlRegex = /\/\w+-\w+/;
		const textRegex = /[/-]/g;
		if (url.includes('learn') || url.includes('practice'))
			return <Trans i18nKey="learnPractice">Learn and practise</Trans>;
		url = baseUrlRegex.exec(url) && baseUrlRegex.exec(url)[0] ? baseUrlRegex.exec(url)[0] : url;
		if (url.includes('question') || url.includes('availability') || url.includes('testbuy')) {
			if (this.props.isReview) return <Trans i18nKey="review">Review</Trans>;
			else return <Trans i18nKey="testbuy">Test buy</Trans>;
		}
		switch (url) {
			case '/':
			case '/home':
				return '';
			case '/select-store':
				return <Trans i18nKey="chooseStore">Choose store</Trans>;
			case '/select-country':
				return <Trans i18nKey="chooseCountry">Choose country</Trans>;
			case '/shopping-list':
				if (this.props.isReview) return <Trans i18nKey="review">Review</Trans>;
				else return <Trans i18nKey="testbuy">Test buy</Trans>;
			case '/store-stream':
				return <Trans i18nKey="todoList">To-do list</Trans>;
			case '/faq':
				return <Trans i18nKey="faq">FAQ</Trans>;
			case '/intro':
				return <Trans i18nKey="introUrl">Intro</Trans>;
			case '/select-area':
				return <Trans i18nKey="selectAreaUrl">Select Area</Trans>;
			case '/more-info':
				return <Trans i18nKey="moreInfoAbout">More info about...</Trans>;
			case '/review':
				return <Trans i18nKey="review">Review</Trans>;
			case '/review/search':
				return <Trans i18nKey="review">Review</Trans>;
			default:
				const text = url.replace(textRegex, ' ');
				return `${text.charAt(1).toUpperCase()}${text.slice(2)}`;
		}
	};

	getLogo = () => {
		const { history, previousUrl } = this.props;
		const url = this.props.location.pathname;
		const goBack = () => (previousUrl ? history.push(previousUrl) : history.goBack());
		if (url.includes('practice')) {
			return <BackArrow onClick={() => history.push('/learn')} />;
		}
		switch (url) {
			case '/':
			case '/home':
				return <Logo onClick={() => this.goToBase()} />;
			case '/store-stream':
			case '/question-final':
				return;
			default:
				return <BackArrow onClick={goBack} />;
		}
	};

	getIcon = () => {
		let url = this.props.location.pathname;
		const baseUrlRegex = /\/\w+-\w+/;
		url = baseUrlRegex.exec(url) && baseUrlRegex.exec(url)[0] ? baseUrlRegex.exec(url)[0] : url;
		switch (url) {
			case '/':
			case '/intro':
			case '/select-store':
			case '/select-country':
				return;
			default:
				return (
					<div onClick={() => this.setState({ openMenu: !this.state.openMenu })}>
						<Hamburger />
					</div>
				);
		}
	};

	toggleInput() {
		this.setState({ collapsed: !this.state.collapsed });
	}

	render() {
		const { breakpoints, currentBreakpoint, store, location, products, language } = this.props;
		const commentScreen = !(
			location.pathname.includes('comment') ||
			location.pathname.includes('video') ||
			location.pathname.includes('image')
		);

		return breakpoints[currentBreakpoint] < breakpoints.desktop ? (
			<>
				{commentScreen && (
					<StyledHeader className="Header">
						<div>{this.getLogo()}</div>
						<h1 style={{ fontWeight: 'bold' }}>{this.getText()}</h1>
						<IconWrapper className="Header__IconWrapper">{this.getIcon()}</IconWrapper>
						{this.state.openMenu && (
							<Overlay>
								<div onClick={() => this.setState({ openMenu: false, collapsed: true })} />
								<Menu>
									<ul>
										<MenuItem onClick={() => this.handleOnClick('/home')}>
											<Trans i18nKey="home">Home</Trans>
										</MenuItem>
										<MenuItem onClick={() => this.handleOnClick(products.length ? '/shopping-list' : '/testbuy-intro')}>
											<Trans i18nKey="testbuy">Test buy</Trans>
										</MenuItem>
										<MenuItem onClick={() => this.handleOnClick('/store-stream')}>
											<Trans i18nKey="todoList">To-do list</Trans>
										</MenuItem>
										<MenuItem onClick={() => this.handleOnClick('/learn')}>
											<Trans i18nKey="learnPractice">Learn and practise</Trans>
										</MenuItem>
										<MenuItem onClick={() => this.handleOnClick('/review')}>
											<Trans i18nKey="review">Review</Trans>
										</MenuItem>
										<MenuItem onClick={() => this.handleOnClick('/select-country')}>
											<Trans i18nKey="changeStore">Change store</Trans>
										</MenuItem>
										<MenuItem onClick={() => this.handleOnClick('/')}>
											<Trans i18nKey="changeLanguage">Change language</Trans>
										</MenuItem>
										<MenuItem onClick={() => this.handleOnClick('/faq')}>
											<Trans i18nKey="faq">FAQ</Trans>
										</MenuItem>
									</ul>
								</Menu>
							</Overlay>
						)}
					</StyledHeader>
				)}
			</>
		) : (
			<StyledHeaderDesktop className="StyledHeaderDesktop">
				<>
					<Logo onClick={() => this.goToBase(true)} />
					<p onClick={() => this.resetCountry()}>{store}</p>
				</>
				<div
					className="StyledHeaderDesktop__Language"
					style={{}}
					onClick={() => this.props.history.push('/?q=changeLanguage')}
				>
					{language.name}
				</div>
			</StyledHeaderDesktop>
		);
	}
}
const mapStateToProps = state => ({
	isIntroDone: state.app && state.app.isIntroDone,
	store: state.app && state.app.store && state.app.store.name,
	language: state.app && state.app.language,
	products: getProducts(state),
	isReview: state.products && state.products.isReview,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withBreakpoints(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));
