import theme from '../styles/1-settings/theme';

export const getColor = cat => {
	const category = cat && cat.toLowerCase();
	switch (category) {
		case 'display':
			return theme.colors.LIGHT_BLUE;
		case 'grouping':
			return theme.colors.PURPLE;
		case 'condition':
			return theme.colors.YELLOW;
		case 'price_tag':
			return theme.colors.PINK;
		case 'buying_instructions':
			return theme.colors.LIGHT_GREEN;
		case 'availability':
		case 'check availability':
			return theme.colors.ORANGE;
		default:
			return theme.colors.RED;
	}
};
