import React from 'react';
import Slider from 'react-slick';
import { Wrapper, Slide1TB, Slide2TB } from './SliderComponents';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/App/app.action';
import { appGetCountry, appGetLanguage } from '../../store/App/app.reducer';
import i18n from '../../i18n';

class IntroTestBuySlider extends React.PureComponent {
	goTo = () => {
		this.props.setIntroDone();
		this.props.goTo();
	};

	getSlides = () => {
		const settings = {
			dots: true,
			infinite: false,
			speed: 200,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			rtl: i18n.language === 'ar' || i18n.language === 'he',
		};
		const { desktop, language, country } = this.props;

		return i18n.language === 'ar' || i18n.language === 'he' ? (
			<Slider ref={slider => (this.slider = slider)} {...settings}>
				<Slide2TB rtl={true} desktop={desktop} goTo={this.goTo} language={language} country={country} />
				<Slide1TB rtl={true} desktop={desktop} goTo={() => this.slider.slickGoTo(0)} skip={this.goTo} />
			</Slider>
		) : (
			<Slider ref={slider => (this.slider = slider)} {...settings}>
				<Slide1TB desktop={desktop} goTo={() => this.slider.slickGoTo(1)} skip={this.goTo} />
				<Slide2TB desktop={desktop} goTo={this.goTo} video={this.props.video} language={language} country={country} />
			</Slider>
		);
	};

	render() {
		const { desktop } = this.props;
		return <Wrapper desktop={desktop}>{this.getSlides()}</Wrapper>;
	}
}

const mapStateToProps = state => ({
	country: appGetCountry(state),
	language: appGetLanguage(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setIntroDone: () => actions.appSetTestBuyIntroDone(),
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(IntroTestBuySlider);
