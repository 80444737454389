import React from 'react';
import styled from 'styled-components';
import ArrowRight from '../../../components/icons/ArrowRight';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import TopicIcon from '../../../components/TopicIcon';
import { Trans } from 'react-i18next';

const Wrapper = styled.article`
	margin-bottom: 15px;

	div {
		margin-bottom: 30px;
	}
`;

const InnerWrapper = styled.article`
	background-color: white;
	height: ${props => (props.desktop ? '150px' : '130px')};
	box-shadow: ${props =>
		props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)'};
	padding: ${props => (props.desktop ? '30px 35px 15px' : '15px')};
	margin-bottom: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	section {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	header {
		font-weight: bold;
		font-size: ${props => (props.desktop ? '24px' : '18px')};
		line-height: 32px;
		max-width: calc(100% - 60px);
	}

	footer {
		text-align: center;
	}
`;

const ExtraButton = styled.article`
	background-color: white;
	box-shadow: ${props =>
		props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)'};
	padding: ${props => (props.desktop ? '25px 35px' : '20px')};
	margin-bottom: 8px;
	font-size: 20px;
	line-height: 30px;
	h2 {
		font-size: 20px;
	}
	p {
		margin-top: 10px;
		font-size: ${props => (props.desktop ? '18px' : '14px')};
		line-height: 21px;
	}
`;

export class Topic extends React.PureComponent {
	state = {
		open: false,
	};

	handleOnClick = (id, uuid, video) => {
		if (video) {
			this.props.history.push(`/learn/${id}/video/${uuid}`);
		} else {
			this.props.history.push(`/learn/${id}/${uuid}`);
		}
	};

	render() {
		const {
			topic: { id, title, learning_materials, questions },
			history,
			desktop,
		} = this.props;

		return (
			<Wrapper>
				<InnerWrapper desktop={desktop} onClick={() => this.setState({ open: !this.state.open })}>
					<section>
						<header>{title}</header>
						<TopicIcon topic={id} width={desktop ? '60' : '50'} height={desktop ? '60' : '50'} />
					</section>
					<footer>
						<ArrowRight
							width={desktop ? '22' : '15'}
							height={desktop ? '22' : '15'}
							style={{ transform: this.state.open ? 'rotate(270deg)' : 'rotate(90deg)' }}
						/>
					</footer>
				</InnerWrapper>
				{this.state.open && (
					<div>
						{learning_materials.map((learn, key) => (
							<ExtraButton
								desktop={desktop}
								key={key}
								onClick={() => this.handleOnClick(id, learn.uuid, learn.video)}
							>
								{learn.video && (
									<h2>
										<Trans i18nKey="video">Video</Trans>: {learn.title}
									</h2>
								)}
								{!learn.video && <h2>{learn.title}</h2>}
								<p>{learn.description}</p>
							</ExtraButton>
						))}
						{questions.length > 0 && (
							<ExtraButton desktop={desktop} onClick={() => history.push(`/practice/${id}`)}>
								<Trans i18nKey="Practise">Practise</Trans>
							</ExtraButton>
						)}
					</div>
				)}
			</Wrapper>
		);
	}
}

export default withRouter(connect()(Topic));
