import React from 'react';
import styled from "styled-components";
import {ShimmerAnimation} from "../styles/6-objects/objects";

const Wrapper = styled.article`
  padding: 30px 40px;
  height: 100px;
  display: flex;
  align-items: center;
`;

const Header = styled(ShimmerAnimation)`
  width: 160px;
  height: 20px;
`;

const Number = styled(ShimmerAnimation)`
  width: 100px;
  height: 14px;
  margin-top: 5px;
`;

const Image = styled(ShimmerAnimation)`
  width: 75px;
  height: 75px;
  margin-right: 20px;
  margin-left: 20px;
`;

const FilterInnerPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100px;
  width: 100%;
  margin-top: 20px;
`;

const FilterHeader = styled(ShimmerAnimation)`
  width: 40%;
  height: 20px;
`;

const FilterOutline = styled(ShimmerAnimation)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 8px;
  top: 0px;
  width: calc(100% - 100px);
`;

const FilterOutlineBottom = styled(FilterOutline)`
  top: 92px;
`;

const FilterOutlineBorderLeft = styled(FilterOutline)`
  transform: rotate(90deg);
  width: 100px;
  top: auto;
  right: auto;
  left: 0px;
`;

const FilterOutlineBorderRight = styled(FilterOutline)`
  transform: rotate(90deg);
  width: 100px;
  top: auto;
  right: 0px;
  left: auto;
`;

export const FilterLoading = () => (
  <FilterInnerPart>
    <FilterOutline />
    <FilterOutlineBorderLeft />
      <FilterHeader />
    <FilterOutlineBorderRight/>
    <FilterOutlineBottom />
  </FilterInnerPart>
);

export const ProductLoading = () => (
  <Wrapper>
    <Image/>
    <div>
      <Header/>
      <Number/>
    </div>
  </Wrapper>
);
