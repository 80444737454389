const initialState = {
  tasks: {
    plan: [
      {
        id: 1,
        name: 'Analyse the product',
        description:
          '<p>Our customers don&rsquo;t know our range. They need help to understand product benefits and find relevant solutions. Then they can make the right buying decisions. We can only help them if we know each product through and through.</p>        <p>To gain this deep understanding, we carry out a product analysis. We experience, touch and try the product through our customers&rsquo; eyes. And understand any special relevance to people locally. We can then point customers in the right direction when we present, communicate and sell our products and solutions.&nbsp;</p>',
        roles: [{ name: 'sales', percentage: 100 }],
        steps: [
          {
            header: 'Step 1: Check',
            descriptions: [
              '<p>Check product information (PIA facts). Collect all facts from both a business and customer point of view.</p>',
            ],
          },
          {
            header: 'Step 2: Assemble',
            descriptions: [
              '<p>Check product information (PIA facts). Collect all facts from both a business and customer point of view.</p>',
            ],
          },
          {
            header: 'Step 3: Test',
            descriptions: [
              '<p>Check product information (PIA facts). Collect all facts from both a business and customer point of view.</p>',
            ],
          },
        ],
      },
      {
        id: 2,
        name: 'Group the range',
        description:
          '<p>Our customers don&rsquo;t know our range. They need help to understand product benefits and find relevant solutions. Then they can make the right buying decisions. We can only help them if we know each product through and through.</p>        <p>To gain this deep understanding, we carry out a product analysis. We experience, touch and try the product through our customers&rsquo; eyes. And understand any special relevance to people locally. We can then point customers in the right direction when we present, communicate and sell our products and solutions.&nbsp;</p>',
        roles: [
          { name: 'sales', percentage: 50 },
          { name: 'com&in', percentage: 50 },
        ],
        steps: [
          {
            header: 'Step 1: Check',
            descriptions: [
              '<p>Check product information (PIA facts). Collect all facts from both a business and customer point of view.</p>',
            ],
          },
          {
            header: 'Step 2: Assemble',
            descriptions: [
              '<p>Check product information (PIA facts). Collect all facts from both a business and customer point of view.</p>',
            ],
          },
          {
            header: 'Step 3: Test',
            descriptions: [
              '<p>Check product information (PIA facts). Collect all facts from both a business and customer point of view.</p>',
            ],
          },
        ],
      },
    ],
    implement: [],
    maintain: [],
    monitor: [],
  },
};

const cmBlocksReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default cmBlocksReducer;

export function getTasks(state) {
  return state.cmblocks && state.cmblocks.tasks;
}
