// @flow
const BASE_BORDER_RADIUS = '2px';
const BASE_PADDING = '20px';

const GREEN = '#264223';
const LIGHT_GREEN = '#6EB31F';
const PURPLE = '#6700E8';
const BROWN = '#5C4230';
const GREY = '#EEEEEE';
const LIGHT_GREY = '#F8F8F8';
const DARK_GREY = '#D9D9D9';
const CORN_SILK = '#FDF7DD';
const WHITE = '#FFFFFF';
const BLACK = '#000000';
const RED = '#FF3300';
const YELLOW = '#FFC512';
const ORANGE = '#F58337';
const PINK = '#EE7A9E';
const LIGHT_BLUE = '#43BDF3';
const BLUE = '#0058A3';

const flexboxgrid = {
  // Defaults
  gridSize: 12, // columns
  gutterWidth: 0.63, // rem
  outerMargin: 2, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 76, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 64, // em
    lg: 75, // em
  },
};

const theme = {
  baseBorderRadius: BASE_BORDER_RADIUS,
  basePadding: BASE_PADDING,
  colors: {
    GREEN,
    LIGHT_GREEN,
    PURPLE,
    BROWN,
    DARK_GREY,
    GREY,
    LIGHT_GREY,
    CORN_SILK,
    WHITE,
    BLACK,
    RED,
    YELLOW,
    ORANGE,
    PINK,
    LIGHT_BLUE,
    BLUE,
  },
  buttonBorderRadius: BASE_BORDER_RADIUS,
  buttonPaddingLarge: '15px 20px',
  buttonPaddingMedium: '10px 20px',
  buttonPaddingSmall: '2px 15px',
  flexboxgrid,
};

export default theme;

export type ThemeProps = { theme: typeof theme };
