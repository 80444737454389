import React from 'react';
import styled from 'styled-components';
import { withBreakpoints } from 'react-breakpoints';

const DesktopContainer = styled.section`
	display: flex;
	flex: 1;
	height: 100%;
	background: ${props => props.theme.colors.GREY};
`;

const Slot = styled.article`
	display: flex;
	flex: 1;
	padding: 0 14%;
	height: 100%;
	width: 100%;
`;

class _DesktopContainer extends React.PureComponent {
	static Slot = Slot;

	render() {
		const { children } = this.props;
		return <DesktopContainer>{children}</DesktopContainer>;
	}
}

export default withBreakpoints(_DesktopContainer);
