import React from 'react';
import styled from 'styled-components';
import Mistake from '../components/Mistake';
import { withRouter } from 'react-router';
import { getColor } from '../utils/util';

const List = styled.ul`
	display: flex;
	flex-direction: column;
	padding: 10px 0px;
`;

class MistakeList extends React.PureComponent {
	render() {
		const { mistake, history, desktop } = this.props;
		return (
			<List>
				{mistake &&
					mistake.mistakes &&
					mistake.mistakes.map((m, key) => (
						<Mistake
							key={key}
							desktop={desktop}
							color={getColor(m.q.category || m.question)}
							id={m.question}
							type={m.q.display_category || m.question}
							description={m.comment}
							onClickMistake={() =>
								history.push(`/store-stream/${mistake.product.identifier}/${m.question}`)
							}
							checked={mistake.fixed}
						/>
					))}
			</List>
		);
	}
}

export default withRouter(MistakeList);
