import React from 'react';

class CheckMarkCircle extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 24.9 24.9"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <g transform="translate(-34 -321.087)">
          <path
            d="M46.5,321.1c6.9,0,12.5,5.6,12.5,12.5S53.3,346,46.5,346S34,340.4,34,333.5c0,0,0,0,0,0
       C34,326.7,39.6,321.1,46.5,321.1z"
          />
          <path
            fill="#fff"
            d="M51.9,330.3c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-5.7,5.6l-2.1-2.1c-0.4-0.4-1-0.4-1.4,0
       c0,0,0,0,0,0c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0l2.8,2.8l0,0c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l6.4-6.4
       C52.3,331.3,52.3,330.8,51.9,330.3C52,330.4,51.9,330.3,51.9,330.3z"
          />
        </g>
      </svg>
    );
  }
}

CheckMarkCircle.defaultProps = {
  width: 100,
  height: 100,
  color: '#a7a7a7',
};

export default CheckMarkCircle;
