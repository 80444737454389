import { START_PRACTICE, SET_PRACTICE_TOTAL, UPDATE_PRACTICE_RESULT, LEARN_SET_LEARN_PRACTICE } from './learn.action';
import languages from '../../languages';

const initialState = {
	topics: [],
	practiceResults: { result: 0, resultsTotal: 0 },
};

const setResult = (state, result) => {
	const practiceResults = Object.assign({}, state.practiceResults);

	if (result.is_correct) {
		practiceResults.result += 1;
	}

	return practiceResults;
};

const connectLanguages = learn =>
	learn.map(l => {
		l.learning_materials = l.learning_materials.map(mat => {
			if (mat.video) {
				mat.subtitles = mat.subtitles.map(sub => {
					sub.name = languages.filter(l => l.code === sub.language).reduce((memo, l) => l.name, '');
					return sub;
				});
			}
			return mat;
		});
		return l;
	});

const learnReducer = (state = initialState, action) => {
	switch (action.type) {
		case START_PRACTICE:
			return {
				...state,
				practiceResults: initialState.practiceResults,
			};
		case SET_PRACTICE_TOTAL:
			return {
				...state,
				practiceResults: Object.assign({}, state.practiceResults, { resultsTotal: action.total }),
			};
		case UPDATE_PRACTICE_RESULT:
			return {
				...state,
				practiceResults: setResult(state, action.result),
			};
		case LEARN_SET_LEARN_PRACTICE:
			return {
				...state,
				topics: connectLanguages(action.learnPractice),
			};
		default:
			return state;
	}
};

export default learnReducer;

export function getTopics(state) {
	return state.learn && state.learn.topics;
}

export function getPracticeResults(state) {
	return state.learn && state.learn.practiceResults;
}
