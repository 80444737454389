import { css } from 'styled-components';

const trumps = css`
  body[dir='rtl'] {
    .Header {
      .Header__IconWrapper {
        text-align: left;
      }
      .BackArrow {
        transform: rotate(180deg);
      }
    }

    .MultipleCheckbox li {
      padding-left: 0;
      padding-right: 30px;
      section {
        margin-right: 0;
        margin-left: 20px;
      }
    }

    .QuestionSummary {
      .CategoryIcon {
        margin-right: 0;
        margin-left: 30px;
      }
      .CategoryCheckMark {
        margin-left: 0;
        margin-right: auto;
      }
      .CategoryButtons button:first-child {
        margin-right: 0;
        margin-left: 5px;
      }
    }

    .StepItem {
      section {
        padding-right: 0;
        padding-left: 20px;
      }
    }

    .StyledHeaderDesktop {
      .StyledHeaderDesktop__Language {
        text-align: left;
      }
    }

    .SelectList {
      li:hover {
        border-left: none;
        border-right: ${props => `5px solid ${props.theme.colors.BLUE}`};
      }
    }

    .PageWrapper {
      header svg {
        transform: rotate(180deg);
      }
    }

    .Home {
      .Home__ArrowContainer {
        padding-left: 15px;
        padding-right: 0;
        &:last-of-type .ArrowRight {
          transform: rotate(180deg);
        }
      }
      .stat-container {
        .ArrowRight {
          transform: rotate(0deg);
        }
      }
    }

    .Filter {
      .BackArrowDesktop {
        transform: rotate(180deg);
      }
      li section {
        margin-left: 20px;
        margin-right: 0px;
      }
    }

    .ProductCard {
      .ProductImage {
        margin-left: 20px;
        margin-right: 0px;
      }
      .icon-container {
        margin-right: auto;
        margin-left: 0;
      }
    }
    .ProductDetail,
    .MistakeDetail {
      .BackArrowDesktop {
        transform: rotate(180deg);
      }
    }

    .MoreInfoAbout {
      svg {
        transform: rotate(180deg);
      }
    }

    .Learn__Video {
      header {
        margin-left: auto;
        right: 30px;
      }
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export default trumps;
