import React from 'react';
import styled from 'styled-components';
import { getTopics } from '../../store/Learn/learn.reducer';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Button from '../../components/Form/Button/Button';
import BackArrowDesktop from '../../components/icons/BackArrowDesktop';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.article`
  overflow: auto;
  width: 100%;
`;

const InnerWrapper = styled.article`
  background-color: white;
  padding: ${props => (props.desktop ? '30px 30px' : '5px 17px 30px')};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${props => (props.desktop ? '20px 0px' : '0px')};
  box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};

  header {
    font-weight: bold;
    font-size: ${props => (props.desktop ? '32px' : '24px')};
    line-height: 30px;
  }

  p {
    font-size: ${props => (props.desktop ? '18px' : '16px')};
    line-height: ${props => (props.desktop ? '30px' : '28px')};
    padding: ${props => (props.desktop ? '0px 35px' : '0px')};
  }

  .rich-text {
    p {
      padding: 10px 0px;
    }
    img {
      height: auto;
      width: 100%;
    }
  }

  ul {
    list-style: disc outside;
    padding: 10px 0px 10px 40px;

    li {
      padding: 10px 0px;
    }
  }

  section {
    padding: ${props => (props.desktop ? '30px 35px 10px' : '30px 0px 10px')};

    h3 {
      font-weight: bold;
      font-size: ${props => (props.desktop ? '20px' : '18px')};
    }
  }

  footer {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
  }
`;

function Material({ desktop, topics, match, history }) {
  const topic = topics
    .filter(q => q.id === parseInt(match.params.id, 10))
    .map(q => q.learning_materials.filter(m => m.uuid === match.params.material).reduce((memo, m) => m, {}))
    .reduce((memo, q) => q, {});
  const { t } = useTranslation();
  return (
    <Wrapper>
      <InnerWrapper desktop={desktop}>
        {desktop && <BackArrowDesktop onClick={() => history.goBack()} />}
        <section>
          <header>{topic.title}</header>
        </section>
        <article>
          <div>
            <p dangerouslySetInnerHTML={{ __html: topic.description }} />
          </div>
          <div>
            <p dangerouslySetInnerHTML={{ __html: topic.body }} />
          </div>
          {topic.video && (
            <video
              style={{ marginTop: '20px', marginBottom: '30px' }}
              width={'100%'}
              src={topic.video}
              controls
              autoPlay={false}
            />
          )}
        </article>
        <footer>
          <Button onClick={() => history.goBack()}>{t('close', { defaultValue: 'Close' })}</Button>
        </footer>
      </InnerWrapper>
    </Wrapper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  topics: getTopics(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Material)
);
