import { css } from 'styled-components';

const utilities = css`
	.bold {
		font-weight: 600;
	}

	.thin {
		font-weight: 300;
	}

	@keyframes placeHolderShimmer {
		0% {
			background-position: -468px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}

	@keyframes wave {
		0%,
		60%,
		100% {
			transform: initial;
		}

		30% {
			transform: translateY(-15px);
		}
	}
`;

export default utilities;
