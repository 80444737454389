import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { getTopics } from '../../store/Learn/learn.reducer';
import Button from '../../components/Form/Button/Button';
import actions from '../../store/Learn/learn.action';
import { css } from 'styled-components';
import CheckMarkCompletedState from '../../components/icons/CheckMarkCompletedState';
import Cross from '../../components/icons/CrossTestbuyQuestions';
import ArrowRight from '../../components/icons/ArrowRight';
import { Trans } from 'react-i18next';
import i18n from '../../i18n';

const DotWrapper = styled.ul`
	display: flex;
	justify-content: space-around;
	height: 50px;
	width: ${props => (props.desktop ? '80%' : '100%')};
	padding: 0 10px;
	align-items: center;
	margin: auto;
`;

const Dot = styled.li`
	display: flex;
	border-radius: 50%;
	width: 11px;
	height: 11px;
	background: black;
	opacity: 0.3;
	&.active {
		opacity: 0.7;
	}
`;

const Wrapper = styled.article`
	padding: 15px;
	width: 100%;
	overflow: auto;
	h2 {
		margin-top: 30px;
		margin-bottom: 20px;
		font-size: 24px;
		line-height: 32px;
		font-weight: bold;
	}
`;

const ImageWrapper = styled.article`
	padding: 12px;
	background-color: white;
	width: 100%;
	box-shadow: ${props =>
		props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)'};
`;

const Image = styled.section`
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: ${props => `url(${props.image})`};
	margin: auto;
	padding-bottom: 56.25%;
`;

const ChoicesWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: ${props => (props.desktop ? 'row' : 'column')};
	button {
		min-width: 40%;
		flex: 1;
		&:last-of-type {
			margin-top: ${props => (props.desktop ? '0px' : '10px')};
			margin-left: ${props => (props.desktop && !props.rtl ? '15px' : '0px')};
			margin-right: ${props => (props.desktop && props.rtl ? '15px' : '0px')};
		}
	}

	${props =>
		props.fourOptions &&
		css`
			flex-direction: row;
			button {
				&:nth-of-type(2) {
					margin-left: 10px;
					margin-right: ${props => (props.rtl ? '15px' : '0px')};
				}
				&:nth-of-type(3) {
					margin-top: 10px;
				}
				&:last-of-type {
					margin-left: 10px;
					margin-top: 10px;
				}
			}
		`};

	${props =>
		props.threeOptions &&
		css`
			flex-direction: row;
			button {
				&:nth-of-type(2) {
					margin-left: 10px;
					margin-right: ${props => (props.rtl ? '15px' : '0px')};
				}
				&:nth-of-type(3) {
					margin-top: 10px;
				}
				&:last-of-type {
					margin-top: 10px;
					margin-left: 0;
					margin-right: ${props => (props.rtl ? '0px' : '0px')};
					max-width: ${props => (props.rtl ? 'calc(50% - 10px)' : 'calc(50% - 5px)')};
				}
			}
		`};
`;

const FeedbackWrapper = styled.div`
	margin-top: 20px;
	background-color: white;
	padding: ${props => (props.desktop ? '30px 70px' : '15px')};
	display: flex;
	flex-direction: column;
	box-shadow: ${props =>
		props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)'};

	section {
		align-self: center;
	}

	p {
		margin: 20px 0px;
		font-size: 16px;
		line-height: 28px;
	}

	button {
		width: ${props => (props.desktop ? '365px' : 'auto')};
		margin: ${props => (props.desktop ? 'auto' : '0px')};
	}
`;

const EnlargeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0px 0px;

	p {
		font-size: 14px;
		line-height: 21px;
	}
`;

class PracticeQuestion extends React.PureComponent {
	state = {
		showFeedback: false,
		correct: false,
		feedback: '',
	};

	selectAnswer = option => {
		this.props.updatePracticeResults(option);
		this.setState({ showFeedback: true, correct: option.is_correct, feedback: option.feedback });
	};

	goToNextQuestion = nextQuestion => {
		const {
			history,
			match: {
				params: { id },
			},
		} = this.props;
		if (nextQuestion === 'final') {
			return history.push(`/practice-summary/${id}`);
		}
		return history.push(`/practice/${id}/${nextQuestion}`);
	};

	render() {
		const { topics, match, desktop, history } = this.props;

		const [questions, question, nextQuestion] = topics
			.filter(q => q.id === parseInt(match.params.id, 10))
			.map(t => {
				return [
					t.questions,
					t.questions.filter(q => q.uuid === match.params.question).reduce((memo, q) => q, {}),
					t.questions
						.map((q, index) => {
							if (q.uuid === match.params.question) {
								return t.questions.length === index + 1 ? 'final' : t.questions[index + 1].uuid;
							}
							return false;
						})
						.filter(q => q)
						.reduce((memo, q) => q, {}),
				];
			})
			.reduce((memo, q) => q, {});

		return (
			<Wrapper>
				<DotWrapper desktop={desktop}>
					{questions.map((q, key) => (
						<Dot key={key} className={q.uuid === match.params.question ? 'active' : ''} />
					))}
				</DotWrapper>
				<ImageWrapper
					desktop={desktop}
					onClick={() => history.push(`/practice/${match.params.id}/${question.uuid}/image`)}
				>
					<Image image={question.image} />
				</ImageWrapper>
				{!desktop && !this.state.showFeedback && (
					<EnlargeWrapper onClick={() => history.push(`/practice/${match.params.id}/${question.uuid}/image`)}>
						<ArrowRight style={{ transform: 'rotate(270deg)' }} />
						<p>
							<Trans i18nKey="Click to enlarge">Click to enlarge</Trans>
						</p>
					</EnlargeWrapper>
				)}
				{!this.state.showFeedback && (
					<>
						<h2>{question.text}</h2>
						<ChoicesWrapper
							rtl={i18n.language === 'ar' || i18n.language === 'he'}
							desktop={desktop}
							fourOptions={question.options.length === 4}
							threeOptions={question.options.length === 3}
						>
							{question &&
								question.options &&
								question.options.map((option, key) => (
									<Button key={key} onClick={() => this.selectAnswer(option)}>
										{option.label}
									</Button>
								))}
						</ChoicesWrapper>
					</>
				)}
				{this.state.showFeedback && (
					<FeedbackWrapper desktop={desktop}>
						{this.state.correct && (
							<section>
								<CheckMarkCompletedState width={'22'} height={'22'} color={'#6EB31F'} />
							</section>
						)}
						{!this.state.correct && (
							<section>
								<Cross color={'red'} />
							</section>
						)}
						<p>{this.state.feedback}</p>
						<Button onClick={() => this.goToNextQuestion(nextQuestion)}>
							<Trans i18nKey="next">Next</Trans>
						</Button>
					</FeedbackWrapper>
				)}
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	topics: getTopics(state),
});
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			updatePracticeResults: result => actions.updatePracticeResults(result),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PracticeQuestion));
