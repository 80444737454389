export const SET_MISTAKE_PHOTO = 'SET_PHOTO';

const setMistakePhoto = photo => ({
  type: SET_MISTAKE_PHOTO,
  photo,
});

const camera = {
  setMistakePhoto,
};

export default camera;
