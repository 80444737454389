export const REVIEW_GET_SHOPPING_LIST = 'REVIEW_GET_SHOPPING_LIST';
export const REVIEW_GET_SHOPPING_LIST_STARTED = 'REVIEW_GET_SHOPPING_LIST_STARTED';
export const REVIEW_GET_SHOPPING_LIST_FAILED = 'REVIEW_GET_SHOPPING_LIST_FAILED';
export const REVIEW_ADD_PRODUCT = 'REVIEW_ADD_PRODUCT';
export const REVIEW_REMOVE_PRODUCT = 'REVIEW_REMOVE_PRODUCT';
export const REVIEW_RESET_SHOPPING_LIST = 'REVIEW_RESET_SHOPPING_LIST';
export const QUERY_SEARCH_ENDPOINT = 'QUERY_SEARCH_ENDPOINT';
export const EMPTY_SEARCH_RESULTS = 'EMPTY_SEARCH_RESULTS';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_SEARCHING = 'SET_SEARCHING';

const actions = {
	getShoppingList() {
		return {
			type: REVIEW_GET_SHOPPING_LIST,
		};
	},
	getShoppingListStarted() {
		return {
			type: REVIEW_GET_SHOPPING_LIST_STARTED,
		};
	},
	getShoppingListFailed() {
		return {
			type: REVIEW_GET_SHOPPING_LIST_FAILED,
		};
	},
	addProduct(product) {
		return {
			type: REVIEW_ADD_PRODUCT,
			product,
		};
	},
	removeProduct(index) {
		return {
			type: REVIEW_REMOVE_PRODUCT,
			index,
		};
	},
	resetShoppingList() {
		return {
			type: REVIEW_RESET_SHOPPING_LIST,
		};
	},
	reviewGetSearchResults(query) {
		return {
			type: QUERY_SEARCH_ENDPOINT,
			query,
		};
	},
	setSearchResults(results) {
		return {
			type: SET_SEARCH_RESULTS,
			results,
		};
	},
	setSearching(isSearching) {
		return {
			type: SET_SEARCHING,
			isSearching,
		};
	},
	emptySearchResults() {
		return {
			type: EMPTY_SEARCH_RESULTS,
		};
	},
};

export default actions;
