import React from 'react';

class PlusExpand extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 13.3 13.3"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M12.3,5.7H7.7V1c0-0.6-0.4-1-1-1s-1,0.4-1,1v4.7H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.7v4.7c0,0.6,0.4,1,1,1s1-0.4,1-1V7.7h4.7
	   c0.6,0,1-0.4,1-1S12.9,5.7,12.3,5.7z"
        />
      </svg>
    );
  }
}

PlusExpand.defaultProps = {
  width: 16,
  height: 16,
  color: 'black',
};

export default PlusExpand;
