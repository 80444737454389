import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getTasks } from '../../../store/CmBlocks/cmblocks.reducer';
import Blocks from '../components/Blocks/Blocks';
import theme from '../../../styles/1-settings/theme';

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

class SelectedBlockList extends React.PureComponent {
  getColor = id => {
    switch (id) {
      case 'plan':
        return theme.colors.ORANGE;
      default:
        return theme.colors.GREEN;
    }
  };

  render() {
    const { match, tasks } = this.props;

    return (
      <Wrapper>
        {tasks &&
          tasks[match.params.id] && (
          <Blocks id={match.params.id} tasks={tasks[match.params.id]} color={this.getColor(match.params.id)} />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  tasks: getTasks(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectedBlockList)
);
