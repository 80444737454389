import React, { useEffect, useState } from 'react';
import { getProducts } from '../../store/Products/products.reducer';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import BackArrow from '../../components/icons/BackArrow';
import { getColor } from '../../utils/util';
import { withRouter } from 'react-router';
import actions from '../../store/Products/products.action';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import CameraIcon from '../../components/icons/Camera';

const Image = styled.div`
	display: flex;
	background-image: ${props => `url(${props.photo})`};
	width: 100%;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	padding-bottom: 56.25%;
	background-color: #c7c7c7;
	position: relative;

	button {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background: white;
		width: ${window.innerWidth > 800 ? '120px' : '80px'};
		height: ${window.innerWidth > 800 ? '120px' : '80px'};
		border: none;
	}
`;

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	flex: 1;
	height: ${props => (props.desktop ? 'auto' : '100%')};
	background: white;
	margin: ${props => (props.desktop ? '15px 0px' : '0px')};
	flex-direction: column;
	position: relative;
	overflow: auto;

	header {
		position: absolute;
		top: 15px;
		left: 15px;
		z-index: 1;
	}

	article {
		padding: ${props => (props.desktop ? '60px 80px 30px' : '60px 25px 25px')};
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 0 auto;

		h2 {
			font-size: 20px;
			padding: 30px 0px 15px;
		}

		textarea {
			width: 100%;
			display: flex;
			flex-direction: column;
			flex: 1 0 auto;
			padding: 20px;
			font-size: 16px;
			margin-bottom: 20px;
			resize: none;
			border: 1px solid #d8d8d8;
			&::placeholder {
				opacity: 1;
				transition: opacity 0.2s;
			}
			&:focus::placeholder {
				opacity: 0;
			}
		}
	}
`;

function CommentMistake({
	saveComment,
	products,
	desktop,
	history,
	setMistakePhoto,
	match: {
		params: { categoryType, productId, questionId },
	},
}) {
	let textarea;
	const widthRef = React.createRef();
	const { t } = useTranslation();
	const [width, setWidth] = useState('');
	const [value, setValue] = useState('');

	useEffect(() => {
		setWidth(widthRef && widthRef.current && widthRef.current.offsetWidth);
	}, [widthRef]);

	const _saveComment = () => {
		!photo && setMistakePhoto('', productId, categoryType);
		saveComment(value, productId, categoryType, questionId);

		history.push('/question-final');
	};

	const onEnterPress = e => {
		if (e.keyCode === 13 && e.shiftKey === false) {
			e.preventDefault();
			textarea = document.getElementById('textarea');
			textarea.blur();
		}
	};

	const photo = products
		.filter(p => p.identifier === productId)
		.map(p => {
			return (
				p.mistakeMarked &&
				p.mistakeMarked
					.filter(p => p.categoryType === categoryType)
					.map(p => p.photo)
					.reduce((meme, photo) => photo, '')
			);
		})
		.reduce((memo, photo) => photo, '');

	return (
		<Wrapper ref={widthRef} desktop={desktop}>
			<header onClick={() => history.goBack()}>
				<BackArrow color={'white'} />
			</header>
			<Image width={width} photo={photo}>
				{!photo && (
					<button onClick={() => history.push(`/camera/${productId}/${categoryType}/${questionId}`)}>
						<CameraIcon
							width={window.innerWidth > 800 ? '60' : '40'}
							height={window.innerWidth > 800 ? '60' : '40'}
							color={'black'}
						/>
					</button>
				)}
			</Image>
			<article>
				{/* TEXT AREA BELOW SHOULD BE HIDDEN FOR COUNTRY: GERMANY */}
				<textarea
					id="textarea"
					onKeyDown={onEnterPress}
					placeholder={t('additionalComment', 'Additional comment')}
					value={value}
					onChange={event => setValue(event.target.value)}
				/>
				<Button color={getColor(categoryType)} onClick={_saveComment} disabled={!value}>
					{t('next', 'Next')}
				</Button>
			</article>
		</Wrapper>
	);
}

const mapStateToProps = state => ({
	products: getProducts(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			saveComment: (comment, productId, category, question) =>
				actions.saveComment(comment, productId, category, question),
			setMistakePhoto: (photo, productId, categoryType) => actions.setMistakePhoto(photo, productId, categoryType),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentMistake));
