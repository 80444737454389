import {
	APP_SET_INITIALIZED,
	APP_ADD_LOADER,
	APP_REMOVE_LOADER,
	APP_RESET,
	APP_SET_COUNTRY,
	APP_SET_TEMPORARY_COUNTRY,
	APP_SET_STORE,
	APP_SET_INTRO_DONE,
	APP_SET_COUNTRIES,
	APP_RESET_STORE_COUNTRY,
	APP_RESET_TEMPORARY_COUNTRY,
	APP_SET_TESTBUY_INTRO_DONE,
	APP_SET_AREA,
	APP_SET_PREFETCH_DATA,
	APP_REPLACE_PREFETCH_DATA,
	APP_SET_WEEKLY_STATS,
	APP_SET_LANGUAGE,
} from './app.action';

const initialState = {
	loaders: [],
	countries: [],
	languages: [],
	language: {},
	areas: [],
	area: { name: '' },
	country: { languages: [{ stores: [] }], name: '' },
	temporaryCountry: { languages: [{ stores: [] }], name: '' },
	isIntroDone: false,
	isTestbuyIntroDone: false,
	store: '',
	appInitialized: false,
	prefetchData: [],
	stats: { amount_tested: 0, amount_successful: 0 },
};

const updateData = (prefetchData, { size, area, replace }) =>
	prefetchData.filter(p => p.size !== size || p.area !== area).concat([{ area, size, products: replace }]);

const extractAreas = prefetchData => {
	const uniqueAreas = [...new Set(prefetchData.map(item => item.area))];
	const uniqueAreasObject = uniqueAreas.map(a => ({ name: a }));
	return uniqueAreasObject;
};

const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case APP_ADD_LOADER:
			return Object.assign({}, state, {
				loaders: [...state.loaders, action.key],
			});

		case APP_REMOVE_LOADER:
			const key = action.key;
			return Object.assign({}, state, {
				loaders: state.loaders.filter(loader => loader !== key),
			});
		case APP_SET_INITIALIZED:
			return {
				...state,
				appInitialized: true,
				languages: action.languages,
			};
		case APP_RESET:
			return {
				...initialState,
			};
		case APP_SET_PREFETCH_DATA:
			return {
				...state,
				areas: extractAreas(action.data),
				prefetchData: action.data,
			};
		case APP_REPLACE_PREFETCH_DATA:
			return {
				...state,
				prefetchData: updateData(state.prefetchData, action.products),
			};
		case APP_SET_AREA:
			return {
				...state,
				area: action.area,
			};
		case APP_SET_COUNTRIES:
			return {
				...state,
				countries: action.countries,
			};
		case APP_SET_COUNTRY:
			return {
				...state,
				country: action.country,
			};
		case APP_SET_LANGUAGE:
			return {
				...state,
				language: action.language,
			};
		case APP_SET_TEMPORARY_COUNTRY:
			return {
				...state,
				temporaryCountry: action.temporaryCountry,
			};
		case APP_SET_STORE:
			return {
				...state,
				store: Object.assign({}, action.store, { successful_testbuys: 0, completed_testbuys: 0 }),
			};
		case APP_SET_INTRO_DONE:
			return {
				...state,
				isIntroDone: true,
			};
		case APP_SET_TESTBUY_INTRO_DONE:
			return {
				...state,
				isTestbuyIntroDone: true,
			};
		case APP_RESET_STORE_COUNTRY:
			return {
				...state,
				country: initialState.country,
				store: initialState.store,
				isIntroDone: initialState.isIntroDone,
				stats: initialState.stats,
			};
		case APP_RESET_TEMPORARY_COUNTRY:
			return {
				...state,
				temporaryCountry: initialState.temporaryCountry,
				isIntroDone: initialState.isIntroDone,
			};
		case APP_SET_WEEKLY_STATS:
			return {
				...state,
				stats: action.stats,
			};
		default:
			return state;
	}
};

export default appReducer;

export function appIsInitialized(state) {
	return state.app && state.app.appInitialized;
}

export function appGetShoppingParams(state) {
	const getBaseLanguage = () =>
		state.app.country.languages
			.filter(l => l.code === 'en')
			.reduce((memo, language) => language.code, state.app.country.languages[0].code);

	//First we try to match the language code that the user has chosen.
	const language = state.app.country.languages
		.filter(l => l.code === state.app.language.code)
		.reduce((memo, language) => language.code, getBaseLanguage());

	return (
		state.app && {
			country: state.app.country.code,
			language,
			store: state.app.store.identifier,
			name: state.app.store.name,
		}
	);
}

export function appGetStore(state) {
	return state.app && state.app.store;
}

export function appGetCountry(state) {
	return state.app && state.app.country;
}

export function appGetLanguage(state) {
	return state.app && state.app.language;
}
export function appGetLanguages(state) {
	return state.app && state.app.languages;
}

export function getAreas(state) {
	return state.app && state.app.areas;
}

export function getArea(state) {
	return state.app && state.app.area && state.app.area.name;
}

export function getPrefetchData(state) {
	return state.app && state.app.prefetchData;
}

export function getAllAreasName(state) {
	return state.app && state.app.areas && state.app.areas[0] && state.app.areas[0].name;
}

export function getTotalItemsInArea(state) {
	const data =
		state.app &&
		state.app.area &&
		state.app.area.name &&
		state.app.prefetchData &&
		state.app.prefetchData.filter(a => a.area === state.app.area.name);

	if (data && data.length > 0) {
		return data[0].total;
	} else {
		return 0;
	}
}
