// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { appIsInitialized } from '../../store/App/app.reducer';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import img from '../../assets/images/IKEA-logo.svg';
import { getProducts } from '../../store/Products/products.reducer';

export const LoadingImageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background-color: white;

	footer {
		position: absolute;
		bottom: 40px;
	}
`;

export const LoadingImage = styled.div`
	width: 300px;
	height: 300px;
	background-image: ${`url(${img})`};
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`;

class AppReadyWaiter extends React.PureComponent {
	state = {
		showLoading: false,
	};

	componentDidMount() {
		const { products, location } = this.props;
		let url = '/';

		if (products.length && location.pathname === '/') {
			url = '/shopping-list';
		}

		if (products.length && location.pathname !== '/') {
			url = location.pathname;
		}

		this.props.history.push(url);

		window.addEventListener(
			'show_loading',
			function(evt) {
				this.setState({ showLoading: true });
			}.bind(this)
		);
	}

	render() {
		const { appIsReady, children } = this.props;
		return !appIsReady || this.state.showLoading ? (
			<LoadingImageWrapper>
				<LoadingImage />
				<footer>© Inter IKEA Systems B.V. 2018</footer>
			</LoadingImageWrapper>
		) : (
			<React.Fragment>{children}</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	appIsReady: appIsInitialized(state),
	products: getProducts(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppReadyWaiter));
