import React from 'react';

class Camera extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 33.3 22.2"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path
          d="M32.6,2.8h-6.5l-1.1-2.4C24.8,0.2,24.6,0,24.3,0H9C8.8,0,8.5,0.2,8.4,0.4L7.2,2.8H0.8C0.3,2.8,0,3.1,0,3.5v17.9
     c0,0.4,0.3,0.8,0.8,0.8h31.8c0.4,0,0.8-0.3,0.8-0.8V3.5C33.3,3.1,33,2.8,32.6,2.8z M16.7,16.9c-3,0-5.5-2.5-5.5-5.5
     c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5C22.1,14.5,19.7,16.9,16.7,16.9z"
        />
      </svg>
    );
  }
}

Camera.defaultProps = {
  width: 100,
  height: 100,
  color: '#a7a7a7',
};

export default Camera;
