import React from 'react';
import styled from 'styled-components';
import CheckMarkCompletedState from './icons/CheckMarkCompletedState';
import { ProductImage } from '../modules/TestBuy/components/Product';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  background-color: white;
  padding: ${props => (props.desktop ? '15px 60px' : '25px')};
  border-bottom: 1px solid #c8c8c8;

  .info {
    display: flex;
    flex-direction: column;

    h1 {
      font-weight: bold;
      line-height: 22px;
      margin: 0px;
    }

    h4 {
      font-size: ${props => (props.desktop ? '14px' : '12px')};
    }
  }

  .icon-container {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

function ProductCard({ desktop, mistake, onClick, icon }) {
  const { t } = useTranslation();
  return (
    <Wrapper desktop={desktop} onClick={onClick} className="ProductCard">
      <ProductImage className="ProductImage" image={mistake.product.image} />
      <div className="info">
        <h4>{format(mistake.mistakes[0].date, 'DD/MM/YYYY')}</h4>
        <h1>{mistake.product.name}</h1>
        <h4>
          {mistake.mistakes.length} {mistake.mistakes.length > 1 ? t('mistakes', 'Mistakes') : t('mistake', 'Mistake')}
        </h4>
      </div>
      {icon && (
        <div className="icon-container">
          <CheckMarkCompletedState />
        </div>
      )}
    </Wrapper>
  );
}

export default ProductCard;
