import React from 'react';

class LearnAssessSolution extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 52.48 47.785"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M52.265 30.537l-6.421-6.421a14.1 14.1 0 0 0 4.331-10.08A14.117 14.117 0 0 0 36.137 0a13.947 13.947 0 0 0-12.543 7.765l-.075.075v.075a17.652 17.652 0 0 0-1.195 3.509l-3.21-1.344a.818.818 0 0 0-.6 0L.448 17.7c-.075 0-.149.075-.224.149a.073.073 0 0 1-.075.075c-.075.075-.075.075-.075.149v.075c-.075 0-.075.149-.075.224H0v21.2a.653.653 0 0 0 .448.672l18.143 7.541h.3a.449.449 0 0 0 .3-.075l18.143-7.466a.718.718 0 0 0 .448-.672V27.924a15.292 15.292 0 0 0 1.792-.373.274.274 0 0 0 .224-.075 14.205 14.205 0 0 0 4.853-2.464l6.5 6.5a.722.722 0 0 0 1.045 0 .594.594 0 0 0 .069-.975zM36.137 1.493a12.526 12.526 0 0 1 4.181 24.34V14.485a.564.564 0 0 0-.075-.3.161.161 0 0 0-.149-.149l-.075-.075c-.075-.075-.149-.075-.224-.149L25.311 7.765a12.52 12.52 0 0 1 10.826-6.272zm0 25.012a12.507 12.507 0 0 1-10.378-5.525L38.75 15.6v10.6a10.023 10.023 0 0 1-2.613.306zm-11.5-17.4l12.917 5.376-12.542 5.23a12.36 12.36 0 0 1-1.419-5.749 11.8 11.8 0 0 1 1.046-4.853zm-5.747 2.468l3.285 1.344c0 .373-.075.672-.075 1.045a13.806 13.806 0 0 0 1.867 6.944c0 .075.075.149.149.224a8.807 8.807 0 0 0 .971 1.344l-6.122 2.539-16.2-6.72zm-17.4 7.914l16.65 6.944v19.562L1.493 39.049zm34.797 19.562l-16.65 6.944V26.431l6.421-2.688a13.992 13.992 0 0 0 10 4.256h.149v11.05z"/>
      </svg>
    )
  }
}

LearnAssessSolution.defaultProps = {
  width: 50,
  height: 50,
  color: 'black',
};

export default LearnAssessSolution;