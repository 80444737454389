import React from 'react';
import styled, { css } from 'styled-components';

const StyledCard = styled.article`
	padding: ${props => (props.desktop ? '50px 70px' : '40px 20px 30px')};
	padding-left: ${props => (props.wBackArrow ? '30px' : 'auto')};
	padding-top: ${props => (props.wBackArrow ? '30px' : 'auto')};
	background-color: white;
	border-radius: 2px;
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
	display: flex;
	flex-direction: column;
	justify-content: ${props => (props.spaced ? 'space-between' : 'flex-start')};
	${props =>
		props.stretch &&
		css`
			flex: 2 1;
		`};

	${props =>
		props.small &&
		css`
			padding: 20px;
			flex: 1 0 auto;
		`};
`;

const CardIcon = styled.section`
	margin: 0px 10px 20px;
	display: flex;
	justify-content: center;
`;

const CardHeader = styled.header`
	line-height: 30px;
	font-size: 18px;
	margin: 10px 0px;
`;

const CardBody = styled.h3`
	font-size: 14px;
	color: gray;
	margin-bottom: 20px;
`;

const CardButton = styled.section`
	margin-top: auto;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	justify-content: flex-end;
`;

export default class Card extends React.PureComponent {
	static Header = CardHeader;
	static Body = CardBody;
	static Button = CardButton;
	static Icon = CardIcon;

	render() {
		const { children, fullWidth, fixBottom, stretch, style, className, spaced, desktop, wBackArrow } = this.props;
		const styles = Object.assign({}, style);
		const small = window.innerWidth < 500;
		return (
			<StyledCard
				small={small}
				className={className}
				style={styles}
				fullWidth={fullWidth}
				spaced={spaced}
				wBackArrow={wBackArrow}
				desktop={desktop}
				fixBottom={fixBottom}
				stretch={stretch}
			>
				{children}
			</StyledCard>
		);
	}
}
