import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import MistakeList from '../../components/MistakeList';
import { connect } from 'react-redux';
import Product from './components/Product';
import { getMistake } from '../../store/Mistakes/mistakes.reducer';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => (props.desktop ? 'auto' : 'calc(100% - 60px)')};
  overflow-y: auto;
  width: 100%;
  background-color: ${props => props.theme.colors.GREY};
  margin: ${props => (props.desktop ? '20px 0px 0px' : '0px')};
`;

const Mistake = styled.article`
    padding: ${props => (props.desktop ? '20px 0px' : '15px')};
    
    header {
        font-size: ${props => (props.desktop ? '28px' : '20px')};
        line-height: 24px;
        font-weight: bold;
      }
    }
  }
`;

function ProductDetail({ match, mistake, desktop }) {
  const { t } = useTranslation();
  return (
    <Wrapper desktop={desktop} className="ProductDetail">
      <Product id={match.params.id} product={mistake.product} area={mistake.area} products={[]} desktop={desktop} />
      <Mistake desktop={desktop}>
        <header>{t('mistakes', { defaultValue: 'Mistakes' })}</header>
        <MistakeList desktop={desktop} mistake={mistake} />
      </Mistake>
    </Wrapper>
  );
}

const mapStateToProps = (state, ownProps) => ({
  mistake: getMistake(state, ownProps),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductDetail)
);
