import React from 'react';

class CrossTestbuyQuestions extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 24.7 24.7"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
         <path d="M15.5 12.3l7.6-7.6c.9-.9.9-2.3 0-3.2-.9-.9-2.3-.9-3.2 0l-7.6 7.6-7.5-7.5c-.9-.9-2.3-.9-3.2 0-.9.9-.9 2.3 0 3.2l7.6 7.6L1.6 20c-.9.9-.9 2.3 0 3.2.4.4 1 .7 1.6.7s1.2-.2 1.6-.7l7.6-7.6 7.6 7.6c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.9-.9.9-2.3 0-3.2l-7.7-7.7z"/>
      </svg>
    );
  }
}

CrossTestbuyQuestions.defaultProps = {
  width: 20,
  height: 20,
  color: 'black',
};

export default CrossTestbuyQuestions;
