import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '../../store/App/app.action';
import styled from 'styled-components';
import { PageWrapper } from '../../components/PageWrapper';
import { SelectList, SelectListItem } from '../../components/Form/SelectList';
import ArrowRightDesktop from '../../components/icons/ArrowRightDesktop';
import { useTranslation } from 'react-i18next';

const Wrapper = styled(PageWrapper)`
  padding: ${props => (props.desktop ? '20px 50px' : '0px')};
  margin: auto;
  height: ${props => (props.desktop ? 'calc(100vh - 170px)' : 'calc(100% - 60px)')};
  width: 100%;
  box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};
  position: relative;

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
    }

    div {
      width: 40px;
    }
  }
`;

const ButtonContainer = styled.section`
  position: absolute;
  top: 50%;
  transform: ${props => (props.left ? 'rotate(180deg)' : 'none')};
  right: ${props => (props.left ? 'auto' : '20px')};
  left: ${props => (props.left ? '20px' : 'auto')};
  opacity: ${props => (props.disabled ? '0.2' : '1')};
`;

function SelectLang({ languages, setLanguage, getCountries, history, desktop, store, location }) {
  const scroller = React.createRef();
  const [activeLeft, setActiveLeft] = useState(false);
  const [activeRight, setActiveRight] = useState(true);
  const [lastClickedLeft, setLastClickedLeft] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const selectLanguage = language => {
    setLanguage(language);
    history.push('/home');
  };

  const onClickRight = () => {
    scroller.current.scrollLeft += 800;
    if (scroller.current.scrollLeft % 8 !== 0 && lastClickedLeft) {
      setActiveLeft(true);
      setLastClickedLeft(false);
      setActiveRight(false);
    } else {
      setActiveLeft(true);
      setActiveRight(true);
    }
  };

  const onClickLeft = () => {
    scroller.current.scrollLeft -= 800;
    if (scroller.current.scrollLeft === 0) {
      setActiveLeft(false);
      setLastClickedLeft(true);
      setActiveRight(true);
    } else {
      setActiveLeft(true);
      setActiveRight(true);
    }
  };

  return (
    <Wrapper desktop={desktop}>
      {desktop && (
        <header>
          <div />
          <h1>{t('selectLanguage.choose', 'Choose language')}</h1>
          <div />
        </header>
      )}
      {desktop && (
        <ButtonContainer disabled={!activeLeft} onClick={onClickLeft} left>
          <ArrowRightDesktop width={'22'} height={'22'} />
        </ButtonContainer>
      )}
      <SelectList ref={scroller} desktop={desktop}>
        {languages &&
          languages.map((c, key) => (
            <SelectListItem desktop={desktop} key={key} onClick={() => selectLanguage(c)}>
              <span>{c.name}</span>
            </SelectListItem>
          ))}
      </SelectList>
      {desktop && (
        <ButtonContainer disabled={!activeRight} onClick={onClickRight}>
          <ArrowRightDesktop width={'22'} height={'22'} />
        </ButtonContainer>
      )}
    </Wrapper>
  );
}

const mapStateToProps = state => ({
  languages: state.app && state.app.languages,
  store: state.app && state.app.store,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountries: () => actions.appGetCountries(),
      setLanguage: language => actions.appSetLanguage(language),
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectLang));
