import React from 'react';

class MinusCollapse extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 13 2"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <path d="M12,2H1C0.4,2,0,1.6,0,1s0.4-1,1-1h11c0.6,0,1,0.4,1,1S12.6,2,12,2z" />
      </svg>
    );
  }
}

MinusCollapse.defaultProps = {
  width: 16,
  height: 16,
  color: 'black',
};

export default MinusCollapse;
