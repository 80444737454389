import React from 'react';
import styled from 'styled-components';
import BackArrow from '../../components/icons/BackArrow';
import { withRouter } from 'react-router';
import { getTopics } from '../../store/Learn/learn.reducer';
import { connect } from 'react-redux';
import BackArrowDesktop from '../../components/icons/BackArrowDesktop';
import { appGetLanguage, appGetCountry } from '../../store/App/app.reducer';

const Wrapper = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: 100%;
	height: ${props => (props.desktop ? 'auto' : '100%')};
	background-color: ${props => (props.desktop ? 'white' : 'black')};
	align-items: center;
	padding: ${props => (props.desktop ? '80px 30px 30px' : '0px')};
	margin: ${props => (props.desktop ? '20px 0px' : '0px')};
	box-shadow: ${props => (props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : 'none')};

	header {
		position: absolute;
		top: ${props => (props.desktop ? '30px' : '15px')};
		left: ${props => (props.desktop ? '30px' : '15px')};
	}

	h2 {
		font-weight: bold;
		font-size: 32px;
		margin-right: auto;
	}

	p {
		font-size: 18px;
		line-height: 28px;
	}

	section {
		overflow: auto;
		max-height: 400px;
		margin-right: auto;
	}
`;

const isDefault = (code, language, country) => {
	if (country.name === 'Canada' && language.code === 'fr-ca' && code === 'fr_ca') {
		return true;
	}

	return language.code === code;
};

class Video extends React.PureComponent {
	render() {
		const { desktop, topics, match, history, language, country } = this.props;
		const topic = topics
			.filter(q => q.id === parseInt(match.params.id, 10))
			.map(q => q.learning_materials.filter(m => m.uuid === match.params.material).reduce((memo, m) => m, {}))
			.reduce((memo, q) => q, {});

		return (
			<>
				{desktop && (
					<div style={{ overflow: 'auto' }}>
						<Wrapper desktop={desktop} className="Learn__Video">
							<header onClick={() => history.goBack()}>
								<BackArrowDesktop />
							</header>
							<h2>{topic.title}</h2>
							<video
								style={{ marginTop: '20px', marginBottom: '30px' }}
								width={'100%'}
								src={topic.video}
								controls
								autoPlay={false}
								crossOrigin="anonymous"
							>
								{topic.subtitles &&
									topic.subtitles.map((track, key) => (
										<track
											key={key}
											default={isDefault(track.language, language, country)}
											label={track.name}
											kind="subtitles"
											src={track.subtitle}
										/>
									))}
							</video>
							<section>
								<p>{topic.description}</p>
							</section>
						</Wrapper>
					</div>
				)}
				{!desktop && (
					<Wrapper desktop={desktop}>
						<header onClick={() => history.goBack()}>
							<BackArrow color={'white'} />
						</header>
						<video width={'100%'} src={topic.video} controls autoPlay={false} crossOrigin="anonymous">
							{topic.subtitles &&
								topic.subtitles.map((track, key) => (
									<track
										key={key}
										default={isDefault(track.language, language, country)}
										label={track.name}
										kind="subtitles"
										src={track.subtitle}
									/>
								))}
						</video>
					</Wrapper>
				)}
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	topics: getTopics(state),
	language: appGetLanguage(state),
	country: appGetCountry(state),
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Video));
