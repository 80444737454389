import React from 'react';

class RotateScreen extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 151.859 78.22"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <g transform="translate(-120 -350)">
          <path
            fill="#1d1d1b"
            d="M2.81,78.22a3.824,3.824,0,0,1-2.72-2.8,4.526,4.526,0,0,1-.07-1.21c0-22.12.01-44.34.01-66.46.04-1.3-.04-2.52,0-3.82A3.58,3.58,0,0,1,2.63.18c.1-.09.2-.08.21-.18L38.53.01a3.966,3.966,0,0,1,2.82,2.81,5.28,5.28,0,0,1,.06,1.31c.05,14.89,0,29.86.05,44.75.04,8.25,0,16.39.04,24.64-.04,2.31-.8,3.84-2.9,4.62C38.5,78.23,2.81,78.22,2.81,78.22Zm-.48-9.8,36.5-.01L38.76,7.47l-36.5.01Zm20.7,4.87a2.478,2.478,0,0,0-2.46-2.47,2.4,2.4,0,0,0-2.47,2.46,2.478,2.478,0,0,0,2.46,2.47h0a2.454,2.454,0,0,0,2.47-2.46Z"
            transform="translate(119.999 350)"
          />
          <path
            fill="#1d1d1b"
            d="M2.81,78.22a3.824,3.824,0,0,1-2.72-2.8,4.526,4.526,0,0,1-.07-1.21c0-22.12.01-44.34.01-66.46.04-1.3-.04-2.52,0-3.82A3.58,3.58,0,0,1,2.63.18c.1-.09.2-.08.21-.18L38.53.01a3.966,3.966,0,0,1,2.82,2.81,5.28,5.28,0,0,1,.06,1.31c.05,14.89,0,29.86.05,44.75.04,8.25,0,16.39.04,24.64-.04,2.31-.8,3.84-2.9,4.62C38.5,78.23,2.81,78.22,2.81,78.22Zm-.48-9.8,36.5-.01L38.76,7.47l-36.5.01Zm20.7,4.87a2.478,2.478,0,0,0-2.46-2.47,2.4,2.4,0,0,0-2.47,2.46,2.478,2.478,0,0,0,2.46,2.47h0a2.454,2.454,0,0,0,2.47-2.46Z"
            transform="translate(271.859 386.359) rotate(90)"
          />
          <path
            d="M26.54,18.77A27.223,27.223,0,0,0,.75,0,.755.755,0,0,0,0,.75a.755.755,0,0,0,.75.75A25.716,25.716,0,0,1,25.02,18.96l-4.06-1.63L27.1,27.74,30.01,16Z"
            transform="translate(172 352)"
          />
        </g>
      </svg>
    );
  }
}

RotateScreen.defaultProps = {
  width: 100,
  height: 100,
  color: '#a7a7a7',
};

export default RotateScreen;
