import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import BackArrowDesktop from '../../../components/icons/BackArrowDesktop';
import { ProductImage } from '../../TestBuy/components/Product';
import { useTranslation } from 'react-i18next';

const Product = styled.article`
	background-color: white;
	margin: ${props => (props.desktop ? '0px' : '15px')};
	padding: ${props => (props.desktop ? '30px' : '25px')};
	box-shadow: ${props =>
		props.desktop ? '0px 15px 25px 0px rgba(0, 0, 0, 0.05)' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)'};
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	nav {
		margin-bottom: 20px;
	}

	.productInfo {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex: 1;
		padding-left: ${props => (props.desktop ? '40px' : '0px')};
		padding-right: ${props => (props.desktop ? '40px' : '0px')};

		header {
			font-weight: bold;
			font-size: ${props => (props.desktop ? '32px' : '20px')};
			line-height: ${props => (props.desktop ? '40px' : '24px')};
		}
	}

	h3 {
		font-size: ${props => (props.desktop ? '18px' : '14px')};
		line-height: 32px;
	}
`;

const LocationTag = styled.section`
	background-color: ${props => props.theme.colors.GREY};
	margin-top: 10px;
	margin-left: ${props => (props.desktop ? '40px' : '0px')};
	padding: ${props => (props.desktop ? '10px 20px' : '0px 10px')};
	width: ${props => (props.desktop ? 'calc(100% - 80px)' : 'fit-content')};
	line-height: 32px;
`;

function _Product({ product = {}, desktop, history, area }) {
	const { t } = useTranslation();
	return (
		<Product desktop={desktop}>
			{desktop && (
				<nav>
					<BackArrowDesktop className="BackArrowDesktop" onClick={() => history.goBack()} />
				</nav>
			)}
			<div style={{ display: 'flex' }}>
				{!desktop && <ProductImage image={product.image} />}
				<section className="productInfo">
					<header>{product.name}</header>
					<h3>{product.identifier}</h3>
				</section>
			</div>
			{area && (
				<LocationTag desktop={desktop}>
					<h3>
						{t('area', { defaultValue: 'Area' })}: {area}
					</h3>
				</LocationTag>
			)}
		</Product>
	);
}

export default withRouter(_Product);
