import React from 'react';
import Header from './components/Header/Header';
import { withRouter } from 'react-router';
import Routes from './Routes';
import { withBreakpoints } from 'react-breakpoints';

class Application extends React.PureComponent {
  render() {
    const { location, breakpoints, currentBreakpoint } = this.props;
    const isQuestion =
      breakpoints[currentBreakpoint] < breakpoints.desktop && location.pathname.indexOf('question') !== -1;
    const isCamera = location.pathname.indexOf('camera') !== -1;
    return (
      <>
        {!isQuestion && !isCamera && <Header />}
        <Routes />
      </>
    );
  }
}

export default withBreakpoints(withRouter(Application));
