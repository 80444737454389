import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ShoppingBasket from '../../components/icons/ShoppingBasket';
import ShoppingBasketCheckMark from '../../components/icons/ShoppingBasketCheckMark';
import ArrowRight from '../../components/icons/ArrowRight';
import theme from '../../styles/1-settings/theme';
import { getProducts } from '../../store/Products/products.reducer';
import actions from '../../store/App/app.action';
import getYear from 'date-fns/get_year';
import getISOWeek from 'date-fns/get_iso_week';
import { subWeeks, addWeeks } from 'date-fns';
import { Trans } from 'react-i18next';

const NavBlock = styled.article`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: white;
	flex: 1;
	margin-top: ${props => (props.desktop ? '0px' : '15px')};
	cursor: pointer;
	padding: 0px 40px;
	box-shadow: ${props => (props.desktop ? 'none' : '0px 1px 2px 0px rgba(0, 0, 0, 0.16)')};
	border-bottom: ${props => (props.desktop ? `1px solid ${props.theme.colors.GREY}` : 'none')};
	font-weight: bold;
	border-left: ${props => (props.active ? `5px solid ${props.theme.colors.BLACK}` : 'none')};
`;

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	height: ${props => (props.desktop ? 'calc(100vh - 80px)' : 'calc(100vh - 56px)')};
	flex-direction: column;
	background-color: ${props => (props.desktop ? props.theme.colors.WHITE : props.theme.colors.GREY)};
	border: ${props => (props.desktop ? `1px solid ${props.theme.colors.GREY}` : 'none')};
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 20px 15px 0px 15px;
	width: 100%;
	font-weight: bold;
	height: ${props => (props.desktop ? '250px' : 'auto')};
	border-bottom: ${props => (props.desktop ? `1px solid ${props.theme.colors.GREY}` : 'none')};

	h1 {
		align-self: center;
		font-size: 36px;
	}

	h4 {
		width: 60%;
		align-self: center;
	}

	div {
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: center;
	}

	.stat-container {
		flex-direction: row;
		padding: ${props => (props.desktop ? '25px 10px 0px' : '25px 0px 0px')};

		.ArrowRight {
			transform: rotate(180deg);
		}
	}

	section {
		align-self: flex-start;
		padding-top: ${props => (props.desktop ? '60px' : '20px')};
	}
`;

const BlocksContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
	padding: ${props => (props.desktop ? 0 : '0 15px 15px')};
`;

const ArrowContainer = styled.article`
	padding-left: ${props => (props.left ? '0px' : '15px')};
	padding-right: ${props => (props.left ? '15px' : '0px')};
	padding-bottom: 20px;
`;

class _Home extends React.PureComponent {
	componentDidMount() {
		this.props.getNewWeek(getISOWeek(this.state.currentDate), getYear(this.state.currentDate));
	}

	state = {
		currentDate: new Date(),
	};

	getNextWeek = direction => {
		const { getNewWeek } = this.props;
		const currentDate =
			direction === 'older' ? subWeeks(this.state.currentDate, 1) : addWeeks(this.state.currentDate, 1);
		this.setState({ currentDate }, () =>
			getNewWeek(getISOWeek(this.state.currentDate), getYear(this.state.currentDate))
		);
	};

	isActive = urls => {
		const { pathname } = this.props.location;
		if (urls.length > 1) {
			for (let i = 0; i < urls.length; i++) {
				if (pathname.includes(urls[i])) {
					return true;
				}
			}
		}
		return pathname.includes(urls);
	};

	canGoFOrward = () => {
		let year = getYear(new Date());
		let week = getISOWeek(new Date());

		return getISOWeek(this.state.currentDate) < week ? true : getYear(this.state.currentDate) < year;
	};

	render() {
		const { history, desktop, store, products, stats } = this.props;
		return (
			<Wrapper desktop={desktop} className="Home">
				<Header desktop={desktop}>
					{!desktop && <h2>{store.name}</h2>}
					<div className="stat-container">
						<ArrowContainer left className="Home__ArrowContainer">
							<ArrowRight className="ArrowRight" onClick={() => this.getNextWeek('older')} />
						</ArrowContainer>
						<div>
							<ShoppingBasket />
							<h1>{stats.amount_tested}</h1>
						</div>
						<section>
							<Trans i18nKey="week">Week</Trans> {getISOWeek(this.state.currentDate)}
						</section>
						<div>
							<ShoppingBasketCheckMark />
							<h1 style={{ color: stats.amount_successful >= 85 ? theme.colors.LIGHT_GREEN : theme.colors.RED }}>
								{stats.amount_successful}%
							</h1>
						</div>
						<ArrowContainer className="Home__ArrowContainer">
							{this.canGoFOrward() && <ArrowRight className="ArrowRight" onClick={() => this.getNextWeek('newer')} />}
						</ArrowContainer>
					</div>
				</Header>
				<BlocksContainer desktop={desktop}>
					<NavBlock
						onClick={() => history.push(products.length ? '/shopping-list' : '/select-area')}
						desktop={desktop}
						active={desktop && this.isActive(['/testbuy', '/shopping-list', '/question'])}
					>
						<h1>
							<Trans i18nKey="testbuy">Test buy</Trans>
						</h1>
						{!desktop && <ArrowRight />}
					</NavBlock>
					<NavBlock
						onClick={() => history.push('/store-stream')}
						desktop={desktop}
						active={desktop && this.isActive(['/store-stream'])}
					>
						<h1>
							<Trans i18nKey="todoList">To-do list</Trans>
						</h1>
						{!desktop && <ArrowRight />}
					</NavBlock>
					<NavBlock
						onClick={() => history.push('/learn')}
						desktop={desktop}
						active={desktop && this.isActive(['/learn', '/practice'])}
					>
						<h1>
							<Trans i18nKey="learnPractice">Learn and practise</Trans>
						</h1>
						{!desktop && <ArrowRight />}
					</NavBlock>
					<NavBlock
						onClick={() => history.push('/review')}
						desktop={desktop}
						active={desktop && this.isActive(['/review', '/review/search'])}
					>
						<h1>
							<Trans i18nKey="review">Review</Trans>
						</h1>
						{!desktop && <ArrowRight />}
					</NavBlock>
					<NavBlock onClick={() => history.push('/faq')} desktop={desktop} active={desktop && this.isActive(['/faq'])}>
						<h1>
							<Trans i18nKey="faq">FAQ</Trans>
						</h1>
						{!desktop && <ArrowRight />}
					</NavBlock>
				</BlocksContainer>
			</Wrapper>
		);
	}
}

const mapStateToProps = state => ({
	store: state.app && state.app.store,
	stats: state.app && state.app.stats,
	products: getProducts(state),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			getNewWeek: (week, year) => actions.appGetWeeklyStats(week, year),
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(_Home));
