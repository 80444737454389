import React from 'react';
import styled from 'styled-components';
import Button from '../../components/Form/Button/Button';
import Card from '../../components/Card';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTopics } from '../../store/Learn/learn.reducer';
import TopicIcon from '../../components/TopicIcon';
import actions from '../../store/Learn/learn.action';
import { Trans } from 'react-i18next';

const Wrapper = styled.article`
  padding: ${props => (props.desktop ? '20px 0px' : '15px 15px 80px')};
  display: flex;
  flex-direction: column;
  height: ${props => (props.desktop ? '100%' : 'calc(100% - 60px)')};
  background-color: ${props => props.theme.colors.GREY};

  .Card {
    padding: 110px 70px 150px;
    .CardIcon {
      padding: 40px;
    }
    h1 {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

class PracticeIntro extends React.PureComponent {
  componentDidMount() {
    const { setPracticeTotal, startPractice, topics, match } = this.props;
    const topic = topics.filter(q => q.id === parseInt(match.params.id, 10)).reduce((memo, q) => q, {});
    startPractice();
    setPracticeTotal(topic.questions.length);
  }
  render() {
    const { desktop, history, topics, match } = this.props;

    const topic = topics.filter(q => q.id === parseInt(match.params.id, 10)).reduce((memo, q) => q, {});

    return (
      <Wrapper desktop={desktop}>
        <Card className={desktop ? 'Card' : 'Small'}>
          <Card.Icon className="CardIcon">
            <TopicIcon topic={topic.id} width={desktop ? '80' : '65'} height={desktop ? '80' : '65'} />
          </Card.Icon>
          <Card.Header>
            <h1>
              <Trans i18nKey="practiceInfo.header">
                In this exercise you will see photos of sales areas. Have a close look at them and answer the 10
                questions.
              </Trans>
            </h1>
            <h1>
              <Trans i18nKey="Good luck!">Good luck!</Trans>
            </h1>
          </Card.Header>
          <Card.Button>
            <Button desktop={desktop} onClick={() => history.push(`/practice/${topic.id}/${topic.questions[0].uuid}`)}>
              <Trans i18nKey="go">Go!</Trans>
            </Button>
          </Card.Button>
        </Card>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  topics: getTopics(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPracticeTotal: length => actions.setPracticeTotal(length),
      startPractice: () => actions.startPractice(),
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PracticeIntro));
