// @flow
import { call, put, takeLatest, select } from 'redux-saga/effects';
import actions, { PRODUCTS_GET_SHOPPING_LIST, PRODUCTS_REPLACE_PRODUCT_STARTED } from './products.action';
import type { Saga } from 'redux-saga';
import { getArea, getPrefetchData, appGetShoppingParams } from '../App/app.reducer';
import { getProductsSize, getProducts } from './products.reducer';
import { fetchQuestions } from '../Questions/questions.saga';
import { getReplacedProduct } from '../../utils/api';

export function* productsGetShoppingList(): Saga<void> {
	try {
		yield put(actions.getShoppingListStarted());
		yield call(fetchQuestions);

		const area = yield select(getArea);
		const size = yield select(getProductsSize);
		const products = yield select(getPrefetchData);

		let selectedProduct = products
			.filter(p => p.size === size && p.area === area)
			.reduce((memo, p) => p.products, false);

		if (selectedProduct) {
			yield put(actions.settShoppingList(selectedProduct));
		} else {
			selectedProduct = products
				.slice(0, 2)
				.filter(p => p.size === size)
				.reduce((memo, p) => p.products, false);

			yield put(actions.settShoppingList(selectedProduct));
		}
	} catch (e) {
		console.error(e);
		yield put(actions.getShoppingListFailed());
	}
}

export function* replaceProduct(payload): Saga<void> {
	try {
		// pass ids of all items in the list, country, store, language, area
		const info = yield select(appGetShoppingParams);
		const area = yield select(getArea);
		const shoppingList = yield select(getProducts);
		const productsIds = shoppingList.map(p => p.identifier);
		const productToReplace = payload.product;
		const data = { ...info, area, products_ids: productsIds };
		const response = yield call(getReplacedProduct, data);
		if (response) {
			yield put(actions.replaceProduct({ response, productToReplace }));
		}
	} catch (e) {
		console.error(e);
		yield put(actions.replaceProductFailed());
	}
}

export function* productsGetShoppingListListener(): Saga<void> {
	yield takeLatest(PRODUCTS_GET_SHOPPING_LIST, productsGetShoppingList);
}

export function* replaceProductListener(): Saga<void> {
	yield takeLatest(PRODUCTS_REPLACE_PRODUCT_STARTED, replaceProduct);
}

export default [productsGetShoppingListListener, replaceProductListener];
