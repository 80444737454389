import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/Form/Button/Button';
import CheckMark from '../../../components/icons/CheckMarkTestbuyQuestions';
import { getColor } from '../../../utils/util';
import theme from '../../../styles/1-settings/theme';
import DesktopCross from '../../../components/icons/DesktopCross';
import { useTranslation, Trans } from 'react-i18next';

const Content = styled.article`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;

	form {
		display: flex;
		margin: 10px 0px;

		li {
			font-size: 18px;
			line-height: 30px;
			display: flex;
			padding-left: 30px;
			padding-bottom: 5px;
		}

		textarea {
			width: 100%;
			font-size: 16px;
			padding: 15px;
			resize: none;
			border: 1px solid #d8d8d8;
			&::placeholder {
				opacity: 1;
				transition: opacity 0.2s;
			}
			&:focus::placeholder {
				opacity: 0;
			}
		}
	}
`;

const Checkbox = styled.section`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${props => props.theme.colors.PINK};
	margin-right: 20px;
	background-color: ${props => (props.checkedButton ? props.color : 'white')};
	color: white;
	font-weight: bold;
	align-self: center;
`;

const StyledSection = styled.section`
	display: flex;
	flex-direction: column;
	margin-bottom: ${props => (props.last ? '0px' : '15px')};
	text-align: center;
`;

const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: auto;
`;

const StyledButton = styled.button`
	background-color: ${props => (props.color ? props.color : props.theme.colors.BLUE)};
	font-weight: bold;
	color: ${props => (props.empty ? 'black' : 'white')};
	font-size: ${props => (props.desktop ? '18px' : '16px')};
	width: ${props => (props.block ? '100%' : 'auto')};
	cursor: pointer;
	padding: 15px;
	height: 68px;
	border: none;
`;

export class YesNoQuestion extends React.PureComponent {
	render() {
		const { goTo, category, desktop, question } = this.props;
		const yes = question.options.filter(o => o.label.toLowerCase() === '✓')[0];
		const no = question.options.filter(o => o.label.toLowerCase() === '✗')[0];

		return (
			<Footer>
				<StyledSection>
					<Button color={getColor(category)} onClick={() => goTo(yes.next_question, yes.make_issue)}>
						<CheckMark
							color={getColor(category) === theme.colors.YELLOW ? 'black' : 'white'}
							width={desktop ? '25' : '16'}
							height={desktop ? '25' : '16'}
						/>
					</Button>
				</StyledSection>
				<StyledSection last>
					<Button color={getColor(category)} onClick={() => goTo(no.next_question, no.make_issue)}>
						<DesktopCross
							color={getColor(category) === theme.colors.YELLOW ? 'black' : 'white'}
							width={desktop ? '25' : '16'}
							height={desktop ? '25' : '16'}
						/>
					</Button>
				</StyledSection>
			</Footer>
		);
	}
}

export const MultipleButton = ({ question, goTo, category, desktop }) => (
	<footer style={{ marginTop: '20px' }}>
		{question.options &&
			question.options.map((option, key) => (
				<StyledSection key={key}>
					<StyledButton
						desktop={desktop}
						color={getColor(category)}
						onClick={() => goTo(option.next_question, option.make_issue)}
					>
						{option.label}
					</StyledButton>
				</StyledSection>
			))}
	</footer>
);

export class MultipleCheckbox extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			options: props.question.options,
			somethingChecked: props.question.options.filter(o => o.selected).reduce(() => true, false),
		};
	}

	onChange = option => {
		let options = [];
		option.selected = !option.selected;
		if (option.selected && !option.make_issue) {
			options = this.state.options.map(o => {
				if (o.label === option.label) {
					o.selected = option.selected;
				} else {
					o.selected = false;
				}
				return o;
			});
		} else {
			options = this.state.options.map(o => {
				if (o.label === option.label) {
					o.selected = option.selected;
				}
				if (o.label === 'None') {
					o.selected = false;
				}
				return o;
			});
		}
		const somethingChecked = options.filter(o => o.selected).reduce(() => true, false);
		this.setState({ options, somethingChecked });
	};

	submitAnswer = () => {
		const { options, somethingChecked } = this.state;
		const { goTo, question, resetQuestion } = this.props;
		const submitOptions = JSON.parse(JSON.stringify(options));
		if (somethingChecked) {
			resetQuestion(question);
			goTo(question.next_question, submitOptions);
		}
	};

	render() {
		const { category, desktop } = this.props;
		return (
			<Content className="MultipleCheckbox">
				<form>
					<ul>
						{this.state.options &&
							this.state.options.map((option, key) => (
								<li key={key} onClick={() => this.onChange(option)}>
									<Checkbox color={getColor(category)} checkedButton={option.selected} />
									{option.label}
								</li>
							))}
					</ul>
				</form>
				<footer>
					<StyledSection last>
						<Button
							desktop={desktop}
							color={getColor(category)}
							onClick={this.submitAnswer}
							disabled={!this.state.somethingChecked}
						>
							<Trans i18nKey="next">Next</Trans>
						</Button>
					</StyledSection>
				</footer>
			</Content>
		);
	}
}

export function OpenQuestion({ goTo, question, category, desktop }) {
	const [value, setValue] = useState('');
	const { t } = useTranslation();
	return (
		<Content>
			<form>
				{/* HANDLE DIFFERENTLY FOR COUNTRY: GERMANY */}
				<textarea
					placeholder={t('openQuestion.placeholder', 'E.g. market hall: home organisation')}
					rows={6}
					value={value}
					onChange={event => setValue(event.target.value)}
				/>
			</form>
			<footer>
				<StyledSection>
					<Button
						disabled={value.length < 1}
						desktop={desktop}
						color={getColor(category)}
						onClick={() => goTo(question.next_question, value)}
					>
						{t('next', 'Next')}
					</Button>
				</StyledSection>
			</footer>
		</Content>
	);
}
