// @flow
import { take, fork, cancel } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { Store } from 'redux';
import appSaga from './App/app.saga';
import productSaga from './Products/products.saga';
import questionsSaga from './Questions/questions.saga';
import mistakesSaga from './Mistakes/mistakes.saga';
import eventSaga from './Events/events.saga';
import learnSaga from './Learn/learn.saga';
import reviewSaga from './Review/review.saga';

export const CANCEL_SAGAS_HMR: 'CANCEL_SAGAS_HMR' = 'CANCEL_SAGAS_HMR';

const sagas = [
	...appSaga,
	...productSaga,
	...questionsSaga,
	...mistakesSaga,
	...eventSaga,
	...learnSaga,
	...reviewSaga,
];

function createAbortableSaga(saga) {
	if (process.env.NODE_ENV === 'development') {
		return function* main(): Saga<*> {
			const sagaTask = yield fork(saga);

			yield take(CANCEL_SAGAS_HMR);
			yield cancel(sagaTask);
		};
	} else {
		return saga;
	}
}

const SagaManager = {
	startSagas(sagaMiddleware: *) {
		sagas.map(createAbortableSaga).forEach(saga => sagaMiddleware.run(saga));
	},

	cancelSagas(store: Store<*, *>) {
		store.dispatch({
			type: CANCEL_SAGAS_HMR,
		});
	},
};

export default SagaManager;
