import React from 'react';

class LearnRangeGrouping extends React.Component {
  render() {
    return (
      <svg
        viewBox="0 0 47.066 46.004"
        fill={this.props.color}
        width={`${this.props.width}px`}
        height={`${this.props.height}px`}
        style={this.props.style}
      >
        <g>
          <path d="M47.018 13.447a.548.548 0 0 0-.142-.214l-.071-.071c-.071-.071-.142-.071-.214-.142l-11.9-4.987a.78.78 0 0 0-.57 0l-11.893 4.986c-.071 0-.142.071-.214.142l-.071.071-.142.142v.071c0 .071-.071.142-.071.214v7.908a.712.712 0 1 0 1.425 0v-6.838l10.473 4.346v12.324l-2.992-1.282a.702.702 0 0 0-.57 1.282l3.989 1.71a.606.606 0 0 0 .57 0l11.9-4.987a.685.685 0 0 0 .427-.641V13.66c.138-.071.138-.142.066-.213zM34.48 9.454l10.045 4.2-10.045 4.2-10.045-4.2zm.712 22.013v-12.4l10.472-4.346v12.333z"/>
          <path d="M6.7 22.85a.623.623 0 0 0 .641-.427.707.707 0 0 0-.356-.926l-5.56-2.351V6.821L11.9 11.167v8.478a.712.712 0 0 0 1.425 0v-8.478l10.469-4.346v2.921a.712.712 0 0 0 1.425 0V5.753a.262.262 0 0 0-.071-.214v-.071a.153.153 0 0 0-.142-.142l-.071-.071c0-.071-.071-.142-.142-.214L12.895.053a.78.78 0 0 0-.57 0L.427 5.04c-.071 0-.142.071-.214.142l-.071.071c0 .071 0 .071-.071.142v.071C0 5.539 0 5.61 0 5.753v13.892a.623.623 0 0 0 .427.641l5.985 2.489c.071 0 .142.075.288.075zm5.913-21.3l10.045 4.2L12.61 9.956 2.565 5.753z"/>
          <path d="M28.14 26.27c-.071-.071-.071-.142-.142-.214l-.071-.071c-.071-.071-.142-.071-.214-.142l-11.9-4.987a.78.78 0 0 0-.57 0l-11.9 4.987c-.071 0-.142.071-.214.142l-.071.071c-.071.071-.071.071-.071.142v.071c0 .071-.071.142-.071.214v13.894a.623.623 0 0 0 .427.641l11.9 4.987h.214a.428.428 0 0 0 .285-.071l11.9-4.987a.685.685 0 0 0 .427-.641V26.555c.142-.071.142-.142.071-.285zm-12.538-3.919l10.045 4.2-10.045 4.2-10.045-4.2zM4.421 27.623l10.472 4.346v12.325L4.421 40.019zm11.9 16.742V32.04l10.472-4.346v12.325z"/>
        </g>
      </svg>
    )
  }
}

LearnRangeGrouping.defaultProps = {
  width: 50,
  height: 50,
  color: 'black',
};

export default LearnRangeGrouping;
